
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
   
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
@import "/style/common.scss";
::-webkit-scrollbar{
  width: 2px;
  height: 100%;
  
}

::-webkit-scrollbar-thumb {
	background: rgb(0, 153, 255);
	border-radius: 10px;
}

.flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .el-select {
  width: 100%;
  height: 100%;
}
/deep/ .el-input {
  width: 100%;
  height: 100%;
}
/deep/ .el-input > input {
  width: 100%;
  height: 100%;
}
input,
textarea {
  box-shadow: none;
  outline: none;
  resize: none;
  border: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.selectBox {
  border: 2px solid #0099ff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}

.el-color-dropdown__link-btn{
  background: transparent;
  padding: 0 15px;
}

</style>
