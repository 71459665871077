export default {
    state:{
        selectPlanIndex: -1
    },
    mutations:{
        ['SET_PLAN_INIDEX'](state, value){
            state.selectPlanIndex = value;
        }
    },
    actions:{
        
    },
    getters:{

    }
}