
class ModelMapping{

    constructor(m, mName){

        this.mesh = m;
        this.name = "";
        this.modelName = "";
        this.mName = mName;
        this.map = new Map();
        this.meshCount = 0;
        this.textObj = null;
        this.mat = null;

        this.canvasWidth = 310;
        this.canvasHeigh = 310;
    }

    setCanvasSize(w,h){

        this.canvasWidth = w;
        this.canvasHeigh = h;
    }

    setMat(mat){

        this.mat = mat;
        this.getTextEnvMap();
        this.refreshMat();
    }

    getTextEnvMap() {
        if (!this.mesh.material[0]) {
            // 没有环境贴图时，反复获取
            setTimeout(() => {
                this.getTextEnvMap();
            })
            return;
        }
        const envMap = this.mesh.material[0].envMap;
        const envMapIntensity = this.mesh.material[0].envMapIntensity;
        const emissive = this.mesh.material[0].emissive;

        if(this.mat){
            this.mat.envMap = envMap;
            this.mat.envMapIntensity = envMapIntensity;
            this.mat.emissive = emissive;
            this.mat.needsUpdate = true;
        }

        this.map.forEach(v=>{

              v.material[0].envMap = envMap;
              v.material[0].envMapIntensity = envMapIntensity;
              v.material[0].emissive = emissive;
              v.material[0].needsUpdate = true;
        })
        
        if(this.textObj){
            this.textObj.material[0].envMap = envMap;
            this.textObj.material[0].envMapIntensity = envMapIntensity;
            this.textObj.material[0].emissive = emissive;
            this.textObj.material[0].needsUpdate = true;
        }
    }

    refresh(texID,url,scale,offset,rotation){

        if(this.map.has(texID)){
            this.refreshTexture(this.map.get(texID),scale,offset,rotation);
        }else{
            this.add(texID,url,scale,offset,rotation);
        }
    }

    add(texID,url,scale,offset,rotation){

        const mm = this.mesh.clone();
        mm.visible = false;

        if(!this.mat){
            this.mat = this.mesh.material[0];
        }

        const mat = this.mat.clone();
        mat.color.setRGB(1,1,1);
        mm.name = texID;
        mm.material = [];
        mm.material.push(mat);

        // 单独处理 单张台卡，纹身贴，车贴
        if (this.mName === 'danzhangtaika' || this.mName === 'wenshengtie' || this.mName === 'chetie') {
            mm.renderOrder = -1000;
        } else {
            this.meshCount ++;
            mm.renderOrder += this.meshCount;
        }

        // this.meshCount ++;
        // mm.renderOrder += this.meshCount;
        // mm.renderOrder = -1000;
        new AMRT.TextureLoader().load(url,(data)=>{

            mm.material[0].albedo = data;
            mm.material[0].needsUpdate = true;
            mm.visible = true;

            this.refreshTexture(mm,scale,offset,rotation);
        })

        this.mesh.parent.add(mm);

        this.map.set(texID,mm);
    }

    remove(texID){

        if(this.map.has(texID)){
            const m = this.map.get(texID);
            m.material[0].dispose();
            m.parent.remove(m);
            this.map.delete(texID);
        }else{
            console.log("不存在图片",texID)
        }

    }

    removeText(){

        if(this.textObj != null){
            this.textObj.material[0].dispose();
            this.textObj.parent.remove(this.textObj);
            this.textObj = null;
        }
    }

    refreshText(img){
        if(this.textObj == null){
            this.textObj = this.mesh.clone();
            this.textObj.visible = false;

            if(!this.mat){
                this.mat = this.mesh.material[0];
            }

            const mat = this.mat.clone();
            mat.color.setRGB(1,1,1);
            mat.transparent = true;
            this.textObj.name = this.mesh.name + "_text";
            this.textObj.material = [];
            this.textObj.material.push(mat);
            // 单独处理 单张台卡，纹身贴，车贴
            if (this.mName === 'danzhangtaika' || this.mName === 'wenshengtie' || this.mName === 'chetie') {
                this.textObj.renderOrder = -500;
            } else {
                this.textObj.renderOrder = 3000;
            }
            
            this.mesh.parent.add(this.textObj);
        }

        new AMRT.TextureLoader().load(img,(data)=>{

            data.center.set(0.5,0.5);
            this.textObj.material[0].albedo = data;
            this.textObj.material[0].needsUpdate = true;
            this.textObj.visible = true;
        })
    }

    refreshTexture(m, scale,offset,rotation){

        //const m = this.map.get(texID);
        const tex = m.material[0].albedo;
        tex.center.set(0.5 + offset.x,0.5 + offset.y);
        var scaleX = tex.image.width * scale.x / this.canvasWidth;
        var scaleY = tex.image.height * scale.y / this.canvasWidth;
        let texRotation = (Math.PI / 180) * (360 - rotation);
        // let angle = Math.abs(Math.cos(texRotation));
        // scaleX = scaleY * (1 - angle) + angle * scaleX;
        // scaleY = scaleX * (1 - angle) + angle * scaleY;
        m.material[0].albedo.rotation = texRotation;
        m.material[0].albedoOffset = offset;
        m.material[0].albedoScale = new AMRT.Vector2(scaleX,scaleY);
        m.material[0].needsUpdate = true;
    }

    refreshMat(){

        this.map.forEach(v=>{

            const mat = this.mat.clone();
            mat.albedo = v.material[0].albedo;
            mat.albedoOffset = v.material[0].albedoOffset;
            mat.albedoScale = v.material[0].albedoScale;
            mat.color.setRGB(1,1,1);
            mat.needsUpdate = true;
            v.material[0] = mat;
        });

        if(this.textObj){
            const mat = this.mat.clone();
            mat.albedo = this.textObj.material[0].albedo;
            mat.albedoOffset = this.textObj.material[0].albedoOffset;
            mat.albedoScale = this.textObj.material[0].albedoScale;
            mat.color.setRGB(1,1,1);
            mat.needsUpdate = true;
            this.textObj.material[0] = mat;
        }
    }

    dispose(){

        this.map.clear();
    }

}

export {ModelMapping}