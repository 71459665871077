import Vue from 'vue'
import Vuex from 'vuex'
import user from './user.js'
import share from './share.js'
import cases from './case.js'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    share,
    cases
  }
})
