<template>
  <div class="share">
    <Head :headType="2" @showShare="showShare"></Head>

    <div class="content">
      <vue-scroll :ops="ops">
        <div class="list">
          <div
            style="margin-bottom:20px ; margin-right:20px"
            v-for="(item,index) in listArr"
            :key="index"
            @click="selectIndex = index "
          >
            <div :class="selectIndex == index ? 'shareBox selectBox' : 'shareBox'">
              <div class="img" style="overflow:hidden">
                <div
                  :style="`background:url(${item.previewUrl})no-repeat 50% 50% / cover ; width:100%;height:100%`"
                ></div>
              </div>

              <div class="text">{{item.name}}</div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>

    <ShareToast v-if="showToast" :showToast="showToast" @closeToast="closeToast" :planInfo='listArr[selectIndex]'></ShareToast>
  </div>
</template>

<script>
import vueScroll from "vuescroll";
import ModelBox from "@/components/shareBox.vue";
import Head from "@/components/common/head";
import ShareToast from "@/components/common/shareToast";
export default {
  components: {
    Head,
    ModelBox,
    vueScroll,
    ShareToast
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          keepShow: true,
          background: "#0099FF",
          size: "2px"
        }
      }, //滚动条配置参数
      listArr: [], // 分享列表
      showToast: false, //展示弹窗
      selectIndex: 0 //默认第一个
    };
  },
  computed: {
    userId(){
      return this.$store.state.user.userId
    },
  },
  watch: {},
  methods: {
    //获取设计方案列表
    getCaseList() {
      this.$api.modelCase.getList({modelId: this.modelId, userId: this.userId}).then(res => {
        if (res) {
          this.listArr = res;
        }
      });
    },
    //跳转详情
    goToindex() {
      
    },
    //关闭弹窗
    closeToast() {
      this.showToast = false;
    },
    //展示弹窗
    showShare() {
      this.showToast = true;
    }
  },
  created() {},
  mounted() {
    this.modelId = this.$route.query.lzyProductCode || this.$store.state.user.lzyProductCode ;
    this.getCaseList();

  }
};
</script>
<style lang='scss' scoped>
.share {
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: calc(100% - 90px);
    background: #f4f4f4;
    padding: 27px 10px 0px 40px;
    box-sizing: border-box;
    .list {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      flex-wrap: wrap;
    }
  }
}
.shareBox {
  width: 445px;
  height: 500px;
  padding: 20px 20px 27px 20px;
  box-sizing: border-box;
  background: white;
  .img {
    width: 405px;
    height: 405px;
    background: #f3f3f3;
    > div {
      transition: all 0.4s;
    }
    > div:hover {
      transform: scale(1.3);
    }
  }
  .text {
    text-align: center;
    margin-top: 28px;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.selectBox {
  border: 2px solid #0099ff;
}
</style>