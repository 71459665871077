import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/index'


import '@/style/swiper.min.css' ; 


import { ColorPicker , Select , Option , Upload , Loading , Message, Slider } from 'element-ui' ; 
import fabric from 'fabric' ; 

Vue.prototype.$api = api;
Vue.prototype.$store = store;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.config.productionTip = false

Vue.use(ColorPicker) ;
Vue.use(Select) ;
Vue.use(Option) ;
Vue.use(Upload) ;
Vue.use(Slider) ;

Vue.use(fabric) ;

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue;
