<template>
  <div class="toastBg flex-center">
    <div class="infoBox" :style="`transform:scale(${showToastInfo ? 1 : .01})`">
      <div
        class="closeBox"
        @click="closeBox"
        :style="`background:url(${require(`@/assets/home/close.png`)}) no-repeat 50% 50% / 100%;`"
      ></div>

      <div class="title">提示</div>

      <div class="info">{{textInfo || '您已保存了设计方案，请选择您需要的方案加入购物车。'}}</div>

      <div class="buttonList">
        <div class="bottomBox" @click="closeBox(1)">取消</div>
        <div class="bottomBox" @click="closeBox(2)">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showToast: {
      type: Boolean,
      default: false
    },
    textInfo: {
      type: String,
      default: "是否删除当前方案?"
    }
  },
  components: {},
  data() {
    return {
      showToastInfo: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.showToast) {
      setTimeout(() => {
        this.showToastInfo = true;
      }, 100);
    }
  },
  methods: {
    //关闭弹窗
    closeBox(type) {
      this.showToastInfo = false;
      setTimeout(() => {
        this.$emit("closeTextToast",type);
      }, 400);
    }
  }
};
</script>
<style lang='scss' scoped>
.toastBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  justify-content: center;
  .infoBox {
    border-radius: 8px;
    width: 400px;
    height: 200px;
    padding-top: 19px;
    box-sizing: border-box;
    background: white;
    position: relative;
    transition: all 0.5s;
    .closeBox {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 21px;
      right: 19px;
    }
    div {
      text-align: center;
    }
    .title {
      font-size: 18px;
      color: #1a1a1a;
      margin-bottom: 49px;
      font-weight: 600;
    }
    .info {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
    }
    .buttonList {
      position: absolute;
      width: 100%;
      height: 49px;
      bottom: 0;
      left: 0;
      line-height: 49px;
      border-top: 1px solid #e1e1e1;
      color: #0099ff;
      display: flex;
      cursor: pointer;
      > div {
        height: 100%;
        flex: 1;
      }
      > div:nth-child(1) {
        color: #999999;
      }
    }
  }
}
</style>