<template>
  <div
    class="list"
    :style="`transform:translateX(${isHide ? -100 : 0}%)`"
    v-if="!cartId && !planId"
  >
    <vue-scroll :ops="ops">
      <div class="infoBox">
        <div class="title flex-center">
          方案 <span style="margin-left:-20px" v-if="schemeArr.length != 0 ">{{schemeArr.filter(f => f.isSave !== false).length}} /10</span>
          <div class="addBox flex-center" v-if="schemeArr.length != 0 " @click="addPlan">
            <span
              :style="`background:url(${require(`@/assets/home/addBox.png`)}) no-repeat 50% 50% / 100%;`"
            ></span>
            新建
          </div>
        </div>

        <div class="nothing" v-if="schemeArr.length == 0 ">
          <div class="box">
            <div
              class="img"
              :style="`background:url(${require(`@/assets/home/nothing.png`)}) no-repeat 50% 50% / 100%;`"
            ></div>
            <div class="txt">暂无设计方案</div>

            <div class="button" @click="addPlan">新建方案</div>
          </div>
        </div>

        <div class="planList" v-else>
          <div
            class="box"
            v-for="(item ,index) in schemeArr "
            :key="index"
            @click="changeSelect(index)"
          >
            <div :class="selectPlanIndex == index ? 'img haveBorder' : 'img'">
              <div
                v-if="item.previewUrl && item.previewUrl != ''"
                class="imgBox"
                :style="`background:url(${item.previewUrl}) no-repeat 50% 50% / cover;width:100%;height:100%;`"
              >
                <div
                  class="deleteIcon"
                  @click.stop="deleteInfoBox(item.id)"
                  :style="`background:url(${require(`@/assets/delete.png`)}) no-repeat 50% 50% / 100% 100% ;`"
                ></div>
                <!-- <div class="listNumBox">{{index + 1}} / 10</div> -->
                 <div
                style="width:15px;height:16px;padding:0px 10px;box-sizing: content-box;bottom:8px;right:0px"
                @click.stop="copyCase(item)"
              >
                <div
                  v-if="showCopyIcon && item.previewUrl"
                  class="icon"
                  :style="`background:url(${require(`@/assets/home/book${index == selectPlanIndex ? '-s' : ''}.png`)}) no-repeat 50% 50% / 100%;`"
                ></div>
              </div>
              </div>
            </div>
            <div class="text flex-center">
              <span
              :title="item.name"
                :style="`color:${index == selectPlanIndex ? '#0099FF' : '#1A1A1A'} ;`"
              >{{item.name ? item.name : modelName}}</span>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <div class="hideBox" @click="isHide = !isHide">
      <div
        class="img"
        :style="`background:url(${require(`@/assets/home/return.png`)}) no-repeat 50% 50% / 100%;transform: rotate(${isHide ? 180 : 0}deg);`"
      ></div>
    </div>
    <div class="mouse-box">
      <div>
        <img src="../assets/leftClick.png" alt=""><span>旋转</span>
      </div>
      <div>
        <img src="../assets/rightClick.png" alt=""><span>移动</span>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";
import vueScroll from "vuescroll";

export default {
  components: {
    vueScroll
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          keepShow: true,
          background: "#0099FF",
          size: "2px"
        }
      }, //滚动条配置参数
      schemeArr: [], //设计方案列表
      isHide: false, //是否隐藏
      selectPlanIndex: -1, //选择计划下标
      jumpcarryId: null, //跳转携带过来的id
      showCopyIcon: true, //展示跳转icon
      deletePlanId: "", //删除方案得id
      editList: null //加载的数据
    };
  },
  computed: {
    modelId() {
      return this.$store.state.user.lzyProductCode;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    modelName() {
      return this.$store.state.user.modelName;
    },
    cartId() {
      return this.$store.state.user.cartId;
    },
    planId() {
      return this.$store.state.user.planId;
    },
  },
  watch: {
    selectPlanIndex(newVal) {
      let selectInfo = this.schemeArr[newVal];
      Bus.$emit("changeTitle", {
        list: this.schemeArr,
        index: this.selectPlanIndex
      });
      console.log(newVal, '==============newVal')

      this.$store.commit('SET_PLAN_INIDEX', newVal);
    },
    schemeArr: {
      deep: true,
      handler: function(newVal) {
        if (newVal.length >= 10) {
          this.showCopyIcon = false;
        } else {
          this.showCopyIcon = true;
        }
        Bus.$emit("changeTitle", {
          list: newVal,
          index: this.selectPlanIndex
        });
      }
    }
  },
  created() {},
  mounted() {
    if (this.$route.params.caseId) {
      this.jumpcarryId = this.$route.params.caseId;
    }

    Bus.$on("updateCase", () => {
      if (this.selectPlanIndex < 0 && !this.$route.query.cartId) this.selectPlanIndex = 0;
      console.log('selectPlanIndex',this.selectPlanIndex)
      this.getCaseList();
    });

    document.body.addEventListener("editOk", () => {
      if (this.cartId) {
        this.getCartCase();
        // console.log('购物车编辑')
      } else {
        // 新建方案时，重置执行
        if (this.schemeArr[0] && this.schemeArr[0].isSave === false && this.selectPlanIndex >= 0) {
          this.changeSelect(this.selectPlanIndex, true);
          return;
        }
        this.getCaseList();
        Bus.$emit('resetmatIndexMap');
      }
    });

    //删除方案
    Bus.$on("deletePlan", () => {
      this.deletePlan();
      if (!this.$route.query.cartId) {
        Bus.$emit("changeTitle", {
          list: [{
                  name: null,
                  modelId: this.modelId,
                  isSave: false //是否保存了
                }],
          index: 0
        });
      }
    });

    Bus.$on("loadPlanInfo", () => {
      this.loadPlanInfo();
    });

    if (this.$store.state.share.selectPlanIndex === -1 && !this.$route.query.cartId) {
      Bus.$emit("changeTitle", {
        list: [{
                name: null,
                modelId: this.modelId,
                isSave: false //是否保存了
              }],
        index: 0
      });
    }
  },
  methods: {
    //删除方案
    deletePlan() {
      let id = this.deletePlanId;
      if (!id || id == "") return;
      this.$api.modelCase.deleteModelCase(id).then(res => {
        this.deletePlanId = "";
        this.selectPlanIndex -= 1;
        if (this.selectPlanIndex < 0) {
          this.selectPlanIndex == 0;
        }
        this.getCaseList();
      });
    },
    //提交删除方案申请
    deleteInfoBox(id) {
      this.deletePlanId = id;
      this.$emit("showConfirm");
      Bus.$emit("changePlan");
    },
    //加载方案数据
    loadPlanInfo() {
      console.log("loadPlanInfo",this.editList)
      let list = this.editList;

      this.$store.commit('SET_CASE', list);
     
      if (!list) {
        // setTimeout(() => {
        //   this.$emit("closeLoading", false);
        // }, 3000)
        return;
      }

      this.loadJsonInfo(JSON.parse(list.TextureIndex));

      // lc已保存或者已加入购物车时
      if (this.$store.state.cases.isSaveCase || this.$route.query.cartId) {
        for (let i = 0, arr = app.editPosInfo; i < arr.length; i++) {
          if (!arr[i].editData) continue;
          const editData = JSON.parse(arr[i].editData);
          if (editData.some(s => s.src || s.textInfo)) {
            this.$parent.selectTexturePos = this.$parent.TextureArr[i];
            break;
          }
        }
      }

      app.modelMaterlInfo = JSON.parse(list.ColorIndex);
      app.craftInfo = JSON.parse(list.craftInfo);
      //部件
      if (list.partList) {
        let partInfo = JSON.parse(list.partList);
        Bus.$emit("changePartInfo", partInfo);
        let info = app.controlPartShow(partInfo);
      } else {
        Bus.$emit("changePartInfo", []);
      }

      this.editList = null;
    },

    //加载JSON编辑贴图数据递归
    async loadJsonInfo(list, imgArr = []) {
      console.log("imgArr",imgArr,app.editInfo.firstLoadInfo);
      app.editPosInfo = list;
      // if (list.length == imgArr.length) {
      //   app.editPosEditInfo = imgArr;
      //   app.editInfo.loadJsonFun(app.editInfo.firstLoadInfo, 2);
      //   app.giveImgToEdit(imgArr, 0);
      //   app.editInfo.firstLoadInfo = null;
      //   // setTimeout(() => {
      //   //   this.$emit("closeLoading", false);
      //   // }, 1000);
      //   return;
      // }
      app.modelMap.dispose();
      window.edit.matMap.dispose();
      for(let i = 0;i < list.length;i ++){
         await window.edit.loadJsonToString(list[i], i);
      }
      window.edit.showFirstCanvas();
      app.giveImgToEdit(list, 0);
      // 清除之前存在的相同的box
      window.edit.removeBox();    
      // window.edit.matMap.texMap.forEach((v)=>{

      //    app.modelMap.addMesh(v);

      // });

      //app.modelMap.initModelInfo(list);
      // let index = imgArr.length;
      // window.edit.loadJsonToString(list[index], index).then(res => {
      //   let img = imgArr;
      //   // res[0].alphaTest = list[index].alphaTest;
      //   img.push(res[0]);
      //   setTimeout(() => {
      //     this.loadJsonInfo(list, img);
      //   }, 500);                                                                                                                                                                                                    
      // });
    },
    //复制
    copyCase(info) {
      if (this.schemeArr.length >= 10) {
        this.$message({
          type: "error",
          message: "当前模型方案超出上限"
        });
        return;
      }
      Bus.$emit("executeFun", { ind: 1, name: info.name, info: info });
    },

    //切换选中
    changeSelect(ind, flag) {
      app.craftInfo = null;
      if (!flag) {
        if (this.selectPlanIndex == ind) return;
      }
      this.selectPlanIndex = ind;
      let data = this.schemeArr[this.selectPlanIndex].editData;
      this.$emit("getModelInfo", { info: data, type: 2 });
      window.edit.initialStats();
      Bus.$emit("changeCartIndex");

      Bus.$emit("closeLoading2", true);
      if (data) {
        
        this.editList = JSON.parse(data);
      } else {
        console.log(1111)
        // this.$emit("closeLoading", true);
        // setTimeout(() => {
        //   this.$emit("closeLoading", false);
        // }, 3000)
      }
  
      Bus.$emit("changePlan");

      this.$store.commit('SET_ISSAVECASE', this.schemeArr[this.selectPlanIndex]);

      Bus.$emit('resetmatIndexMap');
    },

    //添加方案
    addPlan() {
      if (this.schemeArr.length >= 10) {
        this.$message({
          type: "error",
          message: "当前模型方案超出上限"
        });
        return;
      }
      let plan = this.schemeArr.find(item => {
        return item.isSave == false;
      });
      if (plan) {
        this.$message({
          type: "error",
          message: "请先保存当前方案"
        });
        return;
      }
      let info = {
        name: null,
        modelId: this.modelId,
        isSave: false //是否保存了
      };

      Bus.$emit("closeLoading2", true);

      // this.schemeArr.push(info);
      this.schemeArr.unshift(info);
      this.$store.commit('SET_ISSAVECASE', info);
      Bus.$emit("changePartInfo", []);
      // this.changeSelect(this.schemeArr.length - 1);
      this.selectPlanIndex = -1;
      this.changeSelect(0);
    },

    //寻找是否有新建的方案
    findModelIsNew(flag) {
      if (this.schemeArr.length == 0) return;
      let index = this.schemeArr.findIndex(item => {
        return item.isSave === false;
      });
      if (flag) return index;
      if (index != -1 && this.selectPlanIndex != 0) {
        this.selectPlanIndex -= 1;
      }
    },

    //获取购物车方案
    getCartCase() {
      this.$api.cartCase.getCartCase(this.cartId).then(res => {
        let data = res.editData;

        this.$emit("getModelInfo", {
          info: data,
          type: 1,
          modelId: res.modelId
        });
        this.schemeArr = [res];
        this.selectPlanIndex = 0;
        this.$store.commit("SET_USER_INFO", { lzyProductCode: res.modelId });
        if (data) {
          this.editList = JSON.parse(data);
        }
      });
    },

    // 获取设计方案列表
    getCaseList() {
      
      this.findModelIsNew();

      let userId = this.userId;

      this.$api.modelCase
        .getList({ modelId: this.modelId, userId })
        .then(res => {
          window.edit.initialStats();
          if (this.selectPlanIndex == -1 && this.$route.query.cartId) {
            this.selectPlanIndex = 0;
          }
          if (res && res.length > 0) {
            if (this.planId) {
              this.selectPlanIndex = 0;
              let info = res.find(item => {
                return item.id == this.planId;
              });
              if (info) {
                res = [info];
              } else {
                this.$message({
                  type: "error",
                  message: "当前方案已被删除"
                });
                return;
              }
            }

            this.schemeArr = res;
            
            let data;
            if (this.selectPlanIndex == -1) {
              let info = {
                name: null,
                modelId: this.modelId,
                isSave: false //是否保存了
              };
              data = info;
            } else {
              data = this.schemeArr[this.selectPlanIndex] && this.schemeArr[this.selectPlanIndex].editData;
            }

            this.$store.commit('SET_ISSAVECASE', this.schemeArr[this.selectPlanIndex]);

            this.$emit("getModelInfo", { info: data, type: 1 });
            if (data && typeof data == 'string' ) {
              this.editList = JSON.parse(data);
            }
          } else {
            this.schemeArr = [];
            if (this.schemeArr.length == 0) {
              this.addPlan();
            }
          }
          this.$emit("closeLoading", true);
        });
    }
  }
};
</script>
<style lang='scss' scoped>
//谷歌适用
::-webkit-scrollbar {
  display: none;
}

.list {
  width: 200px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  transition: all 0.5s;
  padding-top: 8px;
  .infoBox {
    width: 100%;
    height: 100%;
    padding: 6px 10px 14px 30px;
  }
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    height: 16px;
    .addBox {
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      font-size: 16px;
      font-weight: 400;
      color: #0099ff;
      cursor: pointer;
    }
  }
  .nothing {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    .box {
      > div {
        text-align: center;
      }
      .img {
        width: 90px;
        height: 72px;
        margin-bottom: 11px;
      }
      .txt {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
      .button {
        width: 98px;
        height: 34px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        background: #0099ff;
        line-height: 34px;
      }
    }
  }

  .hideBox {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    right: -45px;
    top: 50%;
    cursor: pointer;
    .img {
      width: 8px;
      height: 15px;
      transition: all 0.5s;
    }
  }
  .mouse-box{
    position: absolute;
    right: -80px;
    bottom: 20px;
    > div{
      margin-top: 20px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      > img{
        margin-right: 10px;
      }
    }
  }

  .planList {
    margin-top: 20px;
    .box:hover {
      transform: scale(1.1);
    }
    .box {
      width: 133px;
      margin-top: 20px;
      transition: all 0.5s;
      cursor: pointer;
      .haveBorder {
        border: 2px solid #0099ff;
      }
      .img {
        width: 133px;
        height: 133px;
        box-sizing: border-box;
        background: #f3f3f3;
        .imgBox {
          position: relative;
          .icon{
            width: 100%;
            height: 100%;
          }
          > div {
            position: absolute;
            width: 20px;
            height: 20px;
            right: 6px;
          }
          .deleteIcon {
            top: 6px;
          }
          .listNumBox {
            min-width: 48px;
            bottom: 4px;
            white-space: nowrap;
          }
        }
      }
      .text {
        margin-top: 11px;
        > span {
          display: inline-block;
          max-width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          font-weight: 400;
          opacity: 1;
        }
        > input {
          font-size: 14px;
          max-width: 100px;
        }
        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>