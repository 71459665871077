const localInfo = localStorage.getItem('userInfo')
let userInfo = {}
if(localInfo){
    userInfo = {...JSON.parse(localInfo)}
}
export default {
    state:{
        // lzyProductCode: userInfo?.lzyProductCode || '1463396284714061824',
        lzyProductCode: userInfo?.lzyProductCode || '1463701550596755456',
        ticket: userInfo?.ticket || '',
        token: userInfo?.token || '',
        userName: userInfo?.userName || '',
        modelName: userInfo?.modelName || '',
        shopingUrl: userInfo?.shopingUrl || '',
        planId: '',
        userId: userInfo?.userId || '',
        indexUrl: userInfo?.indexUrl || '',
        
        fixedComponents: userInfo?.fixedComponents || '',
        productId: userInfo?.productId || '',
        otherComponents: userInfo?.otherComponents || ''
    },
    mutations:{
        ['SET_USER_INFO'](state, value){
            for(let i in value){
                state[i] = value[i]
            }
            localStorage.setItem('userInfo', JSON.stringify(state))
        }
    },
    actions:{
        login(){}
    },
    getters:{

    }
}