import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const modelCase = {
    //获取用户设计方案列表
    getList(params){
        return axios.get(`${base.host}/model/case/list?modelId=${params.modelId}&userId=${params.userId}`, )
    },
    //新增设计稿
    addModelCase(params){
        return axios.post(`${base.host}/model/case` ,params) ; 
    } , 
    //修改设计稿
    reviseModelCase(params){
        return axios.put(`${base.host}/model/case` ,params) ; 
    } , 
    //删除方案
    deleteModelCase(id){
        return axios.delete(`${base.host}/model/case/${id}`)
    }
   
}
export default modelCase