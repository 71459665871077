import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

let path = `https://portal-api.shuyangtech.com/shuyang-portal`
// let path = `http://109.244.159.71:8082/shuyang-portal`
const shuyang = {
    decryptTicket(id){
        return axios.get(`${path}/user/custom/decryptTicket/${id}`, )
    },
    addCart(params){
        return axios.post(`${path}/shoppingcart/shoppingcart/addCartByLzy`, params)
    },
   
}
export default shuyang