<template>
  <div class="loading">
    <div class="box">
      <div class="logo">
        <img :src="`${require('@/assets/loading.gif')}`" alt style="width:100% ; height:100%" />
      </div>
      <div class="text">{{loadingName}}</div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";
export default {
  components: {},
  data() {
    return {
      loadingName: '模型加载中...'
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // Bus.$on('changeLoadingName', name => {
    //   this.loadingName = name;
    // })
  },
  methods: {}
};
</script>
<style lang='scss' scoped>
.loading {
  width: 100%;
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: 200px;
    height: 236px;
    text-align: center;
    .logo {
      width: 200px;
      height: 200px;
      margin-bottom: 16px;
    }
    .text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #0099ff;
      opacity: 1;
    }
  }
}
</style>