
class MaterialMapData{

    constructor(){

        this.src = "";
        this.angle = 0;
        this.left = 0;
        this.top = 0;
        this.scaleX = 1;
        this.scaleY = 1;
        this.uid = AMRT.Math.generateUUID();
        this.name = "";
        this.modelName = "";
        
    }

    initByJson(json,name,modelName){

        this.name = name;
        this.modelName = modelName;
        const d = json;//JSON.parse(json);
        this.src = d.src;
        this.angle = d.transform.angle;
        this.left = d.transform.left;
        this.top = d.transform.top;
        this.scaleX = d.transform.scaleX;
        this.scaleY = d.transform.scaleY;
    }

    syncJson(target){

        this.angle = target.angle;
        this.left = target.left;
        this.top = target.top;
        this.scaleX = target.scaleX;
        this.scaleY = target.scaleY;
        
    }

    toJson(){
        return {
            src:this.src,
            type:"image",
            transform:{
                angle:this.angle,
                left: this.left,
                top: this.top,
                scaleX: this.scaleX,
                scaleY: this.scaleY
            }
        }
    }
}

export {MaterialMapData}