import { ModelMapping } from "./ModelMapping";

class ModelMappingManager{

    constructor(){

        this.model = null;
        this.map = new Map(); //key为网格名称
        this.nameToMap = new Map(); //key 中文名称  value 网格名称
        this.currentMesh = null;
        this.canvasWidth = 310;
        this.canvasHeigh = 310;
        this.mName = null;
    }

    setCanvasSize(w,h){

        this.canvasWidth = w;
        this.canvasHeigh = h;
        this.map.forEach(m => {
            m.setCanvasSize(this.canvasWidth,this.canvasHeigh);
        })
    }

    initModel(m){

        if(this.model != null){
            this.dispose();
        }
        this.model = m;
        let textureObj = this.model.getObjectByName("TextureIndex");
        if(textureObj){
            this.model = textureObj;
        }
        this.mName = m.getObjectByName('RootNode').children[0].name;
    }

    addObject(name,meshName){

        this.nameToMap.set(name,meshName);
    }

    addMesh(mat){

        const mesh = this.model.getObjectByName(mat.modelName);
        let m = null;
        if(this.map.has(mesh)){
            m = this.map.get(mesh);
        }else{
            m = new ModelMapping(mesh, this.mName);
            m.modelName = mat.modelName;
        }
        this.map.set(mesh,m);

        const scale = new AMRT.Vector2(mat.scaleX,mat.scaleY);
        const offset = {
            x:(mat.left - this.canvasWidth / 2) / this.canvasWidth,
            y:-(mat.top - this.canvasWidth / 2) / this.canvasWidth
        }
        //const offset = new AMRT.Vector2(-(mat.left - 155) / 155,(mat.top - 155) / 155);
        m.refresh(mat.uid,mat.src,scale,offset, mat.angle);
    }

    getMeshByName(meshName){

        const mesh = this.model.getObjectByName(meshName);
        return this.map.get(mesh);

    }

    createMesh(name,meshName){

        const mesh = this.model.getObjectByName(meshName);
        let m = null;
        if(this.map.has(mesh)){
            m = this.map.get(mesh);
        }else{
            m = new ModelMapping(mesh, this.mName);
            m.setCanvasSize(this.canvasWidth,this.canvasHeigh);
            m.modelName = meshName;
            m.name = name;
        }
        this.map.set(mesh,m);
    }

    setCurrentMeshByName(name){

        if(this.nameToMap.has(name)){
            this.setCurrentMesh(this.nameToMap.get(meshName));
        }
    }

    setCurrentMesh(meshName){

        const mesh = this.model.getObjectByName(meshName);
        if(this.map.has(mesh)){
            this.currentMesh = this.map.get(mesh);
        }else{
            let m = new ModelMapping(mesh, this.mName);
            m.setCanvasSize(this.canvasWidth,this.canvasHeigh);
            m.modelName = meshName;
            this.map.set(mesh,m)
            this.currentMesh = m;
        }
    }

    refresh(texID,url,scale,offset,rotation){

        if(this.currentMesh == null){
            return;
        }
        // console.log("-------------",scale,offset)
        const offset1 = {
            x:(offset.x - this.canvasWidth / 2) / this.canvasWidth,
            y:-(offset.y - this.canvasWidth / 2) / this.canvasWidth
        }
        // console.log(this.currentMesh)
        this.currentMesh.refresh(texID,url,scale,offset1,rotation);
    }

    setMaterial(meshName,mat){

        const mesh = this.model.getObjectByName(meshName);
        let m = null;
        if(this.map.has(mesh)){
            m = this.map.get(mesh);
        }else{
            m = new ModelMapping(mesh, this.mName);
            m.modelName = meshName;
            this.map.set(mesh,m)
        }
        m.setMat(mat);
    }

    dispose(){
        this.map.forEach(v=>{

            v.dispose();
        })

        this.map.clear();
        this.currentMesh = null;
    }
}

export {ModelMappingManager}