export default {
    state:{
        isSaveCase: false,
        case: null
    },
    mutations:{
        ['SET_ISSAVECASE'](state, value){
            if (value && value.id) {
                state.isSaveCase = true;
            } else {
                state.isSaveCase = false;
            }
        },
        ['SET_CASE'](state, value) {
            state.case = value;
        }
    },
    actions:{
        
    },
    getters:{

    }
}