<template>
  <div class="content">
    <div
      id="viewer"
      :style="`transform:translateX(${cartId || planId ? ticket ?   -150 : 120 : -80}px)`"
    ></div>

    <div class="textTitle" :style="`left:${cartId ? 50 : 220}px`">
      <span class="el-icon-info" style="font-size:16px;display:inline-block"></span>
      {{modelText}}
    </div>
    <!-- 预览框 -->
    <List
      @closeLoading="closeLoading"
      @getModelInfo="getModelInfo"
      @showConfirm="showConfirm = true"
    ></List>

    <!-- 模型信息 -->
    <ModelInfo
      :style="`opacity:${ticket ? 1 : 0}`"
      @changeColor="changeColor"
      @closeLoading="closeLoading"
      :materialinfo="giveMaterialInfo"
      :knifeInfo="knifeInfo"
      :partList="partList"
      :craftInfo="craftInfo"
      :matPosArr="matPosArr"
      :curMatPos="curMatPos"
      ref="modelInfo"
    ></ModelInfo>

    <div class="selectInfoBox">
      <!-- <div class="selectPos" v-show="posNameArr.length != 0 && ticket">
        选择材质区域:
        <div class="moreBox">
          <el-select v-model="selectPos" placeholder="选择部件">
            <el-option v-for="item in posNameArr" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </div>
      </div> -->

      <!-- <div class="selectPos" v-show="textureArr.length != 0 && ticket" style="top:45px;">
        选择贴图区域:
        <div class="moreBox">
          <el-select v-model="selectTexturePos" placeholder="选择贴图区域">
            <el-option value="无" key="无">无</el-option>
            <el-option v-for="item in textureArr" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </div>
      </div> -->

    </div>

    <div class="btn save" @click="saveModel" v-if="(!planId && !cartId) || (!planId && cartId && ticket)">
      <img src="../assets/save2.png" alt="">
      <span>保存</span>
    </div>
    <div class="btn reset" @click="resetModel(1)" v-if="(!planId && !cartId)">
      <img src="../assets/reset2.png" alt="">
      <span>重置</span>
    </div>
    <div class="btn reset" @click="resetModel(2)" v-if="(!planId && cartId && ticket)">
      <img src="../assets/reset2.png" alt="">
      <span>重置</span>
    </div>

    <!-- 信息弹窗 -->
    <TextToast
      v-if="isShowTextToast"
      :showToast="isShowTextToast"
      :textInfo="textToastInfo"
      @closeTextToast="closeTextToast"
    ></TextToast>

    <!-- 输入框弹窗 -->
    <InputToast
      v-if="isShowInputToast"
      :showToast="isShowInputToast"
      :titleType="this.caseInfo.inputToastType"
      :titleDefault="this.caseInfo.inputDefalutName"
      @closeTextToast="closeInputToast"
      @successInputToast="successInputToast"
    ></InputToast>

    <!-- loading页面 -->
    <Loading v-if="showLoadingPage"></Loading>

    <!-- 画布大小 -->
    <div
      class="canvasSizeMap"
      :style="`opacity:${showCanvasToast ? 1 : 0};left:${canvasToastPosition.x}px;top:${canvasToastPosition.y}px`"
    >
      <div>画布大小</div>
      <div>{{canvasToastPosition.w}}*{{canvasToastPosition.h}}mm</div>
    </div>

    <Toast v-if="showConfirm" :showToast="showConfirm" @closeTextToast="closeConfirmToast"></Toast>
  </div>
</template>

<script>
import base from "@/api/base"; // 导入接口域名列表
import Bus from "@/utils/bus.js";
import List from "@/components/previewList";
import ModelInfo from "@/components/modelInfo";
import TextToast from "@/components/common/textToast";
import InputToast from "@/components/common/inputToast";
import Loading from "@/components/common/loading";
import Toast from "@/components/common/confirmToast.vue";
import { App } from "@/utils/3D/app.js";
import { mapState } from "vuex";
import {btof} from '@/utils/tools.js';
let app, viewer;
export default {
  components: {
    List,
    ModelInfo,
    TextToast,
    InputToast,
    Loading,
    Toast
  },
  data() {
    return {
      posList: [], //部件列表
      posNameArr: [], //部件名称列表
      selectPos: "", //选中的部位
      paths: [],
      isShowTextToast: false,
      isShowInputToast: false,
      textToastInfo: null,
      showLoadingPage: true, //展示loading页面
      editMaterialInfo: [], //可编辑的材质信息
      matPosArr: [], //材质列表
      curMatPos: {}, //当前材质区域
      giveMaterialInfo: null, //给予的模型信息
      showSharePage: false, //展示分享页面
      knifeInfo: {
        knifeLayout: "",
        downUrl: ""
      }, //刀版图信息
      showCanvasToast: false, //展示尺寸的弹窗
      canvasToastPosition: {
        x: 0,
        y: 0
      },
      selectTexturePos: "", //选择贴图区域
      textureArr: [], //存放贴图区域数组
      loadModeling: false, //是否在加载模型中
      showConfirm: false, //展示确认弹窗
      partList: [], //部件列表
      modelText: null, //模型文字
      craftInfo: {}, //工艺纹理信息
      ticket: null,
      TextureArr: []
    };
  },
  computed: {
    modelId() {
      return this.$store.state.user.lzyProductCode;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    cartId() {
      return this.$store.state.user.cartId;
    },
    planId() {
      return this.$store.state.user.planId;
    }
  },
  watch: {
    selectTexturePos(newVal) {
      let type = this.loadModeling ? 1 : 2;
      if (newVal == "无") {
        app.recomPositionInfo(app.editModel.name);//lc 切换到'无'时存储已编辑的贴图
        app.editModel = null;
        app.editInfo.initialStats();
        app.changeUploadImg(2);

        //lc '无'时切换到刀版图
        // this.$refs.modelInfo.selectIndex = 0;
        // this.$refs.modelInfo.swiper.slideTo(0, 1000, false);
      } else {
        app.changeUploadImg(1);
        app.selectTextureIndex(newVal, type);
        Bus.$emit("closeEditDiv");

      }

      // 清除之前存在的相同的box
      app.editInfo.removeBox();     
    },
    selectPos(newVal) {
      let info = this.editMaterialInfo.find(item => {
        return item.userData.cName == newVal;
      });

      if (info) {
        let arr = app.changeEditViewer(info.userData.cName);
        this.giveMaterialInfo = info.userData.defaultMaterialInfo;
        // this.createTetxurePos(arr);

        this.curMatPos = info;//lc 获取当前材质区域
      }
    }
  },
  created() {},
  mounted() {
    this.ticket = this.$route.query.ticket;
    Bus.$on("executeFun", info => {
      this.caseInfo = {
        inputDefalutName: info.name || "", // 默认名字 inputDefalutName
        inputToastType: info.ind + 1, // 弹窗类型
        copyCaseInfo: info.info || null //复制得数据
      };
      this.isShowInputToast = true;
    });

    Bus.$on("closeLoading", data => {
      this.closeLoading(data);
      setTimeout(() => {
        if (this.showLoadingPage) {
          this.showLoadingPage = false;
        }
      }, 3000);
    });

    Bus.$on("closeLoading2", data => {
      this.closeLoading(data);
    });

    this.initViewer();
  },
  methods: {
    saveModel() {
      Bus.$emit('saveModel');
      Bus.$emit('changePlan');
    },
    resetModel(type) {
      if (type === 1) {
        Bus.$emit('resetModel');
      } else {
        window.location.reload();
      }
    },
    //关闭确认弹窗 data == 1 ? 取消 2 确认
    closeConfirmToast(data) {
      this.showConfirm = false;
      if (data == 2) {
        Bus.$emit("deletePlan");
      }
    },
    //生成贴图模块
    createTetxurePos(arr) {
      let textureArr = [];
      //管理贴图的下拉框
      arr.map(item => {
        textureArr.push(item.name);
      });
      if (textureArr.length == 0) {
        textureArr.push("无");
      }
      this.selectTexturePos = textureArr[0];
      this.textureArr = textureArr;
    },

    //上传base64图片
    uploadBase64File(data, info) {
      // let imgdata = {
      //   base64: data.previewUrl
      // };

      const uploadData = new FormData();
      uploadData.append("file", btof(data.previewUrl, 'a'));

      this.$api.system.uploadFile2(uploadData).then(res => {
        data.previewUrl = res;
        if (this.caseInfo.copyCaseInfo.id && info.type == 1) {
          this.caseInfo.copyCaseInfo.name = info.name;
          this.caseInfo.copyCaseInfo.editData = data.editData;
          this.caseInfo.copyCaseInfo.previewUrl = data.previewUrl;

          data = this.caseInfo.copyCaseInfo;
          this.$api.modelCase.reviseModelCase(data).then(res => {
            this.isShowTextToast = true;
            Bus.$emit("updateCase");
          });
        } else {
          data.userId = String(this.userId);
          this.$api.modelCase.addModelCase(data).then(res => {
            if (info.type == 2) {
              this.textToastInfo = "复制成功~";
            }
            this.isShowTextToast = true;
            Bus.$emit("updateCase");
          });
        }
      });
    },
    //关闭弹窗成功
    successInputToast(info) {
      if (info.type != 3) {
        let data = {
          name: info.name,
          modelId: this.modelId
        };

        data.editData = JSON.stringify(app.getSaveInfo());
        data.previewUrl = app.shotScene();

        if (info.type == 2) {
          let copyInfo = this.caseInfo.copyCaseInfo;
          data.editData = copyInfo.editData;
          data.previewUrl = copyInfo.previewUrl;
          data.userId = String(this.userId);
          this.$api.modelCase.addModelCase(data).then(res => {
            this.textToastInfo = "复制成功~";
            this.isShowTextToast = true;
            Bus.$emit("updateCase");
          });
          return;
        }
        this.uploadBase64File(data, info);
      }
    },
    //获取模型信息
    getModelInfo(info) {
      this.loadModeling = true;
      this.textureArr = [];
      this.selectTexturePos = "";
      this.selectPos = "";
      let data = info.modelId ? info.modelId : this.modelId ;
      this.$api.model.getModelInfo(data).then(res => {
        let url = JSON.parse(res.fileModelPath);

        this.craftInfo = JSON.parse(res.craft);

        this.modelText = res.textureData;
        let knifeInfo = {
          knifeLayout: res.knifeLayout,
          downUrl: res.downUrl
        };
        this.knifeInfo = knifeInfo;

        //获取模型名称和id
        this.$store.commit("SET_USER_INFO", {
          modelName: res.name,
          modelId: res.id
        });

        let max = [];
        for (let key in url.box.max) {
          max.push(url.box.max[key]);
        }
        let min = [];
        for (let key in url.box.min) {
          min.push(url.box.min[key]);
        }

        url.box.max = max;
        url.box.min = min;

        this.initModel(info, url, JSON.parse(res.editData), data);
      });
    },
    //初始化模型
    initModel(info, url, materialInfo, data) {
      let type = info.type || 1;
      app.initModel(url, materialInfo, data).then(res => {
        console.log(res);
        this.partList = [];
        //存储多部件
        if (res.partArr) {
          res.partArr.map(item => {
            if (item.partArr.length != 1) {
              this.partList.push(item);
            }
          });
        }

        this.editMaterialInfo = res.colorArr;
        let ColorArr = [];
        // 管理颜色的下拉框
        res.colorArr.map(item => {
          ColorArr.push(item.userData.cName);
        });
        this.TextureArr = [];
        res.TextureArr.map(item => {
          this.TextureArr.push(
            item.userData.cName != "" ? item.userData.cName : item.name
          );
          app.modelMap.addObject(this.TextureArr[this.TextureArr.length - 1],item.name);
        });

        this.selectPos = ColorArr[0];
        this.posNameArr = ColorArr;

        this.textureArr = this.TextureArr;

        this.selectTexturePos = this.TextureArr[0];
        // setTimeout(() => {
        //   this.$emit("closeLoading", false);
        // }, 1000)
      
        this.matPosArr = res.colorArr;

        Bus.$emit("loadPlanInfo");

        setTimeout(() => {
          this.loadModeling = false;
        }, 500);
      });
    },
    // //关闭loading页

    closeLoading(bol) {
        this.showLoadingPage = bol;
    },
    //关闭文字弹窗
    closeTextToast() {
      this.isShowTextToast = false;
      this.textToastInfo = null;
    },
    //关闭输入框弹窗
    closeInputToast() {
      this.isShowInputToast = false;
    },
    initViewer() {
      app = new App("viewer");
      window.app = app;

      viewer = app.initViewer();

      this.modelClickFun();
    },
    //修改材质颜色
    changeColor(obj) {
      app.changeEditColor({ color: obj.color, id: obj.id });
    },

    //注册事件
    modelClickFun() {
      app.viewer.container.addEventListener("mousemove", e => {
        let info = app.modelClickFun(e);
        if (info) {
          let size = app.computedUvSize(info.model);
          this.canvasToastPosition.x = info.position.x;
          this.canvasToastPosition.y = info.position.y;
          let width = window.edit.maxWidth;
          let height = window.edit.maxHeight;
          this.canvasToastPosition.w = Math.floor(size.x);
          this.canvasToastPosition.h = Math.floor(size.y);
          if (size.type == 1) {
            if ((size.x == size.y) == 1) {
              width = height;
            }
            this.canvasToastPosition.w = Math.floor(size.x * width);
            this.canvasToastPosition.h = Math.floor(size.y * height);
          }

          this.showCanvasToast = true;
        } else if (info == false) {
          this.showCanvasToast = false;
        }
      });
    }
  }
};
</script>
<style lang='scss' scoped>
/deep/ .el-select {
  width: 100%;
  height: 100%;
}
/deep/ .el-input {
  width: 100%;
  height: 100%;
}
/deep/ .el-input > input {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: calc(100% - 80px);
  position: relative;
  // background: #eeeeee;
  background: #828282;
  overflow: hidden;
  .btn{
    width: 88px;
    height: 28px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: fixed;
    right: 630px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #fff;
    &.save{
      top: 110px;
    }
    &.reset{
      top: 148px;
    }
  }
  .textTitle {
    position: absolute;
    top: 12px;
    color: #ff9200;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  #viewer {
    width: calc(100% - 250px);
    height: 100%;
    box-sizing: border-box;
    background: #828282;
    /deep/ canvas{
      background: #828282!important;
    }
  }
  .changeViewer {
    width: 100% !important;
  }
  .selectInfoBox{
    position: absolute;
    top: 20px;
    right: 62px;
    display: flex;
    flex-wrap: nowrap;
  }
  .selectPos {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
      margin-left: 20px;
    .moreBox {
      width: 130px;
      height: 34px;
      margin-left: 10px;
    }
  }
}
.canvasSizeMap {
  position: fixed;
  left: 50%;
  top: 10%;
  padding: 8px 14px;
  background: #e8e8e8;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-size: 14px;
  color: #666666;
}
</style>