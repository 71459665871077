<template>
  <div class="shareBox" @click="goToGoodsPage">
    <div class="img" style="overflow:hidden">
        <div :style="`background:url(${info.previewUrl})no-repeat 50% 50% / cover ; width:100%;height:100%`"></div>
    </div>

    <div class="text">{{info.name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
    }
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    //跳转到详情页
    goToGoodsPage(){
    let data = this.info ; 
    localStorage.setItem('modelId' , data.modelId ) ; 

   this.$router.push({ name: "Home" , params:{caseId:data.id} });

    }
  },
  created() {},
  mounted() {
  }
};
</script>
<style lang='scss' scoped>
.shareBox {
  width: 445px;
  height: 500px;
  padding: 20px 20px 27px 20px;
  box-sizing: border-box;
  background: white;
  .img {
    width: 405px;
    height: 405px;
    background: #f3f3f3;
    >div{
      transition: all .4s;
    }
    >div:hover{
      transform: scale(1.3);
    }
  }
  .text {
    text-align: center;
    margin-top: 28px;
    font-weight: 500;
    color: #1a1a1a;
  }
}
</style>