import { MaterialMapData } from "./MaterialMapData";

class MaterialMapManager{


    constructor(){

        // this.modelMap = new Map();
        //this.texMap = new Map();
        this.texArray = [];
    }

    // initByJson(info){

    //     const modelName = info.modelName;
    //     const name = info.name;
    //     let ms = [];
    //     var firstUID = "";
    //     const editData = JSON.parse(info.editData);
    //     for(let i = 0;i < editData.length;i ++){

    //         const m = new MaterialMapData();
    //         if(editData[i].type == 'image'){
    //             m.initByJson(editData[i],name,modelName);
    //             firstUID = m.uid;
    //             ms.push(m);
    //             this.texMap.set(m.uid,m);
    //         }
    //     }

    //     if(!this.modelMap.has(name)){
    //         this.modelMap.set(name,ms);
    //     }
    //     return firstUID;
    // }

    add(name,modelName, item){

        const m = new MaterialMapData();
        m.initByJson(item,name,modelName);
        // ms.push(m);
        //this.texMap.set(m.uid,m);
        this.texArray.push(m);
        return m;
    }

    remove(uid){

        for(let i = 0;i < this.texArray.length;i ++){
            if(this.texArray[i].uid === uid){
                this.texArray.splice(i,1);
                return;
            }
        }
    }

    getTexArrByMeshName(meshName){

        let texArr = [];
        for(let i = 0;i < this.texArray.length;i ++){
            if(this.texArray[i].modelName == meshName){
                texArr.push(this.texArray[i]);
            }
        }
        return texArr;
    }

    syncJson(target,uid){

        for(let i = 0;i < this.texArray.length;i ++){
            if(this.texArray[i].uid == uid){

                this.texArray[i].syncJson(target);
                return;
            }
        }

    }

    save(){

        // let JsonData = [];
        // this.texArray.forEach(item => {
        //     let obj = {};
        //     if (item.type == "image") {
        //         obj.src = item.getSrc();
        //         obj.type = item.type;

        //         obj.transform = {
        //             left: item.left,
        //             top: item.top,
        //             angle: item.angle,
        //             scaleX: item.scaleX,
        //             scaleY: item.scaleY,
        //         };
        //     } else if (item.type == 'group') {
        //         obj.type = item.type;
        //         obj.textInfo = item.textInfo;
        //         obj.textPath = item.textPath;
        //         obj.transform = {
        //             left: item.left,
        //             top: item.top,
        //             angle: item.angle,
        //             scaleX: item.scaleX,
        //             scaleY: item.scaleY,
        //         };
        //     }
        //     JsonData.push(obj);

        // })

        // JsonData.push({
        //     type: 'size',
        //     w: edit.editCanvas.width,
        //     h: edit.editCanvas.height,
        // })
    }

    dispose(){

        this.texArray = [];
        // this.modelMap.clear();
        // this.texMap.clear();
    }
}

export {MaterialMapManager}