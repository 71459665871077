/**
 * 接口域名的管理
 */
const api = {
    debug: {
        host: 'http://172.16.10.80:8083/api'
    },
    development: {
        // host: 'http://172.16.10.220:8080'
        // host: 'http://shuyang-admin.laozicloud.com/prod-api'
        host: 'https://canvas-admin.shuyangtech.com/prod-api'
    },
    production: { 
        // host: 'http://109.244.159.71:8082/shuyang-portal'
        // host: 'http://shuyang-admin.laozicloud.com/prod-api'
        host: 'https://canvas-admin.shuyangtech.com/prod-api' , 
    }
}
const base = api[process.env.VUE_APP_ENV]
export default base;