<template>
  <div class="toastBg flex-center">
    <div
      class="infoBox"
      :style="`transform:scale(${showToastInfo ? 1 : .01});height:${titleType == 3 ? 254 : 200}px`"
    >
      <div
        class="closeBox"
        @click="closeBox"
        :style="`background:url(${require(`@/assets/home/close.png`)}) no-repeat 50% 50% / 100%;`"
      ></div>

      <div class="title">{{titleType == 1 ? '保存方案' : titleType == 2 ? '复制方案' : '替换暂存方案'}}</div>

      <div class="inputBox">
        <div class="info" v-if="titleType ==3" style="margin-bottom:19px">
          替换方案:
          <div>
            <el-select v-model="selectPlan" placeholder="选择部件">
              <el-option v-for="item in planArr" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </div>
        </div>
        <div class="info">
          {{titleType == 2 ? '新' : ''}}方案名称:
          <div class="input">
            <input type="text" placeholder="请输入方案名称(限10字）" v-model="planeName" />
          </div>
        </div>
      </div>

      <div class="bottomBox">
        <div
          class="button"
          v-for="(item,index) in 2"
          :key="index"
          @click="buttonClick(index)"
        >{{index == 0 ? '取消' : '确认'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showToast: {
      type: Boolean,
      default: false
    },
    titleType: {
      type: Number,
      default: 1 //1 保存方案 2 复制方案 3 替换暂存方案
    },
    titleDefault: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      showToastInfo: false,
      selectPlan: "", //旋转的部件
      planArr: ["方案1", "方案2", "方案3", "方案4"],
      planeName: "" //方案名称
    };
  },
  computed: {
  },
  watch: {
    planeName(newVal) {
      if (newVal.length > 20) {
        this.planeName = newVal.substring(0, 20);
      }
    }
  },
  methods: {
    //点击按钮
    buttonClick(ind) {
      if (ind == 1) {
        if (this.planeName == "") {
          this.$message({
            type: "error",
            message: "请输入方案名称"
          });
          return;
        }
        this.$emit("successInputToast", {
          name: this.planeName,
          type: this.titleType
        });
      }
      this.closeBox();

    },
    //关闭弹窗
    closeBox() {
      this.showToastInfo = false;
      setTimeout(() => {
        this.$emit("closeTextToast");
      }, 400);
    }
  },
  created() {},
  mounted() {
    if (this.showToast) {
      setTimeout(() => {
        this.showToastInfo = true;
      }, 100);
    }
    this.planeName =this.titleDefault ? this.titleDefault : this.$store.state.user.modelName;
  }
};
</script>
<style lang='scss' scoped>
/deep/ .el-select {
  width: 100%;
  height: 100%;
}
/deep/ .el-input {
  width: 100%;
  height: 100%;
}
/deep/ .el-input > input {
  width: 100%;
  height: 100%;
}
.toastBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  justify-content: center;
  div {
    box-sizing: border-box;
  }
  .infoBox {
    border-radius: 8px;
    width: 400px;
    min-height: 200px;
    padding-top: 19px;
    background: white;
    position: relative;
    transition: all 0.5s;
    overflow: hidden;
    .closeBox {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 21px;
      right: 19px;
    }
    div {
      text-align: center;
    }
    .title {
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 600;
    }

    .bottomBox {
      position: absolute;
      width: 100%;
      height: 49px;
      bottom: 0;
      left: 0;
      line-height: 49px;
      border-top: 1px solid #e1e1e1;
      color: #0099ff;
      display: flex;
      cursor: pointer;
      .button {
        flex: 1;
        height: 100%;
        text-align: center;
      }
      .button:nth-child(1) {
        border-right: 1px solid #e1e1e1;
        color: #999999;
      }
    }
    .inputBox {
      width: 100%;
      padding: 40px 0px 30px 0px;

      .info {
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          width: 200px;
          height: 34px;
          margin-left: 5px;
        }
        .input {
          border: 1px solid #e8e8e8;
          border-radius: 2px;
          display: flex;
          padding: 6px 0px 6px 10px;
        }
      }
    }
  }
}
</style>