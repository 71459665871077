import initAligningGuidelines from './js/aligning_guidelines'
import {
    setInterval
} from 'core-js';
import { drawUV } from '../edit/js/UVHelper.js';
import { MaterialMapManager } from './MaterialMapManager';
export class Edit {
    constructor(info) {
        this.maxWidth = info.maxWidth;
        this.maxHeight = info.maxHeight;
        this.dom = info.dom;
        this.editCanvas = null; //容器

        this.editTarget = null; //当前编辑的目标

        this.threeInfo = null; //3D部分

        this.first = true;
        this.nowPosColor;

        this.canAddInfo = true; //是否可以添加


        this.uvSize = {
            x: 0,
            y: 0
        }; //UV尺寸

        this.num = 0

        this.srcURL = "";
        this.matMap = new MaterialMapManager();
    }

    // 画uv线
    drawUV(obj, width, height) {
        const ctx = document.querySelector( '#uv' ).getContext( '2d' );

        function draw( geometry, width, height ) {
            const modelName = app.viewer.modelGroup.getObjectByName('RootNode').children[0].name;
            const canvas = drawUV( geometry, width, height, modelName, obj.name );
    
            ctx.clearRect( 0, 0, width, height );
            ctx.drawImage( canvas, 0, 0, width, height );
        }

        draw( obj.geometry, width, height );
    }

    //初始化canvas编辑器
    initEdit(width, height, type = 1) {
        console.log('初始化canvas编辑器')
        let _this = this;
        this.uvSize.x = width;
        this.uvSize.y = height;
        let targetWidth, targetHeight;
        if (type == 1) {
            targetWidth = this.maxHeight * width;
            targetHeight = this.maxHeight * height;
        } else {
            targetWidth = width;
            targetHeight = height;
        }
        this.editCanvas = new fabric.Canvas(this.dom, {
            width: targetWidth,
            height: targetHeight,
            targetFindTolerance: 10,
            perPixelTargetFind: true,
        });

        let event = document.createEvent('HTMLEvents');
        event.initEvent('editOk', false, false);
        document.body.dispatchEvent(event)
        this.editCanvas.setBackgroundColor('rgba(255,255,255,0.002)');
        initAligningGuidelines(this.editCanvas);
        window.editCanvas = this.editCanvas

        let deleteImg = document.createElement("img");
        let deleteIcon =
            "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
        deleteImg.src = deleteIcon;

        //  选中的效果修改
        //删除的控制器
        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 0,
            offsetX: 0,
            cursorStyle: "pointer",
            mouseUpHandler: deleteObject,
            render: renderIcon(deleteImg),
            cornerSize: 20,
        });
        fabric.Object.prototype.setControlVisible("mt", false);
        fabric.Object.prototype.setControlVisible("mr", false);
        fabric.Object.prototype.setControlVisible("mb", false);
        fabric.Object.prototype.setControlVisible("ml", false);
        fabric.Object.prototype.setControlVisible("tr", false);
        fabric.Object.prototype.cornerStyle = "circle";
        fabric.Object.prototype.cornerSize = 6;
        //设置边框球体内部颜色
        fabric.Object.prototype.cornerColor = "#FA5151";
        //边框颜色
        fabric.Object.prototype.borderColor = "#FA5151";

        //设置内部实心
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.rotatingPointOffset = 15;
        //调整距离对象的边距
        fabric.Object.prototype.padding = 0;
        //设置边框间隔
        fabric.Object.prototype.borderDashArray = [3, 3];


        function deleteObject(e, target) {
            let canvas = _this.editCanvas;
            var isText = false;
            if(canvas._activeObject){
                if(canvas._activeObject.editType != 2){
                    _this.matMap.remove(canvas._activeObject.uid);
                    app.modelMap.currentMesh.remove(canvas._activeObject.uid);
                }else{
                    isText = true;
                }
                if (canvas._activeObject.text) {

                    const div = document.querySelector(`#${canvas._activeObject.id}`);
                    document.querySelector('.canvas-container').removeChild(div);
                }
                canvas.remove(canvas._activeObject)
            }

            if(isText === true){
                _this.exportModelImg().then(res=>{
                    app.modelMap.currentMesh.refreshText(res);
                  });
            }

            document.dispatchEvent(_this.changeSelect)

           
        }

        function renderIcon(icon) {
            return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
                var size = 12;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(icon, -size / 2, -size / 2, size, size);
                ctx.restore();
            };
        }

        this.threeInfo = window.app;
        this.createcustomFun()



        return this.editCanvas

    }

    //创建所有需要的自定义方法
    createcustomFun() {
        //修改下标
        this.changeSelect = document.createEvent('HTMLEvents');
        this.changeSelect.initEvent('changeSelect', false, false);

        this.changeSize = document.createEvent('HTMLEvents');
        this.changeSize.initEvent('changeSize', false, false);

    }
    // 生成随机id
    guid() {
        const str  = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let len = str.length;
        let randStr = ''; //随机字符串
        for (let i=0;i<16;i++){
            let index = Math.floor(Math.random()*len);
            randStr += str[index];
        }
        return randStr;
    }
    // 创建文本的边框
    createBox(obj) {
        const div = document.createElement('div');
        div.className = 'border-wrap';
        div.id = obj.id;
        document.querySelector('.canvas-container').appendChild(div);
        if (obj) this.updateBoxInfo(obj);
    }
    // 更新文本边框的信息
    updateBoxInfo(obj) {
        
        const activeObject = obj || this.editCanvas._activeObject;
        if (!activeObject) return;
        const id = activeObject.id;
        if (id) {
            
            let div = document.querySelector(`#${id}`);
            if (!div) return;
            div.style.width = activeObject.width + 'px';
            div.style.height = activeObject.height + 'px';
            div.style.left = activeObject.left - activeObject.width / 2 + 'px';
            div.style.top = activeObject.top - activeObject.height / 2 + 'px';
            div.style.transform = `scale3d(${activeObject.scaleX}, ${activeObject.scaleY}, 1) rotate(${activeObject.angle}deg)`;
        }
    }
    // 移除文本边框
    removeBox() {
        const divs = document.querySelectorAll('.border-wrap');
        const parent = document.querySelector('.canvas-container');
        for(let i = 0; i < divs.length; i++) {
            parent.removeChild(divs[i]);
        }             
    }


    addTexture(url){

        this.addEditImg(url, 1).then(re => {
            let img = re;

            const editData = {
                src: url,
                transform:{
                    angle: 0,
                    left: re.left,
                    top: re.top,
                    scaleX: re.scaleX,
                    scaleY: re.scaleY,
                }
            }
            const mat = this.matMap.add("",app.modelMap.currentMesh.modelName, editData);
            img.uid = mat.uid;

            app.modelMap.refresh(mat.uid,url,{x:re.scaleX,y:re.scaleY},{x:re.left,y:re.top},0);
        })
    }

    //编辑图片
    addEditImg(url, type = 1) {
        console.log('编辑图片')
        this.srcURL = url;
        return new Promise((res, rej) => {
            fabric.Image.fromURL(url, (img) => {
                let scale = 1;
                if (img.width > this.editCanvas.width) {
                    scale = Math.max(0.02, (this.editCanvas.width / img.width) * 0.4);
                }
                if (img.height > this.editCanvas.height) {
                    let scale2 = Math.max(0.02, (this.editCanvas.height / img.height) * 0.4);
                    scale = Math.min(scale, scale2);
                }

                img.scale(scale);

                img.left = this.editCanvas.width / 2;
                img.top = this.editCanvas.height / 2;

                img.editType = 1;
                img.selectable = true;

                img.originX = 'center';
                img.originY = 'center';

                this.addTargetToView(img, type) ;

                img.centerInfo = {
                    left : img.left , 
                    top  : img.top , 
                }

                res(img);
            }, {
                crossOrigin: "anonymous"
            });
        })
    }

    //添加文字
    addEditText(info, global) {
        let text = new fabric.Text(info, {
            lineHeight: .6,
            fontSize: 16,
            fill: '#000000'
        });
        text.editType = 2;
        text.left = this.editCanvas.width / 2; 
        text.top = this.editCanvas.height / 2;

        this.addTargetToView(text)
       
    }

    addTextPath(data, info) {
        let group = this.createGroup(data, info)
        this.addTargetToView(group)
        this.exportModelImg().then(res=>{
            //console.log(res)
            app.modelMap.currentMesh.refreshText(res);
        });
    }

    editTextPath(data, info) {
        let target = this.editCanvas._activeObject;
        let group = this.createGroup(data, info)
        this.editCanvas.remove(target)

        group.id = this.guid();
        
        this.addTargetToView(group)
        this.exportModelImg().then(res=>{
            //console.log(res)
            app.modelMap.currentMesh.refreshText(res);
        });
        if (target.id) {
            
            this.createBox(group);

            const div = document.querySelector(`#${target.id}`);
            document.querySelector('.canvas-container').removeChild(div);
        }
    }

    createGroup(data, info) {
        
        let group = new fabric.Group()
        data.forEach((t, index) => {
            let char = new fabric.Path(t);
            char.fill = info.textColor || "#000000";
            group.addWithUpdate(char);
        });
        group.textPath = data;
        group.fontFamily = info.familyName;
        group.color = info.textColor|| "#000000";
        group.loadPath = info.textPathData || '';
        info.textColor = group.color;
        group.textInfo = info
        group.originX = 'center';
        group.originY = 'center';

        if (typeof info === 'string') {
            group.text = info
            group.fontSize = 16
        } else {
            let target = this.editCanvas._activeObject;
            group.angle = info.angle || 0

            if (target) {
                group.left = target.left
                group.top = target.top
                group.angle = target.angle

                group.scaleX = group.scaleY = target.scaleX
            }

            group.fontSize = info.textSize || target.fontSize

            group.text = info.textDesc;
        }
        group.editType = 2;
        
        return group
    }

    setTextPath(global, defaultText = '') {
        let target = this.editCanvas._activeObject;
        if (!target) return
        let text = target.text || global.editInfo.textDesc;

        const fontSize = global.editInfo.textSize || target.fontSize;
        const getTextPath_ = () => {
            global.$api.system.getTextPath({
                textContent: defaultText || text,
                text: defaultText || text,
                pathData: global.editInfo.textPathData || '',
                fontFamily: global.editInfo.familyName,
                fontSize,
                alignment: global.editInfo.align,
                fontStyle: global.editInfo.isItalic ? 1 : 0,
                fontWeight: global.editInfo.isBold ? 1 : 0,
            }).then((data) => {
                data.splice(data.length - 1, 1);
                if (defaultText) {
                    this.addTextPath(data, defaultText)
                } else {
                    this.editTextPath(data, global.editInfo)
                }
            }).catch(() => {
                if (this.num < 2) {
                    setTimeout(() => {
                        this.num++;
                        getTextPath_();
                    }, 500)
                }
            })
        }

        getTextPath_();
    }
    //添加进场景
    addTargetToView(target, type = 1) {
        if (!this.canAddInfo) return
        this.threeInfo.canEditModel = true;
        this.threeInfo.openEidt = true;
        this.editCanvas.add(target);
        if (type == 1) {

            this.editCanvas.setActiveObject(target);
            this.editTarget = target;
          
        }
    }
    //修改文字信息
    changeSelectTextinfo(info, global, ) {
        let target = this.editCanvas._activeObject;
        if (target) {
            this.editTarget = target;
            if (target.fill != info.textColor) {
                target.fontSize = info.textSize - .5;
            }
            const _this = this
            
            // const div = document.querySelector(`#${target.id}`);
            target.text = info.textDesc;
            // if (div) div.id = target.text;

            target.fill = info.textColor;
            target.fontSize = info.textSize;
            if (target.text) {
                _this.setTextPath(global)
            } else {
                const div = document.querySelector(`#${_this.editCanvas._activeObject.id}`);
                document.querySelector('.canvas-container').removeChild(div);

                app.modelMap.currentMesh.removeText();

                _this.editCanvas.remove(_this.editCanvas._activeObject);

                
            }
        }

    }

    //修改文字样式
    changeTextStyle(info) {
        let target = this.editCanvas._activeObject;
        info.map(item => {
            if (item.name == '粗体') {
                target.fontWeight = !item.isSelect ? 'normal' : 'bold'
            }
            if (item.name == "斜体") {
                target.fontStyle = !item.isSelect ? 'normal' : 'italic'
            }
            if (item.name == "横排") {

            }
            if (item.name == "竖排") {

            }

        });
    }

    //导出模型图片
    async exportModelImg() {
        // let uvScale = 5;

        let cacheObjects = [], allObjects = [];

        for(let i = 0;i < this.editCanvas._objects.length;i ++){

            let o = this.editCanvas._objects[i];
            if(o.editType !== 2){
                o.i = i;
                cacheObjects.push(o);
            }
        }
        for(let i = 0;i < cacheObjects.length;i ++){
            this.editCanvas.remove(cacheObjects[i]);
        }
        // //this.editCanvas._objects
        let imgUrl = this.editCanvas.toDataURL({
            multiplier: 5 , 
            quality : 1 , 
        }, true);
       
        for(let i = 0;i < cacheObjects.length;i ++){
            
            this.editCanvas.insertAt(cacheObjects[i], cacheObjects[i].i, false);
        }

        return imgUrl;
        // console.log(imgUrl);
        // return imgUrl;
    }

    //获取canvasJSON 数据
    SaveToJson() {
        let JsonData = [];
        this.editCanvas._objects.map(item => {
            let obj = {};
            if (item.type == "image") {
                obj.src = item.getSrc();
                obj.type = item.type;

                obj.transform = {
                    left: item.left,
                    top: item.top,
                    angle: item.angle,
                    scaleX: item.scaleX,
                    scaleY: item.scaleY,
                };
            } else if (item.type == 'group') {
                obj.type = item.type;
                obj.textInfo = item.textInfo;
                obj.textPath = item.textPath;
                obj.transform = {
                    left: item.left,
                    top: item.top,
                    angle: item.angle,
                    scaleX: item.scaleX,
                    scaleY: item.scaleY,
                };
            }
            JsonData.push(obj);

        })

        JsonData.push({
            type: 'size',
            w: edit.editCanvas.width,
            h: edit.editCanvas.height,
        })



        return JsonData.length == 0 ? null : JSON.stringify(JsonData)

    }


    //加载转出的JSON格式
    async loadJsonToString(data, index) {

        if (!data.editData) {
            return;
        }
        await this.loadModelAera(data,index == 0,index);
        if(index == 0){
            app.modelMap.setCurrentMesh(data.modelName);
            this.firstCanvas = [];
            for(let i = 0;i < this.editCanvas._objects.length;i ++){
                this.firstCanvas.push(this.editCanvas._objects[i]);
            }
            for(let i  = 0;i < this.firstCanvas.length;i ++){
                this.editCanvas.remove(this.firstCanvas[i]);
            }
        }
        else{
            let removeObjs = [];
            for(let i = 0;i < this.editCanvas._objects.length;i ++){
                let obj = this.editCanvas._objects[i];
                if(obj && obj.index != 0){
                    removeObjs.push(obj);
                }
            }
            for(let i  = 0;i < removeObjs.length;i ++){
                this.editCanvas.remove(removeObjs[i]);
            }
        }
    }

    async loadModelAera(data,isLoadPanel,index){
            // this.loadJsonFun(editData)
        const editData = JSON.parse(data.editData);
        console.log(editData)
        for(let i = 0;i < editData.length;i ++){

            if(editData[i].type == 'image'){
                const mat = this.matMap.add(data.name,data.modelName, editData[i]);
                app.modelMap.addMesh(mat);

                if(isLoadPanel){
                    this.load(editData[i],mat.uid).then(res => {
                    })
                }
            }
            else if(editData[i].type == 'group'){
                let panel = await this.load(editData[i],"", isLoadPanel);
                panel.index = index;
                let img = await this.exportModelImg();
                app.modelMap.createMesh(data.name,data.modelName);
                let mesh = app.modelMap.getMeshByName(data.modelName);
                mesh.refreshText(img);
            }
        }
        this.activateObjects();
    }

    showFirstCanvas(){
        if(this.firstCanvas){
            for(let i  = 0;i < this.firstCanvas.length;i ++){
                this.editCanvas.add(this.firstCanvas[i]);
            }
            this.firstCanvas = [];
        }
        
    }

    /**
     * 根据部件切换面板图片
     * @param {*} data 
     */
    changeTexByMeshName(data){
        if(!data.editData){
            return;
        }
        const editData = JSON.parse(data.editData);
        let texArr = this.matMap.getTexArrByMeshName(data.modelName);
        for(let i = 0;i < texArr.length;i ++){
            this.load(texArr[i].toJson(),texArr[i].uid).then(res => {
            })
        }
        for(let i = 0;i < editData.length;i ++){
            if(editData[i].type == 'group'){
                this.load(editData[i],"");
            }
        }
        this.activateObjects();
    }

    load(item,uid, isLoadPanel = true) {
        console.log(item)
        return new Promise((res, rej) => {
            switch (item.type) {
                case 'image':
                    this.addEditImg(item.src, 2).then(re => {
                        let img = re;
                        img.uid = uid;
                        Object.assign(img, item.transform);
                        res(img);
                    })
                    break;
                case 'group':
                    let data = item.textPath;
                    let info = item.textInfo;

                    let group = this.createGroup(data, info);
                   
                    Object.assign(group, item.transform);
                    this.addTargetToView(group, 2);

                    if (isLoadPanel) {
                        setTimeout(() => {
                            group.id = this.guid();
                            this.createBox(group);
                        })
                    }
                    
                    //app.modelMap.
                    res(group);
                    break;
                case 'size':
                    this.editCanvas.setHeight(item.h);
                    this.editCanvas.setWidth(item.w);
                    res();
                    break;
            }
        })
    }

    loadJsonFun(list, type = 1, ind = 0) {
       
        let _this = this;
        if (!list) return

        let info = list[ind];
        if (ind > list.length - 1) {
            this.activateObjects();
        } else {
            load(info).then(res => {
                this.loadJsonFun(list, type, ind + 1)

            })
        }

    }


    //修改编辑图片的信息
    changeEditImage(info) {
        let target = this.editCanvas._activeObject;
        if(target){
            target.scaleX = target.scaleY = info.scaleNum / 100;
            target.angle = Number(info.rotateNum);

            if(target.editType != 2){
                app.modelMap.refresh(target.uid,"",{x:target.scaleX,y:target.scaleY},{x:target.left,y:target.top},target.angle)
                window.edit.matMap.syncJson(target,target.uid);
              }else{
                window.edit.exportModelImg().then(res=>{
                  app.modelMap.currentMesh.refreshText(res);
                });
            }
        }
        
    }

    //回归初始状态
    initialStats(type = 1) {

        let canvas = this.editCanvas;
        // canvas.backgroundColor = '';
        canvas._activeObject = null;
        let length = canvas._objects.length;
        if (canvas._objects.length == 0) {
            document.dispatchEvent(this.changeSelect)
        }
        for (let i = 0; i <= length - 1; i++) {
            canvas.remove(canvas._objects[0]);
        }

        canvas.renderAll();
    }

    //更新画布尺寸 切换部件
    updateCanvasSize(width, height, modelName) {
        this.editName = modelName;
        this.editCanvas.setHeight(height);
        this.editCanvas.setWidth(width);
        app.modelMap.setCanvasSize(width,height);

        this.threeInfo = window.app;

        this.editCanvas.renderAll()
        document.dispatchEvent(this.changeSize)

        // 画uv线
        const uv = document.querySelector('#uv');
        width = parseInt(width)
        height = parseInt(height)
        uv.setAttribute('width', width);
        uv.setAttribute('height', height);
        if (this.threeInfo.curEditPos) {
          console.log(this.threeInfo.curEditPos)
          window.edit.drawUV(this.threeInfo.curEditPos, width, height);
        }

    }

    activateObjects() {
        if (this.editCanvas._objects.length != 0) {
            this.editCanvas._objects.map(item => {
                item.setCoords();
            })
        }
    }

    //一键铺满
     oneKeyOver(){
         let object = this.editCanvas._activeObject ; 
         if(object){
            let canvas = this.editCanvas ; 
             let object = canvas._activeObject ; 
             let imgWidth = object.width  ; 
             let imgHeight = object.height ; 
             let canvasHeight = canvas.height ; 
             let canvasWidth = canvas.width ; 

             let scale = canvasHeight / imgHeight > 1 ? 1 : canvasHeight / imgHeight; 
            //  let scale = canvasHeight / imgHeight; 
             console.log(canvasHeight)
             console.log(imgHeight)


             //1 是图片满足条件  2不满足
             let type = 1 ; 

             if(canvasWidth != Math.floor(imgWidth * scale) ){
                type = 2 ; 
             }

             this.centerObject() ; 
             return  {
                 scale : scale , 
                 type  : type , 
             } ; 
                
         }
     }

     //图片重新居中
     centerObject(){
        let object = this.editCanvas._activeObject ; 
        if(object){
            object.left = object.centerInfo.left ; 
            object.top = object.centerInfo.top ; 
               
        }
     }

     //取消选中
     uncheckImg(){
         if(this.editCanvas._activeObject){
            this.editCanvas._activeObject = null ; 
         }
     }
}