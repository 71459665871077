<template>
  <div class="head">
    <div class="returnIndex" @click="returnIndex">返回首页</div>

    <div class="title">{{goodsName}}</div>

    <!-- 右侧Icon -->
    <div class="headBox" v-if="!planId">
      <div class="icon" v-if="headType == 1 && !cartId">
        <div class="box" v-for="(item,index) in 3" :key="index" @click="openFun(index)">
          <div
            class="bj"
            v-if="index == 0 && !canSave ||  index == 1&& !canPreview || index == 2  && !canBuy"
          ></div>
          <div
            class="img"
            v-if="index !== 0"
            :style="`background:url(${require(`@/assets/home/icon${index + 1}.png`)}) no-repeat 50% 50% / 100% ;width:${index == 0 ? 17 : index == 1 ? 20 : 18 }px;height:${index == 0 ? 17 : index == 1 ? 13 : 18 }px `"
          ></div>
          <div class="txt" v-if="index !== 0">{{index == 0 ? '保存' : index == 1 ? '预览' : '加入购物车' }}</div>
          <div class="cartNum" v-if="index == 2 && addCartNum >= 1">{{addCartNum}}</div>
        </div>
        <div class="box" @click="jumpCart">
          <div
            class="img"
            :style="`background:url(${require(`@/assets/home/icon3.png`)}) no-repeat 50% 50% / 100% ;width:18px;height:18px`"
          ></div>
          <div class="txt">查看购物车</div>
        </div>
      </div>

      <!-- <div class="icon" v-else-if="headType == 1 && cartId && ticket">
        <div class="box" @click="openFun(2)">
          <div
            class="img"
            :style="`background:url(${require(`@/assets/home/icon1.png`)}) no-repeat 50% 50% / 100% ;width:17px;height:17px `"
          ></div>
          <div class="txt">保存</div>
        </div>
      </div> -->

      <div class="icon" v-else-if="headType == 2">
        <div class="box" @click="showShare">
          <div
            class="img"
            :style="`background:url(${require(`@/assets/home/share.png`)}) no-repeat 50% 50% / 100% ;width:18px;height:17px `"
          ></div>
          <div class="txt">分享</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";
import {btof} from '@/utils/tools.js';
let app;
export default {
  props: {
    headType: {
      type: Number,
      default: 1
    }
  },
  components: {},
  data() {
    return {
      canPreview: true, //是否能点击预览
      canSave: false, //可以保存
      headName: "", //标题名字
      canBuy: false, //能否加入购物车
      canSaveCar: true, //阻止重复点击
      ticket: null,
      addCartNum: 0, //已加入的次数
    };
  },
  computed: {
    indexUrl() {
      return this.$store.state.user.indexUrl;
    },
    shopingUrl() {
      return this.$store.state.user.shopingUrl;
    },
    goodsName() {
      return  this.$route.query.goodsName || this.$store.state.user.modelName;
    },
    modelId() {
      return this.$store.state.user.lzyProductCode;
    },
    cartId() {
      return this.$store.state.user.cartId;
    },
    planId() {
      return this.$store.state.user.planId;
    }
  },
  watch: {},
  methods: {
    //保存方案
    saveCartCase() {},
    //返回到分享页面
    returnIndex() {
      window.location.href = this.indexUrl || "https://shuyangtech.com/";
    },
    //执行按钮方法
    openFun(ind) {
      if (ind == 2) {
        this.uploadImg();
        return;
      } else if (ind == 1) {
        if (!this.canPreview) return;
        let routeData = this.$router.resolve({ path: "/share" });
        window.open(`${routeData.href}?lzyProductCode=${this.modelId}&goodsName=${this.goodsName}`, "_blank");
        return;
      }
      Bus.$emit("executeFun", {
        ind: ind,
        name: this.headName,
        id: this.caseId,
        info: this.planInfo
      });
    },
    //展示分享弹窗
    showShare() {
      this.$emit("showShare");
    },
    //上传图片
    uploadImg() {
      app = window.app;

      let info = app.getEditPosInfo();
      //材质层级
      this.colorIndex = info.colorIndex;
      //贴图层级
      this.components = info.textureIndex;
      // Bus.$emit("closeLoading", true);
      
      this.uploadBase64File(this.components, 0);
    },

    //构建参数
    structureData() {
      let arr = [];
      //先放一个默认的
      let colorIndex_;
      if (!this.cartId) {
        // lc用当前保存的方案数据加入购物车
        const obj = this.$store.state.cases.case;
          console.log(obj.ColorIndex, 'obj.ColorIndex')
        if (obj.ColorIndex) {
          colorIndex_ = JSON.parse(obj.ColorIndex);
        }
        // let ColorIndex = this.colorIndex[0];
        colorIndex_ = colorIndex_.map((m, index) => {
          return {
            cName: m.name,
            color: m.color || this.colorIndex[index].color,
            id: m.materialId,
            imgUrl: this.colorIndex[index].imgUrl,
            name: this.colorIndex[index].name
          }
        })
      } else {
        colorIndex_ = this.colorIndex;// lc用当前编辑的数据加入购物车，在购物车编辑时
      }

      colorIndex_.map((item, index) => {
        if (item.imgUrl && item.imgUrl.indexOf("basis") != -1) {
          item.imgUrl = null;
        }
        let obj = {};
        obj.componentCode = item.name;
        obj.componentImage = item.imgUrl;

        if (item.id == "") {
          let info = app.defaultMaterialArr.find(ite => {
            return ite.name == item.cName;
          });
          item.id = info.materialId;
        }

        obj.componentSpecs = [
          {
            productSpecCode: 1,
            productSpecValueCode: item.color
          },
          {
            productSpecCode: 2,
            productSpecValueCode: item.id
          }
        ];
        arr.push(obj);
      });

      this.components.map(item => {
        let obj = {};
        obj.componentCode = item.name;
        obj.componentImage = item.imgUrl;

        obj.componentSpecs = [
          {
            productSpecCode: 1,
            productSpecValueCode: "#ffffff"
          },
          {
            productSpecCode: 2,
            productSpecValueCode: app.craftInfo
              ? app.craftInfo.id
              : this.$store.state.user.defaultCrafId
          }
        ];
        arr.push(obj);
      });

      return arr;
    },

    //创建订单
    createPlan() {
      if (!this.canBuy && !this.cartId) {
        this.$message({
          type: "error",
          message: "请先保存该方案~"
        });
        return;
      }
      if (!this.canSaveCar) {
        this.$message({
          type: "error",
          message: "请稍后再试试~"
        });
        return;
      }

      Bus.$emit("closeLoading", true);

      this.canSaveCar = false;
      setTimeout(() => {
        this.canSaveCar = true;
      }, 3000);

      let info = this.planInfo;

      let data = {};

      if (this.cartId) {
        data = {
          modelId: String(this.modelId),
          editData: JSON.stringify(app.getSaveInfo()),
          name: info.name,
          previewUrl: info.previewUrl
        };
      } else {
        // lc 不是购物车时用已保存的方案作为购物车方案
        const obj = this.$store.state.cases.case;
        data = {
          modelId: String(this.modelId),
          editData: JSON.stringify(obj),
          name: info.name,
          previewUrl: info.previewUrl
        };
        console.log(data, 'datadata')
      }

      //修改方案
      if (this.cartId) {
        data.id = this.cartId;
        // let imgdata = {
        //   base64: app.shotScene()
        // };

        const uploadData = new FormData();
        uploadData.append("file", btof(app.shotScene(), 'a'));

        this.$api.system.uploadFile2(uploadData).then(ImgUrl => {
          this.$api.cartCase.reviseCartCase(data).then(res => {
            this.addCart(this.cartId, ImgUrl, 2);
          });
        });
        return;
      }

      this.$api.cartCase.addCartCase(data).then(res => {
        this.addCart(res);
      });
    },

    //加入购物车
    addCart(cartId, url, type) {
      if (!cartId) return;
      let info = this.planInfo;
      let data = {
        planCode: cartId,
        planName: info.name,
        previewImage: url ? url : info.previewUrl,
        productCode: info.modelId,
        modelUrl: `https://canvas.shuyangtech.com?cartId=${cartId}`,

        productId: this.$store.state.user.productId
      };
      // lc 把客户那边拿的数据加入购物车时传过去
      if (this.$store.state.user.otherComponents) {
        data.otherComponents = JSON.parse(this.$store.state.user.otherComponents);
      }

      let components = this.structureData();
      data.components = components;

      this.$api.shuyang.addCart(data).then(res => {
        Bus.$emit("closeLoading", false);
        this.$message({
          type: "success",
          message: (type == 1 ? "加入成功~" : "保存成功~")
        });

        // setTimeout(() => {
        //   window.location.href = this.shopingUrl;
        // }, 2000);
        this.addCartNum += 1;
      });
    },

    //跳转购物车
    jumpCart() {
        window.location.href = this.shopingUrl ? this.shopingUrl : 'https://shuyangtech.com/#/shopping-cart';
    },
    //上传图片
    uploadBase64File(list, ind) {
      return new Promise((res, rej) => {
        if (list.length < ind + 1) {
          this.createPlan();
          return;
        }
        let url = list[ind].imgUrl;
        if (!url || url.indexOf("base64") == -1) {
          this.uploadBase64File(list, ind + 1);
          return;
        }
        // let data = {
        //   file: btof(url, 'a')
        // };

        // console.log(data, 'btof(url, a)')

        const uploadData = new FormData();
        uploadData.append("file", btof(url, 'a'));

        this.$api.system.uploadFile2(uploadData).then(res => {
          this.components[ind].imgUrl = res;
          this.uploadBase64File(list, ind + 1);
        });
      });
    },
    
  },
  created() {},
  mounted() {
    this.ticket = this.$route.query.ticket;
    //修改标题名字
    Bus.$on("changeTitle", val => {
      let info = val.list[val.index];
      if (info) {
        this.canSave = true;
        this.planInfo = info;
        this.headName = info.name;
        this.caseId = info.id || null;
        this.canBuy = info.editData ? true : false;
      }
      let length = val.list.length;
      if (length <= 1) {
        //判断是否可以预览
        if (length == 0) {
          this.canPreview = false;
        } else {
          let index = val.list.findIndex(item => {
            return item.isSave == false;
          });
          this.canPreview = index != -1 ? false : true;
        }
      } else {
        this.canPreview = true;
      }
    });

    Bus.$on('saveModel', () => {
      if (!this.planId && this.headType == 1 && !this.cartId) {
        this.openFun(0);
      }
      if (!this.planId && this.headType == 1 && this.cartId && this.ticket) {
        this.openFun(2);
      }
    })
  }
};
</script>
<style lang='scss' scoped>
.head {
  width: 100%;
  height: 80px;
  background: #0099ff;
  padding: 27px 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .headBox {
    height: 100%;
  }
  .returnIndex {
    width: 98px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    color: #0099ff;
    text-align: center;
    position: absolute;
    left: 40px;
    cursor: pointer;
  }
  .title {
    font-size: 26px;
    font-weight: bold;
    color: #ffffff;
    max-width: 540px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon {
    position: absolute;
    right: 40px;
    display: flex;
    .box:hover {
      cursor: pointer;
      transform: scale(0.9);
      box-shadow: 0px 0px 3px #f0f0f0;
    }
    .box {
      margin-right: 21px;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.5s;
      padding: 5px;

      .bj {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: rgba($color: #0099ff, $alpha: 0.5);
      }
      .img {
        margin-right: 11px;
      }
      .txt {
        font-size: 16px;
        color: #ffffff;
      }
      .cartNum {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        background: red;
        text-align: center;
        line-height: 20px;
        color: white;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>