import store from '../../store/index.js';
import vue from '../../main.js';
import Bus from "@/utils/bus.js";
import { ModelMappingManager } from './ModelMappingManager.js';
export class App {
    constructor(dom) {
        this.dom = dom;


        this.editMaterial = null; //编辑的canvas

        this.editModel = null; //编辑贴图的模型

        this.editImg = null;

        this.loadModelArr = []; //已经加载过的模型

        this.editPosInfo = []; //编辑的部件

        this.editMaterialInfo = []; //编辑材质的部件

        this.canEditModel = false; //可以编辑模型

        this.editPosEditInfo = []; //编辑区域的信息

        this.openPrev = false; //开启预览

        this.clientX = 0;
        this.clientY = 0;

        this.defaultMaterialArr = []; //默认材质数组

        this.editColorModel = null; //编辑的颜色模型

        // this.editTextureArr = []; //存放贴图数据的数组

        this.modelMaterlInfo = []; //存放当前方案的材质信息

        this.partList = []; //部件列表

        this.craftInfo = null; //工艺信息

        this.oneMatInfo = {};// 材质列表的第一个材质信息

        this.modelMap = new ModelMappingManager();
    }


    initViewer() {
        this.viewer = new AMRT.Viewer(document.getElementById(this.dom), {
            nv: 1,
        });
        
        this.viewer.addEventListener(AMRT.Event.UPDATE, this.animate.bind(this))

        this.editInfo = window.edit;

        this.modelClickFun();

        // this.createNewRender();

        return this.viewer
    }




    //渲染更新回调
    animate() {
        let info = this.editInfo.editCanvas;
        if (info) {
            info.renderAll(true)
        }
        if (this.openEidt) {
            this.updateImg()
            this.editInfo.updateBoxInfo();
        }
        if (this.openPrev) {
            let model = this.viewer.modelManager.modelGroup.getObjectByName('RootNode');
            model.rotation.y += 0.01
        }

        if (this.newRenderInfo) {
            this.newRenderInfo.render.render(this.newRenderInfo.scene, this.newRenderInfo.camera);
        }

        if (this.materialSphere && this.materialSphere.children.length != 0) {
            this.materialSphere.children.map(item => {
                item.rotation.y += 0.1;
            })
        }
    }

    //寻找当前模型是否加载过了
    findLoadModel(id) {
        let model = this.loadModelArr.find(item => {
            return item.userData.modelId == id;
        })

        return model
    }

    //初始化模型信息数据
    initializeModel() {
        this.editMaterialInfo = [];
        this.editPosEditInfo = [];
        this.editPosInfo = [];
        this.canEditPosArr = [];
        this.partList = [];
    }

    //初始化模型
    initModel(url, arr, id) {

        console.log("开始加载模型-----------------")

        return new Promise((res, rej) => {
            if (arr[0].name === 'xinglipai') {
                this.viewer.UserOldTangents = true;
            } else {
                this.viewer.UserOldTangents = false;
            }
            this.canEditModel = false;
            this.initializeModel();
            this.deleteModel();


            let haveModel = this.findLoadModel(id);
            if (haveModel) {
                this.editModel = haveModel.clone();

                this.modelMap.initModel(this.editModel);
                let modelInfo = haveModel.getObjectByName('RootNode').children[0];
                

                this.viewer.add(this.editModel)
                this.handleModelInfo(arr).then((data) => {
                    console.log("------------------模型加载完成")
                    res(data)
                    this.initializeNowModel();

                    this.resetAttribute(modelInfo);
                })
            } else {
                this.modelHasLoad = false;
                this.matHasLoad = false;
                
                this.viewer.loadModel(url, {
                    // background: false,
                    addModel: false,
                    onLoad: async model => {
                        
                            let modelInfo = model.getObjectByName('RootNode').children[0];


                            // 单独处理名称错误问题
                            const TextureInde = model.getObjectByName('TextureInde');
                            if (TextureInde) {
                                TextureInde.name = 'TextureIndex';
                            }

                            this.modelId = id;
                            model.userData.modelId = id;

                            const colorIndex = model.getObjectByName('ColorIndex');
                            if (colorIndex && colorIndex.children.length > 0) {
                                colorIndex.children.sort((a, b) => a.name - b.name);// 对材质颜色层排序
                            }
                            const textIndex = model.getObjectByName('TextureIndex');
                            if (textIndex && textIndex.children.length > 0) {
                                textIndex.children.sort((a, b) => a.name - b.name);// 对贴图层排序
                            }

                            this.loadModelArr.push(model);

                            this.editModel = this.loadModelArr[0].clone();

                            const matList = [];
                            
                            // lc 防止多个材质区域时，材质颜色相互引用的问题
                            const colorIndex_ = this.loadModelArr[0].getObjectByName('ColorIndex');
                            if (colorIndex_) {
                                const list = colorIndex_.children || [];
                                if (colorIndex_ && list.length > 0) {

                                    list.forEach((f, i) => {
                                        if (i > 0) {

                                            matList[i] = [f.material[0].clone()];
                                        }
                                    })
                                    this.editModel = this.loadModelArr[0].clone();

                                    this.editModel.getObjectByName('ColorIndex').children.forEach((f, i) => {
                                        if (i > 0) {
                                            f.material = matList[i];
                                        }
                                    })
                                }
                            }

                            
                            
                            this.viewer.add(this.editModel)
                            this.modelMap.initModel(this.editModel);
                            this.modelHasLoad = true;
                            const r = await this.matLoadComplete(arr);
                            console.log("------------------模型加载完成")
                            res(r);
                        // this.resetAttribute(modelInfo);



                        // this.createModelPositionSize(modelInfo) ; 
        
                    },

                    onMatLoad: async info => {
                        
                        setTimeout(() => {
                            this.viewer.sceneManager.setBackground('#828282');
                        }, 1000)
                        this.matHasLoad = true;
                        const r = await this.matLoadComplete(arr);
                        res(r);
                    }
                })
            }
        })
    }

    matLoadComplete(arr){
        return new Promise(resolve => {
            if(this.modelHasLoad && this.matHasLoad){
                this.handleModelInfo(arr).then((data) => {
                    resolve(data)
                    this.initializeNowModel();
                    
                    let model = this.viewer.modelGroup.getObjectByName('RootNode').children[0];
                    this.resetAttribute(model);
        
                })
            }
        })

    }

    resetAttribute(modelInfo) {
        
        // 单独处理一些模型，改变平行光和环境光强度和平行光是否跟随相机移动
        const models = [
            {name: 'maipai', dirIntensity: 0, ambIntensity: 0.8, lightFollow: false},
            {name: 'tiezhi', dirIntensity: 0, ambIntensity: 0.8, lightFollow: false},
            {name: 'zhishouhuan', dirIntensity: 0.2, ambIntensity: 0.7, lightFollow: false},
            // {name: 'dancengzhibei', dirIntensity: 0.3, ambIntensity: 0.7, lightFollow: false},
            // {name: 'beitao', dirIntensity: 0.3, ambIntensity: 0.7, lightFollow: false},
            {name: 'lushu', dirIntensity: 0.3, ambIntensity: 0.7, lightFollow: false},
            {name: 'xiongka', dirIntensity: 0.3, ambIntensity: 0.7, lightFollow: false},
        ];
        if (modelInfo) {
            const findObj = models.find(f => f.name === modelInfo.name);
            if (findObj) {
                // this.viewer.sceneManager.directLight.intensity = findObj.dirIntensity;
                // this.viewer.sceneManager.ambientLight.intensity = findObj.ambIntensity;
                this.viewer.lightFollow = findObj.lightFollow;
            } else {
                // 处理有些模型加载完后灰色问题
                setTimeout(() => {
                    this.viewer.sceneManager.directLight.position.copy(this.viewer.sceneManager.camera.position.clone());
                }, 1000)
            }
        }

        // 单独处理手环发光
        if (modelInfo.name === 'faguangshouhuan') {
            setTimeout(() => {
                const root = this.viewer.modelGroup.getObjectByName('RootNode').children[0];
                this.viewer.unrealBloom.add(root);
               
                this.viewer.unrealBloom._bloomPass.strength = 0.44;
                this.viewer.unrealBloom._bloomPass.radius = 1;
                this.viewer.enableUnrealBloom=true;
            }, 2000)
        }
        // 单独处理餐盘旋转问题
        if (modelInfo.name === 'canpan') {
            setTimeout(() => {
                const root = this.viewer.modelGroup.getObjectByName('RootNode');
                root.rotation.x = Math.PI/2;
            }, 2000)
        }

        // 单独处理模型闪烁问题
        const models2 = [
            {name: 'tingchezheng', near: 1},
            {name: 'shouka', near: 1},
            {name: 'lushu', near: 0.1},
            {name: 'caidan', near: 0.1},
            {name: 'jiangpai', near: 0.01},
            {name: 'wenshengtie', near: 0.001},
            {name: 'xintouzhi', near: 0.001},
            {name: 'canquan', near: 0.001},
            {name: 'zhuobu', near: 0.01},
            {name: 'fangweitie', near: 0.01},
        ];
        if (modelInfo) {
            const findObj = models2.find(f => f.name === modelInfo.name);
            if (findObj) {
                this.viewer.sceneManager.camera.near = findObj.near;
                this.viewer.sceneManager.camera.updateProjectionMatrix();
            }
        }

        // 单独处理场景的背景颜色
        const models3 = ['tiezhi'];
        if (modelInfo && models3.includes(modelInfo.name)) {
            setTimeout(() => {
                // this.viewer.sceneManager.setBackground('#E7E6E6');
            }, 1000)
        }

        setTimeout(() => {
            this.viewer.sceneManager.setBackground('#828282');
        }, 1000)

        // 单独处理一些模型的视角
        const models4 = [
            {name: 'yinzhang', position: [-0.015441003295820342, -0.014284791213220648, 0.40737835237324216]},
            {name: 'caidan', position: [-2.820084177290738e-8, -2.0330309024094915e-10, 2.823176320348881]},
            {name: 'xiongka', position: [9.84436042017478e-7, -1.0477972031067771e-10, 14.83618257669246]},
            {name: 'beitao', position: [0.0002177751039127429, 0.15648278147512704, 3.160251526140368]},
            {name: 'jiancaidai', position: [-0.01661720255706095, 1.7477720176401936, 1.129828797227339]},
            {name: 'huizhang', position: [-0.04041277960341649, -0.15364043205042868, 0.035315464743225305]},
            {name: 'zhishouhuan', position: [-0.01988755054053138, 0.40681274095584213, 0.8642138855417292]},
            {name: 'makebei', position: [0.04597365280858101, 0.004729386653965092, 0.6624652144744113]},
            {name: 'xinglipai', position: [0.01132782719484742, 0.06837027314500593, 0.22393650904332152]},
            {name: 'huanbaozhizhijinghe', position: [-0.49364872362084067, 0.693521137998714, 0.6978792459469224]},
            {name: 'yakelizhijinghe', position: [-0.4881214185707186, 0.5686782056784466, 0.6291394623012574]},
            {name: 'fangweitie', position: [1.704512889853707, -0.08149030562735748, 10.983679563512071]},
            {name: 'lawangzhanjia', position: [-11.450067715995006, 4.253314586350282, 13.609197406150972]},
            {name: 'faguangshouhuan', position: [-0.008323033332752614, 0.0036792789622194827, 0.009062193253828218]},
            {name: 'zhuobu', position: [-5.19285175773971, 6.785668001305681, 10.295266135619473]},
            {name: 'guijiaoshouhuan', position: [-0.0058156944807417855, 0.04068872390375935, 0.11321995315543892]},
            {name: 'cheyi', position: [1.3019801750511142, 0.9871681329401973, 1.1885297550201868]},
            {name: 'bangqiumao', position: [0.015218199130613164, -0.000017417093755684246, 0.0063286449898868335]},
            {name: 'zhidai', position: [-0.819326701335994, 0.8188879135982702, 1.3362342554827256]},
            {name: 'bushouhuan', position: [0.10415616046043823, -1.276937898367245, 0.5960874097372111]},
            {name: 'sanzhetaika', position: [-1.593779510358573, 0.43953380122191466, 1.3953501029135695]},
            {name: 'feijihe', position: [-0.8840676669940405, 0.7194837195677471, 1.5177217322046792]},
            {name: 'choulashilihe', position: [2.0980437875893694, 0.9026769645727064, 2.736396263851704]},
            {name: 'shubengailihe', position: [-2.3447584266272923, 0.7846717602183082, 2.6036109961811307]},
            {name: 'tiandigailihe', position: [-1.8876209086917406, 0.22592910124967866, 1.2379271261975786]},
        ];
        if (modelInfo) {
            const findObj = models4.find(f => f.name === modelInfo.name);
            if (findObj) {
                // setTimeout(() => {
                    this.viewer.sceneManager.camera.position.fromArray(findObj.position);
                // }, 500)
            }
        }

    }

    //计算模型和相机的距离
    createModelPositionSize(model) {
        let cameraPosition = this.viewer.camera.position;
        let targetPosition = model.position;

        let distance1 = targetPosition.clone().length();
        let distance2 = cameraPosition.clone().length();
        let distance = distance2 - distance1;
        this.viewer.controls.minDistance = distance / 8;
        this.viewer.controls.maxDistance = distance / 6;

    }

    //处理模型中文名称  type 1 是贴图  2 是材质
    handleModelChineseName(model, infoArr, type, name) {
        model.traverse((obj) => {
            let info = infoArr.find((item) => {
                return obj.name == item.name;
            })

            if (info) {
                obj.userData.cName = info.cname;
                if (name) {
                    obj.userData.partName = name;
                }
                if (type == 2) {
                    obj.userData.defaultMaterialInfo = info.ks[0];
                    obj.userData.defaultMaterial = info.ks[0].matDefault != '' ? info.ks[0].matDefault : info.ks[0].mat[0];
                    let materialObj = {
                        name: info.cname,
                        materialId: obj.userData.defaultMaterial,
                    }
                    this.defaultMaterialArr.push(materialObj)
                }
            }
        })
    }

    //处理模型信息
    handleModelInfo(arr) {
        //存放编辑过的数据
        let infoarr = this.editPosInfo;
        this.editPosInfo = [];
        this.defaultMaterialArr = [];
        let model = this.viewer.modelGroup.getObjectByName('RootNode').children[0];

        //编辑贴图logo区域
        let modelTextureArr = [];
        //编辑材质颜色区域
        let modelColorArr = [];
        let partArr = [];

        if (arr.length == 1) {
            let item = arr[0]

            let modelSelf = model.getObjectByName(item.name);
            let textureArr = modelSelf.getObjectByName("TextureIndex");
            let colorArr = modelSelf.getObjectByName('ColorIndex');

            let info = item.arr[0]
            if (textureArr) {
                this.handleModelChineseName(textureArr, info.textureArr, 1)
                modelTextureArr = textureArr.children.length != 0 ? [...textureArr.children] : [textureArr];
            }
            if (colorArr) {
                this.handleModelChineseName(colorArr, info.colorArr, 2)
                modelColorArr = colorArr.children.length != 0 ? [...colorArr.children] : [colorArr];
            }
        } else {
            let goodsModel = model;
            arr.map(item => {
                let partInfo = {
                    partName: item.partCname,
                    partArr: [],
                };
                let ColorArr = [];
                let TextureArr = [];
                item.arr.map((partItem, index) => {
                    let name = `${goodsModel.name}_${item.name}_${partItem.name}`;
                    let partModel = goodsModel.getObjectByName(name);
                    partModel.userData.partName = partItem.cName;
                    if (index != 0) {
                        partModel._visible = false;
                    }

                    if (partInfo) {
                        partModel.userData.preview = partItem.preview
                        partInfo.partArr.push(partModel)
                    }

                    let textureArr = partModel.getObjectByName("TextureIndex");
                    let colorArr = partModel.getObjectByName('ColorIndex');

                    if (colorArr) {
                        this.handleModelChineseName(colorArr, partItem.colorArr, 2, name)
                        ColorArr = colorArr.children.length != 0 ? [...colorArr.children] : [colorArr];
                    }
                    if (textureArr) {
                        this.handleModelChineseName(textureArr, partItem.textureArr, 1, name)
                        TextureArr = textureArr.children.length != 0 ? [...textureArr.children] : [textureArr];

                    }


                })
                modelTextureArr = modelTextureArr.concat(TextureArr);
                modelColorArr = modelColorArr.concat(ColorArr);

                partArr.push(partInfo)

            })
        }
        this.canEditPosArr = modelTextureArr;   

        this.canEditColorArr = modelColorArr;
        console.log(this.canEditPosArr, 'canEditPosArrcanEditPosArr')
        return new Promise((res, rej) => {
            //制作贴图区域存储数据对象
            this.canEditPosArr.map(item => {
                let obj = {
                    name: item.userData.cName ? item.userData.cName : item.name,
                    modelName: item.name,
                    editData: '',
                }

                this.editPosInfo.push(obj);
            })


            modelColorArr.map(item => {
                let obj = {
                    name: item.userData.cName,
                    modelName: item.name,
                    editData: '',
                }

                let index = infoarr.findIndex(ite => {
                    return ite.name == item.cName;
                })

                if (index != -1) {
                    obj.materialInfo = infoarr[index].materialInfo;
                }

                this.editMaterialInfo.push(obj);
            })

            modelTextureArr.map(item => {
                if (item.material && item.material.length != 0) {
                    item.material[0].transparent = true;
                    item.material[0].needsUpdate = true;
                }
            })

            if (modelTextureArr.length != 0) {
                this.posArr = modelColorArr;
                let obj = {
                    colorArr: modelColorArr,
                    TextureArr: this.canEditPosArr,
                }
                if (partArr.length > 0) {
                    partArr.map(item => {
                        if (item.partArr.length > 1) {
                            this.partList.push(item);
                        }
                    })
                    obj.partArr = partArr
                }

                res(obj)
            }

            let box = new AMRT.Box3();
            // box = box.setFromObject(model);
            let center = box.setFromObject(model).getCenter();
            // const w = Math.abs(box.min.x - box.max.x);
            // const h = Math.abs(box.min.y - box.max.y);
            // if (w > h) {

            //     this.viewer.camera.position.z = center.z + 2*w + w / 2;
            // } else {
            //     this.viewer.camera.position.z = center.z + 2*h + h / 2;
            // }
            this.viewer.camera.position.z = this.viewer.camera.position.z;
            this.viewer.camera.position.y = center.y;


        })
    }

    //贴图的canvas大小
    editImgCanvas(model, type = 1) {
        let size = this.computedUvSize(model);

        this.sizeX = size.x;
        this.sizeY = size.y;

        this.updateModelTexture(model, type, size.type);
    }

    //处理编辑模型
    createEidtModel(model) {
        // const modelInfo = this.viewer.modelGroup.getObjectByName('RootNode').children[0];
        // // lc单独处理麦排
        // if (modelInfo && modelInfo.name !== 'maipai') {
        //     model._renderOrder = -2000;
        // }
        // if (modelInfo && modelInfo.name === 'fangshaipifuyi') {
        //     model._renderOrder = 2000;
        // }

        // // this.meshDraw.setCurrentMesh(model);

        // let targetModel = model.clone();
        // if (!targetModel.material) return

        // model._visible = false;
        // targetModel.name = `${model.name}_Clone`;
        // targetModel.material = [targetModel.material[0].clone()];
        // model.parent.add(targetModel);

        // targetModel.material[0].emissiveMap = null;
    }

    //将编辑贴图更新到模型上
    updateModelTexture(model, type, uvType) {
        setTimeout(() => {
            // let material = model.material[0].clone();

            // let index = model.parent.children.findIndex((item, index) => {
            //     return item.name.indexOf(`${model.name}_Clone`) != -1;
            // })


            // if (index == -1) {
            //     let parent = model.parent.children;
            //     let index = parent.findIndex(item => {
            //         return item.name == 'ColorIndex'
            //     })
            //     if (index != -1) {
            //         this.createEidtModel(model)
            //     } else {
            //         model.parent.children.map(ite => {
            //             this.createEidtModel(ite)
            //         })
            //     }

            //     let targetModel = model.parent.getObjectByName(`${model.name}_Clone`);
            //     this.editModel = targetModel;
            // } else {
            //     this.editModel = model.parent.children[index];
            //     model.material = [material]
            // }

            let modelInfo = this.viewer.modelGroup.getObjectByName('RootNode').children[0];
            let info = this.editInfo;
            if (info.editCanvas) {
                let width = this.sizeX;
                let height = this.sizeY;
                if (uvType == 1) {
                    width = info.maxWidth * this.sizeX;
                    height = info.maxWidth * this.sizeY;

                    if (height > info.maxHeight) {
                        let size = height / info.maxHeight;
                        width = width / size;
                        height = info.maxHeight;
                    }

                    
                    // if (modelInfo && (modelInfo.name === 'guasheng' || modelInfo.name === 'dakouguasheng')) {
                    //     if (width > info.maxWidth) {
                    //         let size = height / info.maxWidth;
                    //         width = width / size;
                    //         height = info.maxWidth;
                    //     }
                    // }
                }

                if (this.sizeX == this.sizeY) {
                    // if (modelInfo && (modelInfo.name === 'guasheng' || modelInfo.name === 'dakouguasheng')) {

                    //     width = height = info.maxWidth;
                    // } else {
                        width = height = info.maxHeight;
                    // }
                }
                if (modelInfo && (modelInfo.name === 'guasheng' || modelInfo.name === 'dakouguasheng')) {
                    width = height = 500
                }
                if (modelInfo && (modelInfo.name === 'jiangpai' || modelInfo.name === 'yuanlingweiyi')) {
                    if (width < height) {

                        width = height;
                    }
                }
                info.updateCanvasSize(width, height, model.userData.cName, uvType);

            } else {
                info.initEdit(this.sizeX, this.sizeY, uvType)
            }
            

            if (type != 2) {
                this.giveImgToEdit(this.editPosEditInfo, 0, type);
            }
            // setTimeout(() => {
            //     // this.canEditModel = true;
            //     this.updateImg()
            // }, 1000)
        }, 300)
    }

    //纹理更新
    async updateImg() {
        // if (!this.editModel || !this.canEditModel || this.editModel == '') return
        // if (this.editImg) {
        //     this.editImg.dispose()
        //     this.editImg = null;
        // }
        // // console.log("------------------",this.editModel.name);
        // let info = await this.editInfo.exportModelImg();
        // // console.log(info);
        // this.editMaterial = this.editModel.material[0];
        // new AMRT.TextureLoader().load(info, (info) => {
        //     this.editImg = info;
        //     //info.generateMipmaps = false;
        //     // info.rotation = (Math.PI / 180) * 30;
        //     // this.editMaterial.albedoOffset = new AMRT.Vector2(0.2,0.2);
        //     // this.editMaterial.albedoScale = new AMRT.Vector2(0.05,0.05);
        //     this.editMaterial.albedo = info;
        //     this.editMaterial.needsUpdate = true;
        //     this.editModel.visible = true;
        // });
    }

    //切换编辑模型材质颜色 物件
    changeEditColor(obj) {
        this.editColorModel.material[0].color = new AMRT.Color(obj.color);
        this.editColorModel.material[0].needsUpdate = true;

        let index = this.editMaterialInfo.findIndex((item => {
            return item.name == this.editColorModel.userData.cName;
        }))
        if (index != -1) {
            this.editMaterialInfo[index].color = obj.color;
            this.editMaterialInfo[index].materialId = obj.id;
        }
    }

    //截取场景
    shotScene() {
        return this.viewer.screenShot();
    }

    //删除当前模型
    deleteModel() {
        let model = this.viewer.modelGroup.children;
        if (model.length != 0) {
            this.viewer.remove(model[0]);
        }
    }

    //切换颜色编辑区域 获取到当前可以编辑贴图的区域
    changeEditViewer(name) {
        let model = this.posArr.find(item => {
            return item.userData.cName == name
        })
        let textureArr = [];
        if (model) {
            this.editColorModel = model;
            //拿到当前选择的部件中 可以选择logo区域
            let textureIndex = this.viewer.modelGroup.getObjectByName('TextureIndex');
            textureIndex.children.map(item => {
                if (item.name && item.name.indexOf(model.name) != -1) {
                    textureArr.push(item);
                }
            })
        }

        return textureArr;
    }

    //将图片赋值给编辑区域
    giveImgToEdit(list, ind = 0, arr) {
        console.log("list--------------",list)
        let _this = this;
        let info = list[ind];
        // if (!info) {
            this.giveModelNewMaterial();
            if (this.craftInfo && this.craftInfo.info) {

                // this.craftToModel(this.craftInfo.info, this.craftInfo.id)

            }
            setTimeout(() => {
                this.updateImg();
            }, 600)
            // return
        // } else {
            if (info) {
                let index = this.editPosInfo.findIndex((item => {
                    return item.name == info.name
                }))
        
                this.editPosInfo[index].color = info.color;
            }


        // }
        // lc 最后改变工艺下标
        if (ind > list.length - 1) {
            setTimeout(() => {
                let event = document.createEvent('HTMLEvents');
                event.initEvent('changeCraft', false, false);
                document.body.dispatchEvent(event)

            }, 500)
            return;
        }
        
        // loadModelAlbeo(info).then(res => {
        //     _this.giveImgToEdit(list, ind + 1, arr)
        // })
        

        // function loadModelAlbeo(info) {
        //     return new Promise((res, rej) => {
        //         if (!info) return res();
        //         if (!_this.editModel) return
        //         let Modelinfo = _this.editModel.parent.children.find(ite => {
        //             if (ite.name.indexOf('Clone') != -1) {
        //                 return ite.userData.cName == info.name || ite.name.split('_Clone')[0] == info.name
        //             }
        //         })
        //         if (!_this.editModel.material || !Modelinfo) return
        //         let material = Modelinfo.material[0].clone();

        //         if (info.url == '') {
        //             res();
        //         } else {
        //             new AMRT.TextureLoader().load(info.url, (data) => {
        //                 data.name = info.name;
        //                 if (!Modelinfo) return
        //                 material.albedo = data;
        //                 Modelinfo.material = [material];
        //                 Modelinfo.material[0].alphaTest = info.alphaTest;
        //                 Modelinfo.material[0].needsUpdate = true;
        //                 Modelinfo.visible = true;// lc 显示当前贴图面
        //                 res();
        //             })
        //         }
        //     })
        // }
    }


    //拿到模型编辑区域的信息
    getEditPosInfo() {
        let Colorarr = [];
        let TextureArr = [];
        let RootNode = this.viewer.modelGroup.getObjectByName("RootNode");
        let colorIndex = RootNode.getObjectByName('ColorIndex');
        let textureIndex = this.canEditPosArr;
        this.editMaterialInfo.map(item => {
            let info = colorIndex.children.length == 0 ? colorIndex.parent : colorIndex;
            let model = info.getObjectByName(`${item.modelName}`);
            let obj = {
                name: `${this.modelId}_${item.modelName}`,
                imgUrl: model.material[0].albedo ? model.material[0].albedo.image.src : null,
                color: `#${model.material[0].color.getHexString()}`,
                //材质的id
                id: item.materialId || '',
                cName: item.name
            }
            Colorarr.push(obj);
        })
        textureIndex.map((item, index) => {
            let model;
            if (item.name.indexOf('Clone') != -1) {
                model = item;
            } else {
                model = item.parent.getObjectByName(`${item.name}_Clone`);
            }

            if (model) {
                let obj = {
                    name: `${this.modelId}_T_${item.name}`,
                    imgUrl: model.material[0].albedo ? model.material[0].albedo.image.currentSrc : '',
                }
                TextureArr.push(obj)
            }
        })
        return {
            colorIndex: Colorarr,
            textureIndex: TextureArr
        };
    }

    //拿到并重组编辑过的数据
    recomPositionInfo(name = this.editModel.userData.cName) {
        // let index = this.canEditPosArr.findIndex(item => {
        //     return item.name == name.split('_Clone')[0];
        // })
        //this.editCanvas._objects

        // if (index != -1) {
        //     this.editPosInfo[index].editData = this.editInfo.SaveToJson();
        // }
        //this.editPosInfo[index].editData = this.editInfo.SaveToJson();
        return this.editPosInfo
    }


    //开启预览
    openPreview() {
        this.openPrev = !this.openPrev;
    }

    //触摸事件
    modelClickFun(e) {
        if (this.canEditPosArr) {
            if (Math.abs(e.clientX - this.clientX) >= 3 || Math.abs(e.clientY - this.clientY) >= 3) {
                this.clientX = e.clientX;
                this.clientY = e.clientY;

                // console.log(this.canEditPosArr, 'this.canEditPosArrthis.canEditPosArr')

                let parent = this.canEditPosArr[0].parent;
                let arr = [];
                this.canEditPosArr.map((item) => {
                    let model = parent.getObjectByName(`${item.name}_Clone`)
                    if (model) {
                        arr.push(model)
                    }
                })
                if (arr.length == 0) {
                    return
                }
                let object = this.viewer.picker.intersectObject(e, arr)
                if (object) {
                    const v2 = this.viewer.picker.vectorToScreen(object.point)
                    return {
                        model: object.object,
                        position: v2,
                    }
                } else {
                    return false;
                }
            }
        }
    }

    //计算画布大小
    computedUvSize(model) {
        let materialInfo = {
            x: 0,
            y: 0
        }
        //有贴图拿贴图的尺寸 无贴图则默认uv尺寸
        if (model.material[0].albedo && model.material[0].albedo.image) {
            let images = model.material[0].albedo.image;
            let x = images.width;
            let y = images.height;

            let maxWidth = this.editInfo.maxWidth;
            let maxHeight = this.editInfo.maxHeight;

            let xScale = Number((maxWidth / x).toFixed(2));
            let yScale = Number((maxHeight / y).toFixed(2));


            let scale = Math.min(xScale, yScale);

            materialInfo.x = scale * x;
            materialInfo.y = scale * y;
            materialInfo.type = 2;

        } else {
            let uvInfo = model.geometry.attributes.uv.array
            let xArr = [];
            let yArr = [];

            uvInfo.map((item, index) => {
                if (index % 2 == 0) {
                    xArr.push(item);
                } else {
                    yArr.push(item);
                }
            })

            yArr.sort((a, b) => {
                return a - b;
            })

            let xMin = Math.min(...xArr)
            let yMin = Math.min(...yArr)

            let xMax = Math.max(...xArr)
            let yMax = Math.max(...yArr)

            materialInfo.x = Number((xMax - xMin).toFixed(3));
            materialInfo.y = Number((yMax - yMin).toFixed(3));
            materialInfo.type = 1;
        }



        return materialInfo
    }


    //加载新材质
    loadNewMaterial(data, id, model = this.editColorModel, color) {
        let index = this.editMaterialInfo.findIndex(item => {
            return item.name == model.userData.cName;
        })
        this.editMaterialInfo[index].materialInfo = data;
        this.editMaterialInfo[index].materialId = id;
        this.editMaterialInfo[index].color = color;

        if (!data.a.length) {
            data.a = [data.a]
        }
        // for (let key in data.z) {
        //     data.z[key] = `${data.z[key]}?t=${new Date().getTime()}`
        // }


        this.viewer.modelManager.loadSingleMaterial(data, {
            //加载完成
            onLoad: mats => {
                console.log('====================新材质加载完成')

                
                    let colorIndex = this.loadModelArr[0].getObjectByName('ColorIndex');
                    colorIndex = colorIndex.children.length ? colorIndex.children : [colorIndex];
                    const envMap = colorIndex[0].material[0].envMap;
                    // console.log(envMap.clone(), '====================envMap')
                    // const envMapIntensity = colorIndex[0].material[0].envMapIntensity;
                    // const emissive = colorIndex[0].material[0].emissive;

                    // let Oricolor = model.material[0].color;
                    // model.material = [model.material[0].clone()];
                    // console.log(model.name, 'model.namemodel.name')
                    // console.log(mats[0].color.getHexString(), 'mats[0].color.getHexString()mats[0].color.getHexString()')
                    // console.log(color, 'colorcolor')
                    if (color) {
                        mats[0].color = new AMRT.Color(color);
                    } else {
                        // mats[0].color = new AMRT.Color(0xffffff);
                    }
                    

                    model.material = [mats[0].clone()];
                    
                    // model.material[0].envMap = envMap;
                    // model.material[0].envMapIntensity = envMapIntensity;
                    // model.material[0].emissive = emissive;

                    // model.material[0].needsUpdate = true;
                    this.getEnvMap(model, colorIndex, envMap);


                    if(this.modelMap.currentMesh != null){
                        this.modelMap.currentMesh.getTextEnvMap();
                    }
            }
        })
    }

    getEnvMap(model, colorIndex, envMap) {
     
        if (!envMap) {
            // 没有环境贴图时，反复获取
            
            envMap = colorIndex[0].material[0].envMap;
            
            setTimeout(() => {
                this.getEnvMap(model, colorIndex, envMap);
            })
            return;
            
        }
        console.log(envMap.clone(), '====================envMap222')
        const envMapIntensity = colorIndex[0].material[0].envMapIntensity;
        const emissive = colorIndex[0].material[0].emissive;

        model.material[0].envMap = envMap;
        model.material[0].envMapIntensity = envMapIntensity;
        model.material[0].emissive = emissive;

        model.material[0].needsUpdate = true;
    }


    //给与模型新的材质
    giveModelNewMaterial(arr = this.modelMaterlInfo) {
        console.log("加载材质数据",arr);
        arr.map((item, index) => {

            let model = this.canEditColorArr.find(ite => {
                return ite.name == item.modelName;
            })
            if (item.materialInfo) {

                // let model = this.canEditColorArr.find(ite => {
                //     return ite.name == item.modelName;
                // })
                // model.material[0].color = new AMRT.Color(0xffffff)
                if (model) {
                    this.loadNewMaterial(item.materialInfo, item.materialId, model, item.color)
                    setTimeout(() => {
                        var myEvent = new CustomEvent('loadTextMat', {
                            // objParams就是需要传递的参数，
                            // 可以是任意的类型
                            detail: {materialId: item.materialId, index}
                        });
                        document.body.dispatchEvent(myEvent);
                    }, 1000)
                    
                }
            } else {
                //lc 针对多个材质区域时，默认第一个材质，选择颜色不显示时
                model.material[0].color = new AMRT.Color(item.color);
            }
        })
    }


    //确定编辑贴图的区域
    selectTextureIndex(name, type = 2) {
        console.log("name",name)
        // if(!this.editModel)return
        this.canEditModel = false;
        let model = this.canEditPosArr.find(item => {
            return item.userData.cName == name || item.name == name;
        })
        if (model) {
            this.saveLastData();
            this.modelMap.setCurrentMesh(model.name);
            this.curEditPos = model;
            // if (this.editModel) {
            //     this.recomPositionInfo(this.editModel.name);
            // }
            // let editModel = model.parent.getObjectByName(`${model.name}_Clone`);
            // if (editModel) {
            //     this.editModel = editModel.clone();
            // }
            this.editImgCanvas(model, 2)
            if (type == 2) {
                this.editInfo.initialStats();
                this.changeTextureViewer(name)
            }
            
        }

    }

    saveLastData(){
        if(this.modelMap.currentMesh == null){
            return;
        }
        let index = this.editPosInfo.findIndex((item => {
            return item.modelName == this.modelMap.currentMesh.modelName
        }))
        console.log(this.modelMap.currentMesh.modelName,index,this.editPosInfo)
        this.editPosInfo[index].editData = this.editInfo.SaveToJson();
    }

    //获取存储的数据
    getSaveInfo() {
        // if (this.editModel) {
        //     this.recomPositionInfo(this.editModel.name);
        // }
        this.saveLastData();
        let partInfo = [];
        this.partList.map(item => {
            let model = item.partArr[item.selectIndex];
            let info = {
                name: model.name,
                partName: item.partName,
                partInfoName: model.userData.partName,
            }
            partInfo.push(info)
        })
        // lc 没有材质信息时，默认把第一个材质放进来
        this.editMaterialInfo.forEach(item => {
            if (!item.materialId && this.oneMatInfo[item.name]) {
                const {materialInfo, materialId} = this.oneMatInfo[item.name];
                item.materialInfo = materialInfo;
                item.materialId = materialId;
            }
            if (!item.color) {
                item.color = '#' + this.viewer.modelGroup.getObjectByName('ColorIndex').getObjectByName(item.modelName).material[0].color.getHexString();
            }
        })
        // lc编辑区域没有图片和文字时，清除多余的信息
        this.editPosInfo.forEach(f => {
            if (f.editData) {
                const editData = JSON.parse(f.editData);
                if (editData.every(e => e.type === 'size')) {
                    f.editData = '';
                }
            }
        })
        // lc放入镂空强度值
        // this.editPosInfo.forEach(f => {
        //     const m = this.viewer.modelGroup.getObjectByName(f.modelName + '_Clone');
        //     f.alphaTest = m.material[0].alphaTest;
        // })
        let obj = {
            ColorIndex: JSON.stringify(this.editMaterialInfo),
            TextureIndex: JSON.stringify(this.editPosInfo),
            partList: JSON.stringify(partInfo),
            craftInfo: JSON.stringify(this.craftInfo),
        }

        return obj;
    }

    //停止下载图片
    changeUploadImg(type = 1) {
        let bol = type == 1 ? true : false;
        this.editInfo.canAddInfo = bol;
        this.canEditModel = bol;
    }

    //初始化当前模型
    initializeNowModel(type) {
        let ModelArr = this.canEditColorArr;
        let colorIndex = this.loadModelArr[0].getObjectByName('ColorIndex');
        if (colorIndex) {
            // lc编辑时
            if ((store.state.share.selectPlanIndex > -1 && store.state.cases.isSaveCase) || vue.$route.query.cartId) {
                let oldModelInfo = colorIndex.children.length == 0 ? [colorIndex] : colorIndex.children;
                ModelArr.map(item => {
                    let model = oldModelInfo.find(ite => {
                        return item.name == ite.name;
                    })
                    if (model && model.material) {
                        item.material[0].copy(model.material[0].clone())
                    }
    
                })
                console.log('编辑时')
            } else {
                console.log('未保存时')
                // lc未保存时
                setTimeout(() => {
                    let event = document.createEvent('HTMLEvents');
                    event.initEvent('changeCraft', false, false);
                    document.body.dispatchEvent(event)

                }, 500)

                // setTimeout(() => {
                //     var myEvent = new CustomEvent('changeMat', {
                //         // objParams就是需要传递的参数，
                //         // 可以是任意的类型
                //         detail: type
                //     });
                //     document.body.dispatchEvent(myEvent);

                // }, 500)
            }
        } else {
            console.log('最开始时工艺')
            // lc没有材质区域时，未保存时
            setTimeout(() => {
                let event = document.createEvent('HTMLEvents');
                event.initEvent('changeCraft', false, false);
                document.body.dispatchEvent(event)

            }, 500)
        }

        let textArr = this.viewer.modelGroup.getObjectByName('TextureIndex');
        let textArrClone = this.viewer.modelGroup.getObjectByName('TextureIndex_Clone');
        // lc隐藏模型本身贴图
        if (!type) {
            if (textArr.children.length) {

                textArr.children.forEach(t => t.visible = false);
            } else {
                textArr.visible = false;
                if (textArrClone) textArrClone.visible = false;
            }
        }
       
        if (type == 2) {
            this.canEditModel = false;

            this.editInfo.initialStats();

            //处理材质
            this.editMaterialInfo.map(item => {
                item.materialInfo = null;
                item.materialId = null;
            })

            let TextureIndex = this.loadModelArr[0].getObjectByName('TextureIndex');

            let oldTextureInfo = TextureIndex.children.length == 0 ? [TextureIndex] : TextureIndex.children;
            let textureParent = this.canEditPosArr[0].parent;
            //处理贴图
            setTimeout(() => {
                this.canEditPosArr.map(item => {
                    let model = textureParent.children.find(ite => {
                        return ite.name.split('_Clone')[0] == item.name && ite.name.indexOf('_Clone') != -1
                    })

                    let model2 = oldTextureInfo.find(ite => {
                        return item.name == ite.name;
                    })

                    if (model && model2) {
                        model.material = [model2.material[0].clone()];
                        model.material[0].emissiveMap = null;
                        model.material[0].needsUpdate = true;
                        // model._renderOrder = -2000 ; 
                    }
                })
                // lc隐藏模型本身贴图
                if (textArr.children.length) {
        
                    textArr.children.forEach(t => t.visible = false);
                } else {
                    textArr.visible = false;
                    if (textArrClone) textArrClone.visible = false;
                }
            }, 800)


            //处理编辑数据
            this.editPosInfo.map(item => {
                item.editData = null;
            })

        }

        let time = 3000;
        if (type === 2) time = 500; // lc初始化模型时
        setTimeout(() => {
            
            var myEvent = new CustomEvent('changeColor', {
                // objParams就是需要传递的参数，
                // 可以是任意的类型
                // detail: type
            });
            document.body.dispatchEvent(myEvent);
        }, time)

        setTimeout(() => {
            Bus.$emit("closeLoading2", false);
        }, 3000)
    }

    //在当前部件组内选择部件
    changePartArr(arr, ind) {
        if (!arr) return
        arr.partArr.map((item, index) => {
            if (ind == index) {
                item._visible = true;
            } else {
                item._visible = false;
            }
        })
    }

    //切换的时候加载数据
    changeTextureViewer(name) {
        let info = this.editPosInfo.find(item => {
            return item.name == name;
        })
        if (info) {
            //if (info.editData == '') return

            //let list = JSON.parse(info.editData);
            //this.editInfo.loadModelAera(info,true);
            this.editInfo.changeTexByMeshName(info);
            this.modelMap.setCurrentMesh(info.modelName);
            //this.editInfo.loadJsonFun(list)
        }
    }

    //控制显示隐藏部件
    controlPartShow(list) {
        let Partinfo = {}
        list.map((item, index) => {
            let info = this.partList.find(ite => {
                return item.partName = ite.partName
            })

            if (info) {
                let ind = info.partArr.findIndex(ite => {
                    return ite.name == item.name
                })
                Partinfo.partInfo = info.partArr[index];
                Partinfo.ind1 = ind;
                Partinfo.ind2 = index;

            }
        })

        this.changePartArr(this.partList[Partinfo.ind2], Partinfo.ind1)
    }

    //恢复默认材质
    returnDefaultMaterial() {
        let staticModel = this.loadModelArr[0].getObjectByName('ColorIndex');

        let targetModel = staticModel;
        if (staticModel.children.length != 0) {
            targetModel = staticModel.getObjectByName(this.editColorModel.name);
        }

        if (targetModel.name == this.editColorModel.name) {
            this.editColorModel.material = targetModel.material;
            let index = this.editMaterialInfo.findIndex(item => {
                return item.name == this.editColorModel.userData.cName;
            })
            this.editMaterialInfo[index].materialInfo = null;
            this.editMaterialInfo[index].materialId = null;
            this.editMaterialInfo[index].color = `#${this.editColorModel.material[0].color.getHexString()}`;

        }

    }

    //获取工艺
    craftToModel(data, id, textArr) {
        console.log(textArr, '获取新的贴图材质')
        if (!data.a.length) {
            data.a = [data.a]
        }

        this.craftInfo = {
            info: data,
            id,
        };

        this.viewer.modelManager.loadSingleMaterial(data, {
            //加载完成
            onLoad: mats => {
                let model = this.viewer.modelGroup.getObjectByName('RootNode');
                let goodsName = model.children[0].name;
                console.log('goodsName===========', goodsName)

                let text = this.loadModelArr[0].getObjectByName('TextureIndex');
                text = text.children.length ? text.children : [text];
                const envMap = text[0].material[0].envMap;

                if (goodsName == '27lushu') {
                    mats[0].opacity = 0.7;
                }
                for(let i = 0;i < textArr.length;i ++){
                    mats[0].transparent = true;
                    this.modelMap.setMaterial(textArr[i],mats[0]);
                }

                // const envMapIntensity = text[0].material[0].envMapIntensity;
                // const emissive = text[0].material[0].emissive;

                // model.traverse((item) => {
                //     if (item.isMesh) {
                //         //特殊处理这个模型
                //         if (goodsName == '10taika') {
                //             if (item.name.indexOf('AlbeoIndex') != -1 || item.parent.name == 'AlbeoIndex') return
                //         }

                //         // if (item.name != 'ColorIndex' && item.parent.name != 'ColorIndex' || item.name == 'TextureIndex_Clone' || item.parent.name == 'TextureIndex') {
                //         if (item.name == 'TextureIndex_Clone' || (item.parent.name == 'TextureIndex' && textArr.some(s => (s + '_Clone') == item.name))) {

                //             let oldMaterial = item.material[0].clone();
                //             let material = mats[0].clone();
                //             let texture;

                //             if (item.material[0].albedo) {
                //                 texture = oldMaterial.albedo;
                //             }

                //             item.material = [material]
                //             item.material[0].albedo = texture || null;
                //             if (item.name.indexOf('Texture') != -1 || item.parent.name.indexOf('Texture') != -1) {
                //                 // item.material[0].color = new AMRT.Color(0xffffff);
                //             }
                //             item.material[0].transparent = true;
                //             //特殊处理路书
                //             if (goodsName == '27lushu') {
                //                 item.material[0].opacity = 0.7;
                //             }

                //             // item.material[0].envMap = envMap;
                //             // item.material[0].envMapIntensity = envMapIntensity;
                //             // item.material[0].emissive = emissive;
                //             // item.material[0].needsUpdate = true;
                //             this.getTextEnvMap(text, item, envMap);
                //         }

                //     }
                // })

            }
        })

        let event = document.createEvent('HTMLEvents');
        event.initEvent('changeCraft', false, false);
        document.body.dispatchEvent(event)
    }

    getTextEnvMap(text, item, envMap) {
        if (!envMap) {
            // 没有环境贴图时，反复获取
            const envMap = text[0].material[0].envMap;
            setTimeout(() => {
                this.getTextEnvMap(text, item, envMap);
            })
            return;
        }
        const envMapIntensity = text[0].material[0].envMapIntensity;
        const emissive = text[0].material[0].emissive;

        item.material[0].envMap = envMap;
        item.material[0].envMapIntensity = envMapIntensity;
        item.material[0].emissive = emissive;
        item.material[0].needsUpdate = true;
    }

    //返回默认材质
    returnDefaultCarftMateriial(id) {
        let oldModelArr = [];
        let nowModelArr = [];

        oldModelArr = this.returnModelArr(this.loadModelArr[0].getObjectByName('RootNode'));
        nowModelArr = this.returnModelArr(this.viewer.modelGroup.getObjectByName('RootNode'));

        this.craftInfo.info = null;
        this.craftInfo.id = id;
        nowModelArr.map(item => {
            let index = oldModelArr.findIndex(ite => {
                return item.name == ite.name && item.parent.name == ite.parent.name || ite.name == item.name.split('_Clone')[0] && ite.parent.name == ite.parent.name;
            })
            if (index != -1) {
                let oldMaterial = item.material[0].clone();
                let material = oldModelArr[index].material[0].clone();
                let texture;

                if (item.material[0].albedo) {
                    texture = oldMaterial.albedo;
                }


                item.material = [material];
                item.material[0].albedo = texture;
                item.material[0].transparent = true;

                if (item.name.indexOf('Texture') != -1 || item.parent.name.indexOf('Texture') != -1) {
                    item.material[0].color.copy(material.color)
                    item.material[0].transparency = 0;
                }
                item.material[0].needsUpdate = true;
            }
        })
    }

    //返回内部所有网格
    returnModelArr(obj3D) {
        let arr = [];
        obj3D.traverse((obj) => {
            if (obj.isMesh) {
                //排除材质层级
                if (obj.name == 'ColorIndex' || obj.parent.name == 'ColorIndex') return
                if (obj.name) {
                    arr.push(obj);
                }
            }
        })
        return arr
    }

}