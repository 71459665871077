import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const cartCase = {
    //添加购物车方案
    addCartCase(params) {
        return axios.post(`${base.host}/model/cartCase`,params )
    },

    //查询购物车方案
    getCartCase(id) {
        return axios.get(`${base.host}/model/cartCase/${id}`, )
    },

    //修改购物车方案
    reviseCartCase(params){
        return axios.put(`${base.host}/model/cartCase`,params )
    }
    


}
export default cartCase