<template>
  <div class="home">
    <template v-if="showEdit">
      <Head></Head>
      <Content></Content>
    </template>
    <Middleware @complete="handleComplete" v-else></Middleware>
  </div>
</template>

<script>
import Head from "@/components/common/head";
import Content from "@/components/3dContent";
import Middleware from "@/components/Middleware"
export default {
  components: {
    Head,
    Content,
    Middleware
  },
  data() {
    return {
      showEdit: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleComplete(){
      this.showEdit = true
    }
  },
  created() {
    let params = this.$route.query;
    // let ticket = params.ticket;
    // let lzyProductCode = params.lzyProductCode || "1459366450023239680";
    // // let lzyProductCode = params.lzyProductCode || '1457967867407503360';
    //
    // let planId = params.planId;
    //this.$store.commit('SET_USER_INFO', params)
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
.home{
  height: 100vh;
  user-select: none;
}
</style>