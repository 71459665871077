<template>
  <div class="Upload" style="width:100% ;height:100%;">
    <el-upload
      :action="uploadImgUrl"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      :headers="headers"
      accept=".jpg,.jpeg,.png,.JPG,.JPEG"
      style="width:100% ; height:100%"
    ></el-upload>
  </div>
</template>

<script>
import base from '@/api/base'; // 导入接口域名列表

export default {
  components: {},
  data() {
    return {
        uploadImgUrl: base.host + "/uploadFile", // 上传的图片服务器地址
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleUploadSuccess(res) {
      this.$emit("uploadImgInfo", res.url);
      this.loading.close();
    },
    handleBeforeUpload() {
      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败"
      });
      this.loading.close();
    }
  }
};
</script>
<style lang='scss' scoped>
/deep/.el-upload{
  border: none !important;
}
.Upload {
  position: absolute;
}
</style>

