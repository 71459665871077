function drawUV( geometry, width, height, modelName, meshName ) {
  let num = 1;
  if (modelName === 'guangshankapian' && meshName === '03') num = 2; // 单独处理光栅卡片
  const uvMap = {}, edgeMap = {};
  const uv = [], edges = [], triangles = [];

  const uvArray = geometry.attributes.uv.array;
  let index;
  if (geometry.custom_data.index) {

      index = geometry.custom_data.index.array;
  } else {
    index = geometry.index.array;
  }

//   console.log( uvArray );

  let min = Infinity, max = -Infinity;
  uvArray.forEach( u => {
      if ( u < min )
          min = u;
  } );
//   console.log( 'min:' + min );

  if ( min < 0 ) {
      uvArray.forEach( ( u, i ) => {
          uvArray[ i ] += Math.abs( min );
      } );
  }

  uvArray.forEach( ( u, i ) => {
      if ( u > max )
          max = u;
  } );

//   console.log( 'max:' + max );
  if ( max > 1 ) {
      uvArray.forEach( ( u, i ) => {
          uvArray[ i ] /= max;
      } );
  }
  // if ( max !== -Infinity && max < 1 ) {
  //     uvArray.forEach( ( u, i ) => {
  //         uvArray[ i ] *= ( 1 - max );
  //     } );
  // }

//   console.log( uvArray );

  for ( let i = 0, length = uvArray.length / 2; i < length; i++ ) {

      let v2 = new AMRT.Vector2( uvArray[ i * 2 ], uvArray[ i * 2 + 1 ] );
      const key = v2.x + '' + v2.y, u = uvMap[ key ];

      if ( u ) {
          v2 = u;
      } else {
          uvMap[ key ] = v2;
      }
      uv.push( v2 );
  }

  function addEdge( p1, p2 ) {

      let key1 = p1.x + '' + p1.y + '' + p2.x + '' + p2.y;
      let key2 = p2.x + '' + p2.y + '' + p1.x + '' + p1.y;

      let key = key1;
      let edge = edgeMap[ key1 ];

      if ( !edge ) {
          edge = edgeMap[ key2 ];
          key = edge ? key2 : key1;
      }

      if ( edge ) {
          edge.use++;
      } else {

          edge = [ p1, p2 ];
          edge.use = 1;
          edges.push( edge );
          edgeMap[ key ] = edge;
      }

      return edge;
  }

  for ( let i = 0, length = index.length / 3; i < length; i++ ) {

      const ii = i * 3;

      let ui = index[ ii ];
      const p1 = uv[ ui ];

      ui = index[ ii + 1 ];
      const p2 = uv[ ui ];

      ui = index[ ii + 2 ];
      const p3 = uv[ ui ];


      let edge1 = addEdge( p1, p2 );
      let edge2 = addEdge( p2, p3 );
      let edge3 = addEdge( p3, p1 );

      triangles.push( [ edge1, edge2, edge3 ] )

  }
  const newEdges = [];
  edges.forEach( e => {
      if ( e.use === num ) {
          newEdges.push( e );
      }
  } );

  const canvas = document.createElement( 'canvas' );
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext( '2d' );

  for ( let i = 0; i < newEdges.length; i++ ) {
      const edge = newEdges[ i ];


      const x1 = edge[ 0 ].x * width, y1 = ( 1 - edge[ 0 ].y ) * height,
          x2 = edge[ 1 ].x * width, y2 = ( 1 - edge[ 1 ].y ) * height;

    //   console.log( x1, y1, x2, y2 )
      ctx.beginPath();
      ctx.moveTo( x1, y1 );
      ctx.lineTo( x2, y2 );
      ctx.strokeStyle = '#0099FF';
      // ctx.strokeStyle = '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
      ctx.stroke();
  }

  return canvas;

}


export { drawUV }
