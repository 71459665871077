import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
const text2PathBaseUrl = "https://shuyang.laozicloud.com/text2path/";
const system = {
    getSystemList(id){
        return axios.get(`${base.host}/system/material/list?createUserId=${id}`) ; 
    },

    //上传图片资源
    uploadSystemInfo(param){
        return axios.post(`${base.host}/system/material`,param)
    } , 

    //删除自定义素材
    deleteImgInfo(id){
        return axios.delete(`${base.host}/system/material/${id}`) ; 
    },

   
    getTextPath(param){
        return axios.post(`${text2PathBaseUrl}Text2Path.ashx`, qs.stringify(param))
    },
    getFontList(){
        return axios.get(`${base.host}/model/font/list?status=0`) ;
    } , 

    uploadBase64File(param){
        return axios.post(`${base.host}/uploadBase64File`, qs.stringify(param))
    },
    uploadFile2(param){
        return axios.post(`${base.host}/uploadFile`, param)
    }

}
export default system