<template>
  <div class="toastBg">
    <div class="infoBox" :style="`transform:scale(${showToastInfo ? 1 : .01})`">
      <div
        class="closeBox"
        @click="closeBox"
        :style="`background:url(${require(`@/assets/home/close.png`)}) no-repeat 50% 50% / 100%;`"
      ></div>

      <div class="title">分享</div>

      <div class="input">
        <input type="text" v-model="shareTitle" />
      </div>

      <div class="url">
        链接地址:
        {{shareUrl}}
      </div>

      <div class="bottomBut" :data-clipboard-text="shareText()" @click="copyUrl">复制并分享</div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {
    showToast: {
      type: Boolean,
      default: false
    },
    planInfo: {
      type: Object
    }
  },
  computed: {
    userName(){
      return this.$store.state.user.userName
    },
    modelName(){
      return this.$store.state.user.modelName
    }, 
  },
  data() {
    return {
      showToastInfo: false,
      shareTitle: `${this.$store.state.user.userName}设计了好看的${this.$store.state.user.modelName},大家快来看看吧！`, //分享标题
      shareUrl: "https://www.laozicloud.com/workbench/myWorks" //分享链接
    };
  },
  watch: {
    shareTitle(newVal) {}
  },
  methods: {
    //关闭弹窗
    closeBox() {
      this.showToastInfo = false;
      setTimeout(() => {
        this.$emit("closeToast");
      }, 400);
    },

    //分享文案
    shareText() {
      let text = `${this.shareTitle} 
      复制下方链接前去查看:
      ${this.shareUrl}
      `;
      return text;
    },

    //复制文本
    copyUrl() {
      let _this = this;
      if (this.shareTitle == "") {
        this.$message({
          type: "error",
          message: "请先填写分享语~"
        });
        return;
      }
      let clipboard = new Clipboard(".bottomBut");

      clipboard.on("success", e => {
        //  释放内存
        this.$message({
          type: "success",
          message: "复制成功~"
        });
        clipboard.listener.destroy();
        _this.closeBox();
      });
      clipboard.on("error", e => {
        // 不支持复制
        // 释放内存
        clipboard.listener.destroy();
      });
    }
  },
  created() {},
  mounted() {
    if (this.showToast) {
      setTimeout(() => {
        this.showToastInfo = true; 
      }, 100);
    }
    this.shareUrl = `https://canvas.shuyangtech.com?planId=${this.planInfo.id}&lzyProductCode=${this.planInfo.modelId}&userId=${this.$store.state.user.userId}`;
  }
};
</script>
<style lang='scss' scoped>
.toastBg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    box-sizing: border-box;
  }
  .infoBox {
    width: 489px;
    height: 280px;
    border-radius: 8px;
    background: #ffffff;
    padding: 19px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s;
    .closeBox {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 21px;
      right: 19px;
    }
    .title {
      font-size: 18px;
      color: #1a1a1a;
      opacity: 1;
      text-align: center;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .input {
      width: 420px;
      height: 34px;
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      padding: 9px 10px 10px 10px;
      > input {
        width: 100%;
      }
    }
    .url {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      margin-top: 10px;
      width: 100%;
       word-wrap: break-word;
      word-break: normal;
    }
    .bottomBut {
      width: 100%;
      height: 50px;
      border-top: 1px solid #e1e1e1;
      margin-top: 30px;
      text-align: center;
      line-height: 50px;
      color: #0099ff;
      cursor: pointer;
    }
  }
}
</style>