<template>
  <div class="info">
    <div class="list">
      <div
        class="box"
        v-for="(item, index) in infoType"
        :key="index"
        @click="changeShowSelect(index)"
        :style="`background :${selectIndex == index ? '#0099FF' : '#5A5E60'} `"
      >
        <div>{{ item }}</div>
      </div>

      <!-- <div
        class="swiper-container swiper-no-swiping"
        @mousemove="enterToEdit"
        @mouseout.stop="enterToEdit"
        :style="`height:${bodyHeight <= 780 ? '83vh' : '86vh'};max-height:${
          bodyHeight <= 780 ? '83vh' : '86vh'
        }`"
      > -->
      <div
        class="swiper-container swiper-no-swiping"
        @mousemove="enterToEdit"
        @mouseout.stop="enterToEdit"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in 4"
            :key="index"
            style="overflow-y: auto"
          >
            <!-- 刀版图盒 -->
            <div class="dbtBox" v-if="index == 1">
              <div class="imgBox flex-center" style="margin-bottom: 19px">
                <img id="dbImg" :src="`${knifeInfo.knifeLayout}`" />
              </div>

              <div class="button" @click="uploadImg">下载刀版图</div>
              <div class="textInfo">
                素材实际尺寸等于上传完后的初始尺寸，可以根据比例提示修改文件实际尺寸
              </div>
            </div>

            <!-- 材质颜色 -->
            <div
              class="materialBox"
              v-if="
                (index == 0 && modelMaterialList.length != 0)
              "
            >
              <div class="title">选择区域</div>
              <div
                class="selectPos"
                v-show="$parent.posNameArr.length != 0 && $parent.ticket"
              >
                选择材质区域:
                <div class="moreBox">
                  <el-select v-model="$parent.selectPos" placeholder="选择部件">
                    <el-option
                      v-for="item in $parent.posNameArr"
                      :key="item"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- 款式 -->
              <div class="ks" v-if="partList.length != 0">
                <div class="title" style="margin-top: 16px;">选择款式</div>
                <div
                  class="materialList"
                  v-for="(item, index) in partList"
                  :key="index"
                >
                  <div
                    class="infoBox"
                    v-for="(partItem, ind) in item.partArr"
                    :key="ind"
                    @click="changenowPart(item, ind, index)"
                  >
                    <div
                      :class="item.selectIndex == ind ? 'img selectBox' : 'img'"
                      :style="`background:url(${
                        partItem.userData.preview
                          ? partItem.userData.preview
                          : require(`@/assets/noimg.png`)
                      }) no-repeat 50% 50% / cover ;background-position: center; `"
                    ></div>
                    <div class="name" :title="partItem.userData.partName">
                      {{ partItem.userData.partName }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- 材质 -->
              <div v-if="modelMaterialList.length != 0" class="mat" style="padding-top: 20px;">
                <div class="title">
                  选择材质
                  <!-- <div class="returnOriginal" @click="reductionModel">初始化模型</div> -->
                </div>

                <!-- 材质列表-->
                <div id="matSwiper">
                  <div class="materialList swiper-wrapper">
                    <div
                      class="infoBox swiper-slide"
                      v-for="(item, index) in modelMaterialList"
                      :key="index"
                      @click="selectMaterialIndex = index"
                    >
                      <div v-if="item.name && item.name != ''">
                        <div
                          v-if="
                            item.preView && item.preView.indexOf('http') != -1
                          "
                          :class="
                            selectMaterialIndex == index
                              ? 'img selectBox'
                              : 'img'
                          "
                          :style="`background:url(${
                            item.preView
                              ? item.preView
                              : require(`@/assets/noimg.png`)
                          }) no-repeat 50% 50% / cover ;  background-position: center; `"
                        ></div>
                        <div
                          v-else
                          :class="
                            selectMaterialIndex == index
                              ? 'img selectBox'
                              : 'img'
                          "
                          :style="`background:rgb(${item.preView}); background-position: center; `"
                        ></div>
                        <div class="name" :title="item.name">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="swiper-button-next right-btn"
                    v-show="modelMaterialList.length > 5"
                  >
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div
                    class="swiper-button-prev left-btn"
                    v-show="modelMaterialList.length > 5"
                  >
                    <i class="el-icon-arrow-left"></i>
                  </div>
                </div>
              </div>

              <div
                v-show="
                  (colorType == 1 && modelMaterialList.length != 0) ||
                  (colorType == 2 && colorList.length != 0)
                "
              >
                <div class="title">
                  选择颜色
                  <span
                    class="search"
                    v-if="colorType == 1"
                    :style="`background:url(${require(`@/assets/home/search.png`)}) no-repeat 50% 50% / 100%`"
                  ></span>
                  <a
                    href="https://www.qtccolor.com/secaiku/"
                    target="_blank"
                    v-if="colorType == 1"
                    >潘通色号查询</a
                  >
                </div>
                <!-- 颜色选择 -->
                <div
                  class="colorPick"
                  v-if="colorType == 1 && selectMaterialIndex != -1"
                >
                  <div class="colorBox">{{ selectColor }}</div>
                  <el-color-picker v-model="selectColor"></el-color-picker>
                  <span @click="selectColor = '#ffffff'">清空</span>
                </div>
                <div
                  class="colorPick"
                  v-if="colorType == 2 && selectMaterialIndex != -1"
                >
                  <div
                    class="colorBox2"
                    v-for="(item, index) in colorList"
                    :key="index"
                    @click="changeEditColor(item.color, index)"
                    :style="`background:${item.color};border:${
                      index == selectColorIndex
                        ? '2px solid #0099ff'
                        : ''
                    }`"
                  >
                    <span :title="item.remark">{{item.remark}}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- lc 展示一个默认材质 -->
            <!-- <div v-if=" index == 0 && modelMaterialList.length == 0" class="default-mat">
              <div class="title">
                选择材质
              </div>
              <div class="mat-box">
                
              </div>
              <div class="name">
                默认材质
              </div>
            </div> -->


            <!-- 没有任何可编辑材质部位 -->
            <div
              class="materialBox flex-center"
              v-if="
                index == 0 &&
                modelMaterialList.length == 0
              "
              style="width: 100%; height: 100%; flex-wrap: wrap"
            >
              <div>
                <div
                  class="nothingBox"
                  :style="`width:180px;height:180px;background:url(${require(`@/assets/nothing.png`)}) no-repeat 50% 50% / 100% 100%`"
                ></div>
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    color: #999;
                    margin-left: -20px;
                  "
                >
                  暂无可编辑的材质
                </div>
              </div>
            </div>

            <!-- 自定义素材 -->
            <div class="customMaterial" v-if="index == 2">
              <div class="title">选择区域</div>
              <div
                class="selectPos"
                v-show="$parent.textureArr.length != 0 && $parent.ticket"
                style="top: 45px"
              >
                选择贴图区域:
                <div class="moreBox">
                  <el-select
                    v-model="$parent.selectTexturePos"
                    placeholder="选择贴图区域"
                  >
                    <!-- <el-option value="无" key="无">无</el-option> -->
                    <el-option
                      v-for="item in $parent.textureArr"
                      :key="item"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="title">
                {{ !isDelete ? "添加图片" : "删除图片" }}
                <span style="margin-left: 10px;">{{imgList.length}}/50</span>
                <div
                  class="deleteBox"
                  v-if="!isDelete"
                  @click="isDelete = true"
                >
                  <span
                    :style="`background:url(${require('@/assets/home/delete2.png')}) no-repeat 50% 50% / 100%`"
                  ></span>
                  删除
                </div>
                <div
                  class="confimBut"
                  v-if="isDelete"
                  @click="isDelete = false"
                >
                  完成
                </div>
              </div>

              <div class="deleteList">
                <vue-scroll :ops="ops">
                  <div class="infolist">
                    <div
                      class="deleteBox flex-center"
                      style="position: relative"
                    >
                      <img
                        :src="`${require('@/assets/home/add.png')}`"
                        alt
                        style="width: 48px; height: 48px"
                      />
                      <Upload @uploadImgInfo="uploadImgInfo"></Upload>
                    </div>

                    <div
                      class="deleteBox"
                      v-for="(item, index) in imgList"
                      :key="index"
                      @click="addEditImg(item.previewImage)"
                    >
                      <!-- <img :src="`${item.previewImage}`" alt  /> -->
                      <div
                        :style="`width:100%;height:100%;background:url(${item.previewImage}) 50% 50% / cover`"
                      ></div>
                      <div
                        class="delete flex-center"
                        v-if="isDelete"
                        @click.stop="deleteImgInfo(item.id)"
                      >
                        <div
                          class="icon"
                          :style="`background:url(${require('@/assets/home/delete.png')}) no-repeat 50% 50% / 100%`"
                        ></div>
                      </div>
                    </div>
                  </div>
                </vue-scroll>
              </div>

              <div class="txt">
                为保证效果，请上传透明的图片。格式限png、jpg。
              </div>

              <div class="title">添加文字</div>

              <div class="texBox">
                <div class="inp">
                  <input
                    type="text"
                    v-model="addText"
                    placeholder="请输入文字"
                  />
                  <div class="maxTex">
                    {{ addText.length }} / {{ maxLength }}
                  </div>
                </div>
                <div class="addBox" @click="addEditText">添加</div>
              </div>
            </div>

            <!-- 平面素材 -->
            <div class="planeMaterial" v-if="index == 3">
              <div class="canvasInfo flex-center">
                <canvas id="canvas" width="402" height="250"></canvas>
                <canvas id="uv"></canvas>

                <div class="xLine" style="width: 100%"></div>
                <div class="yLine" style="height: 100%"></div>

                <div class="canvasSize">
                  <span>画布大小</span>
                  <span>{{ canvasSize.width }}*{{ canvasSize.height }} mm</span>
                </div>
              </div>

              <!-- 图片编辑 -->
              <div class="imgEdit" v-if="editType == 1">
                <div class="infoBox flex-center">
                  图片旋转角度(°)
                  <div class="editBox">
                    <div class="edit" @click="changeImgInfo(1, 1)">-</div>
                    <div class="num">
                      <input
                        type="number"
                        v-model="imgEditInfo.rotateNum"
                        @keyup="changeNumber(1)"
                      />
                    </div>
                    <div class="edit" @click="changeImgInfo(1, 2)">+</div>
                  </div>
                </div>

                <div class="infoBox flex-center">
                  图片缩放大小(%)
                  <div class="editBox">
                    <div class="edit" @click="changeImgInfo(2, 1)">-</div>
                    <div class="num">
                      <input
                        type="number"
                        v-model="imgEditInfo.scaleNum"
                        @keyup="changeNumber(2)"
                      />
                    </div>
                    <div class="edit" @click="changeImgInfo(2, 2)">+</div>
                  </div>
                </div>
                <div class="infoBox flex-center">
                  <div class="overButton" @click="oneKeyOver">一键铺满</div>
                  <div class="cancelButton" style="top: 360px" @click="uncheck">
                    取消选中
                  </div>
                </div>
              </div>

              <!-- 选择工艺 -->
              <div v-if="craftInfoList.length != 0" class="craft">
                <!-- <div class="slider-box">
                  <label for="">镂空强度</label>
                  <el-slider
                    v-model="alphaTest"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    @change="changeAlphaTest"
                    show-input>
                  </el-slider>
                </div> -->
               
                <!-- <div class="title">选择贴图材质</div> -->

                <!-- 材质列表-->
                <!-- <div id="craftSwiper">
                  <div class="materialList swiper-wrapper">
                    <div
                      class="infoBox swiper-slide"
                      v-for="(item, index) in craftInfoList"
                      :key="index"
                      @click="craftSelectIndex = index"
                    >
                      <div v-if="item.name && item.name != ''">
                        <div
                          v-if="
                            item.preView && item.preView.indexOf('http') != -1
                          "
                          :class="
                            craftSelectIndex == index ? 'img selectBox' : 'img'
                          "
                          :style="`background:url(${
                            item.preView
                              ? item.preView
                              : require(`@/assets/noimg.png`)
                          }) no-repeat 50% 50% / cover ;  background-position: center; `"
                        ></div>
                        <div
                          v-else
                          :class="
                            craftSelectIndex == index ? 'img selectBox' : 'img'
                          "
                          :style="`background:rgb(${item.preView}); background-position: center; `"
                        ></div>
                        <div class="name" :title="item.name">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="swiper-button-next right-btn"
                    v-show="craftInfoList.length > 5"
                  >
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div
                    class="swiper-button-prev left-btn"
                    v-show="craftInfoList.length > 5"
                  >
                    <i class="el-icon-arrow-left"></i>
                  </div>
                </div> -->
              </div>

              <!-- 文字编辑 -->
              <div class="textEdit" v-if="editType == 2">
                <div class="textInfo flex-center">
                  <div class="left">
                    <textarea
                      v-model="editInfo.textDesc"
                      maxlength="50"
                    ></textarea>
                  </div>
                  <div class="right">
                    <!-- 字体类型 -->
                    <div class="textType">
                      <el-select
                        v-model="editInfo.textType"
                        @change="changeFont"
                        placeholder="请选择字体"
                      >
                        <el-option
                          v-for="item in fontList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>

                    <!--字体样式  -->
                    <div class="textStyle flex-center">
                      <div>
                        <el-select
                          v-model="editInfo.textSize"
                          placeholder="字体大小"
                        >
                          <el-option
                            v-for="(item, index) in typeOptions.textSize"
                            :key="index"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="styleList">
                      <div
                        class="styleBox"
                        v-for="(item, index) in styleList"
                        :key="index"
                        @click="changeStyleSelect(index)"
                        :style="`color:${
                          item.isSelect ? '#0099FF' : '#000000'
                        }`"
                      >
                        <span
                          :style="`background:url(${require(`@/assets/home/text${
                            index + 1
                          }${
                            item.isSelect ? '-s' : ''
                          }.png`)}) no-repeat 50% 50% / 100% 100% ; width
                          :${index == 0 ? 12 : index == 1 ? 14 : 16}px`"
                        ></span>
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="title">变形样式模板</div>

                <!-- 滚动框 -->
                <div class="slideBox">
                  <div
                    class="item"
                    title="取消变形样式"
                    @click="
                      (editInfo.textPathData = ''), (editInfo.curFontTempIndex = -1)
                    "
                  >
                    <img src="@/assets/images/none.png" />
                  </div>
                  <div
                    class="item"
                    :style="{
                      border:
                        editInfo.curFontTempIndex === item.fontStyleId
                          ? '2px solid #0099ff'
                          : '2px solid transparent',
                    }"
                    v-for="item in curFontStyleList"
                    :key="item.fontStyleId"
                    @click="changeTextPath(item.textPath, item.fontStyleId)"
                  >
                    <img :src="item.preview" />
                  </div>

                  <div class="left-btn" @click="prevPage">
                    <i class="el-icon-arrow-left"></i>
                  </div>
                  <div class="right-btn" @click="nextPage">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>

                <!-- 最底部 -->
                <div class="textButton flex-center">
                  <div class="left">
                    <div class="title">字体颜色</div>
                    <div class="colorBox">
                      <div class="color">{{ editInfo.textColor }}</div>

                      <el-color-picker
                        v-model="editInfo.textColor"
                        style="
                          margin: 16px 0px 10px 10px;
                          width: 34px;
                          height: 34px;
                        "
                      ></el-color-picker>
                    </div>
                    <div class="colorList">
                      <div
                        v-for="(item, index) in planeColorList"
                        :key="index"
                        :style="{
                          background: item,
                          border:
                            editInfo.textColor === item
                              ? '2px solid #0099ff'
                              : '2px solid transparent',
                        }"
                        @click="editInfo.textColor = planeColorList[index]"
                      ></div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="title">旋转角度</div>

                    <div class="rotateBox flex-center">
                      <div
                        class="rotate"
                        :style="`background:url(${require('@/assets/home/rotate.png')}) no-repeat 50% 50% / 100% ;`"
                      >
                        <div
                          class="line"
                          :style="`transform:rotate(${planeRotateNum}deg)`"
                        ></div>
                      </div>
                      <div class="rotateNum">
                        <input type="number" v-model="planeRotateNum" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";
import Swiper from "swiper";
import vueScroll from "vuescroll";
import Upload from "@/components/common/uploadImg";
import { Edit } from "@/utils/edit/app.js";
import { throttle } from "lodash";
import axios from "axios";
let edit;
let craftSwiper, matSwiper;
export default {
  components: {
    vueScroll,
    Upload,
  },
  props: {
    materialinfo: {
      type: Object,
    },
    curMatPos: {
      type: Object,
    },
    matPosArr: {
      type: Array,
    },
    knifeInfo: {
      type: Object,
    },
    partList: {
      type: Array,
      default: [],
    },
    craftInfo: {
      type: Object,
    },
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          keepShow: true,
          background: "#0099FF",
          size: "2px",
        },
      }, //滚动条配置参数
      infoType: ["材质颜色", "刀版图素材", "添加素材", "编辑素材"], //模型信息类型
      selectIndex: 0, //轮播图选中下标
      selectColor: "", //选中的颜色
      colorList: [], //颜色列表
      dbtUrl: "",
      colorType: 1, //颜色类型
      addText: "", //添加的文字
      maxLength: 50, //最大长度
      isDelete: false, //是否开启删除状态
      imgEditInfo: {
        rotateNum: 0, //旋转角度
        scaleNum: 100, //缩放大小
      }, //图片编辑的信息
      editType: -1, // 编辑类型 1是图片 2是文字
      typeOptions: {
        textType: [],
        textSize: [6, 8, 10, 12, 14, 16, 18, 20, 22], //文字尺寸
        textStyle: [],
      }, //字体样式列表
      planeColorList: [
        "#FF6632",
        "#F6B37F",
        "#ACD598",
        "#13B5B1",
        "#AA89BD",
        "#E3E3E3",
      ], //素材的颜色列表
      planeRotateNum: 0, //旋转值
      editCanvas: null, //
      editInfo: {
        textType: "", //选中结果
        textSize: "", //选中结果
        textStyle: "", //选中结果
        textDesc: "", //文本域
        textColor: "#000000",
        familyName: "",
        /**是否加粗 */
        isBold: false,
        /**是否斜体 */
        isItalic: false,
        /**横向或纵向 */
        align: 0,
        textPathData: "", //转曲路径数据
      }, //编辑的信息
      styleList: [
        {
          name: "粗体",
          key: "isBold",
          isSelect: false,
        },
        {
          name: "斜体",
          key: "isItalic",
          isSelect: false,
        },
        {
          name: "横排",
          key: "row",
          isSelect: false,
        },
        {
          name: "竖排",
          key: "col",
          isSelect: false,
        },
      ], //样式列表
      modelMaterialList: [], //模型的材质列表
      selectMaterialIndex: -1, //选择材质的下标
      imgList: [], //自定义素材列表
      fontStyleList: [
        {
          fontStyleId: "1",
          preview: require("@/assets/images/yuan_b.png"),
          textPath: "M 0 0 a 1 1 0 0 0 200 0",
        },
        {
          fontStyleId: "2",
          preview: require("@/assets/images/yuan_t.png"),
          textPath: "M 0 100 a 1 1 1 0 1 200 0",
        },
        {
          fontStyleId: "3",
          preview: require("@/assets/images/bolan_t.png"),
          textPath: "M 0 50 Q 43 -19 100 50 Q 157 110 200 50",
        },
        {
          fontStyleId: "4",
          preview: require("@/assets/images/bolan_b.png"),
          textPath: "M 0 50 Q 48 104 100 50 Q 155 -4 200 50",
        },
        {
          fontStyleId: "5",
          preview: require("@/assets/images/tuoyuan_b.png"),
          textPath: "M 0 0 A 1 0.4 0 0 0 200 0",
        },
        {
          fontStyleId: "6",
          preview: require("@/assets/images/tuoyuan_t.png"),
          textPath: "M 0 50 A 1 0.4 0 0 1 200 50",
        },
      ],
      index: 0,
      pageSize: 3,
      maxPage: 0,
      fontList: [],
      canvasSize: {
        width: 0,
        height: 0,
      }, //画布尺寸
      canLoadImg: true, //是否可以加载图片
      selectColorIndex: -1, //选择的颜色下标
      canAddImg: true, //能否添加图片
      planNowPartInfo: [], // 设计方案当前的款式信息
      craftInfoList: [], //工艺列表
      craftSelectIndex: -1, //工艺选择下标
      // curFontTempIndex: -1,
      // alphaTest: 0,//镂空度
      matIndexMap: {},//记录每个材质区域所选的材质下标
      matListMap: {},//记录每个材质区域对应的材质列表，防止切换区域时重复请求材质列表
    };
  },
  computed: {
    curFontStyleList() {
      let index = this.index * this.pageSize;
      return this.fontStyleList.slice(index, index + this.pageSize);
    },
    userId() {
      return this.$store.state.user.userId;
    }
  },
  watch: {
    //工艺材质
    craftInfo: {
      deep: true,
      handler(newVal) {
        if (!newVal) return;
        this.craftInfoList = [];
        let obj = {
          name: "默认材质",
          id: newVal.matDefault || "",
        };

        this.$store.commit("SET_USER_INFO", { defaultCrafId: obj.id });

        // this.craftInfoList.unshift(obj);

        newVal.mat.map((item, index) => {
          this.craftInfoList.push({});
          this.getCraftInfo(item, index);
        });
      },
    },
    //工艺下标
    craftSelectIndex(newVal) {
      let data = this.craftInfoList[newVal];
      console.log(data, "工艺");
      if (newVal == 0) {
        // app.returnDefaultCarftMateriial(data.id);
        // return;
      }
      // if (!data) return;
      // this.getMaterialJsonInfo(data.json, data.id, 2);
    },
    //选择颜色
    selectColor(newVal) {
      console.log(newVal, "newValnewVal");
      this.changeEditColor(newVal);
    },
    // 文字编辑信息
    editInfo: {
      deep: true,
      handler(newVal) {
        //修改文字信息
        edit.changeSelectTextinfo(this.editInfo, this);
      },
    },

    selectIndex(newVal) {
      if (newVal >= 2) {
        edit.activateObjects();
      }
    },
    materialinfo: {
      deep: true,
      handler(newVal) {
        if (JSON.stringify(this.matIndexMap) === '{}') {
          this.$parent.posNameArr.forEach(key => {
            this.matIndexMap[key] = -1;
            this.matListMap[key] = [];
          })
        }
        
        if (!newVal) return;
        // let obj = {
        //   name: "默认材质",
        //   id: newVal.matDefault
        // };
        // this.modelMaterialList.unshift(obj);
        if (this.matListMap[this.$parent.selectPos]) {
          if (!this.matListMap[this.$parent.selectPos].length) {
            this.modelMaterialList = [];
            newVal.mat.map((item, index) => {
              let type = newVal.matDefault == item ? 1 : 2;
              this.modelMaterialList.push({});
              this.getmaterialInfo(item, type, index);
            });
          } else {
            this.modelMaterialList = this.matListMap[this.$parent.selectPos];
            setTimeout(() => {
              if (matSwiper) matSwiper.destroy();
              this.initMatSwiper();
            })
          }
        }
        

        if (!this.curMatPos.material) return;
        console.log(this.curMatPos, "JSON");

        this.selectColor =
          "#" + this.curMatPos.material[0].color.getHexString();

        console.log(this.selectColor, 'this.selectColor')

        this.changeColorIndex();

        if (this.$store.state.cases.isSaveCase || this.$route.query.cartId) {
          if (this.matIndexMap[this.$parent.selectPos] === -1) {
            setTimeout(() => {
              const index = app.modelMaterlInfo.findIndex(
                (f) => f.modelName === this.curMatPos.name
              );
              var myEvent = new CustomEvent("changeMatIndex", {
                // objParams就是需要传递的参数，
                // 可以是任意的类型
                detail: app.modelMaterlInfo[index]
                  ? app.modelMaterlInfo[index].materialId
                  : -1,
              });
              document.body.dispatchEvent(myEvent);
            }, 1000);
          } else {
            this.selectMaterialIndex = this.matIndexMap[this.$parent.selectPos];
          }
        } else {
          if (this.matIndexMap[this.$parent.selectPos] === -1) {
            setTimeout(() => {
                var myEvent = new CustomEvent('changeMat', {
                    // objParams就是需要传递的参数，
                    // 可以是任意的类型
                    detail: null
                });
                document.body.dispatchEvent(myEvent);

            }, 500)
          } else {
            this.selectMaterialIndex = this.matIndexMap[this.$parent.selectPos];
          }
        }

        // lc 单层纸杯材质层side改为0
        setTimeout(() => {
          let modelInfo = app.viewer.modelManager.modelGroup.getObjectByName('RootNode').children[0];
          if (modelInfo && modelInfo.name === 'dancengzhibei') {
            modelInfo.getObjectByName('ColorIndex').children[0].material[0].side = 0;
          }
        }, 1500)

        setTimeout(() => {
          const index = this.matIndexMap[this.$parent.selectPos];
          const info = index == -1 ? this.modelMaterialList[0] : this.modelMaterialList[index];
          this.getTextInfo(info.id, this.curMatPos.name, 1);
        }, 1100)

      },
    },
    //材质下标改变
    selectMaterialIndex(newVal, oldVal) {
      if (newVal == -1) return;
      let data = this.modelMaterialList[newVal];
      if (!data) return;
      console.log(data, "材质");
      if (newVal == 0) {
        // this.colorList = [];
        // this.colorType = -1;
        // app.returnDefaultMaterial(data.id);
        // return;
      }
      this.colorType = data.colorType == 1 ? 1 : 2;
      // this.selectColorIndex = -1;
      if (data.colorType == 0) {
        this.changeColorList();
      }

      this.changeColorIndex();

      if (this.$store.state.cases.isSaveCase || this.$route.query.cartId) {
        oldVal !== -1 && this.getMaterialJsonInfo(data.json, data.id, 1); // 防止在刚进入编辑页时，材质下标改变去加载材质
      } else {
        this.getMaterialJsonInfo(data.json, data.id, 1); // 防止在刚进入编辑页时，材质下标改变去加载材质
      }
      
      // this.getMaterialJsonInfo(data.json, data.id, 1); // 防止在刚进入编辑页时，材质下标改变去加载材质

      this.matIndexMap[this.$parent.selectPos] = this.selectMaterialIndex;

      this.getTextInfo(data.id, this.curMatPos.name, 1);
    },
    planeRotateNum(newVal) {
      if (newVal == "") {
        this.planeRotateNum = 0;
      } else if (newVal >= 360) {
        this.planeRotateNum = this.planeRotateNum - 360;
      }
      this.rotateObj(this.planeRotateNum);
    },
    addText(newVal) {
      if (newVal.length >= this.maxLength) {
        this.$message({
          type: "error",
          message: "最多50个字~",
        });
        this.addText = newVal.substring(0, 50);
      }
    },
    imgEditInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.scaleNum < 0) {
          newVal.scaleNum = 0;
        } else if (newVal.scaleNum > 500) {
          newVal.scaleNum = 500;
        }

        if (newVal.rotateNum < 0) {
          newVal.rotateNum = 360;
        } else if (newVal.rotateNum > 360) {
          newVal.rotateNum = 0;
        }
        edit.changeEditImage(newVal);
      },
    },
    partList(newVal) {
      newVal.map((item) => {
        if (this.planNowPartInfo.length != 0) {
          let info = this.planNowPartInfo.find((ite) => {
            return ite.partName == item.partName;
          });
          if (info) {
            let index = item.partArr.findIndex((partInfo) => {
              return partInfo.userData.partName == info.partInfoName;
            });

            item.selectIndex = index != -1 ? index : 0;
          }
        } else {
          item.selectIndex = 0;
        }
      });
    }
  },
  created() {
    let _this = this;
    this.maxPage = Math.floor(this.fontStyleList.length / this.pageSize);
    this.getFontList();

    this.bodyHeight = document.body.offsetHeight;

    document.body.addEventListener("editOk", () => {
      _this.editCanvas = window.edit.editCanvas;
      _this.bindFun();
      // console.log('初始化时')
    });

    document.body.addEventListener("changeCraft", () => {
      let index = _this.craftInfoList.findIndex((item) => {
        return item.id == (app.craftInfo && app.craftInfo.id);
      });
      index = index != -1 ? index : 0;

      _this.craftSelectIndex = index;
    });
  },
  mounted() {

    Bus.$on('resetmatIndexMap', () => {
      this.$parent.posNameArr.forEach(key => {
        this.matIndexMap[key] = -1;
      })
      console.log(this.matIndexMap, '初始化matIndexMap')
    })

    this.ticket = this.$route.query.ticket;
    this.initSwiper();

    this.initCanvas();

    this.getSystemList();

    Bus.$on("changePlan", () => {
      this.selectMaterialIndex = -1;
      this.editType = -1;
    });

    Bus.$on("changePartInfo", (info) => {
      this.planNowPartInfo = info;
    });

    Bus.$on("changeCartIndex", () => {
      this.craftSelectIndex = -1;
    });
    Bus.$on("closeEditDiv", () => {
      this.editType = -1;
    });

    // 编辑时，同时加载贴图材质
    document.body.addEventListener("loadTextMat", (obj) => {
      this.getTextInfo(obj.detail.materialId, obj.detail.index, 2);
    });
    // lc 未保存方案时，加载材质和颜色
    document.body.addEventListener("changeMat", (obj) => {

      this.handleDefaultMat();
    });

    // lc 色轮设为当前模型颜色
    document.body.addEventListener("changeColor", () => {
      if (!this.curMatPos.material) return;
      this.selectColor = "#" + this.curMatPos.material[0].color.getHexString();

      this.changeColorIndex();

    });

    // lc 根据当前材质区域信息中的材质id的对比来选中材质
    document.body.addEventListener("changeMatIndex", (obj) => {
      if (obj.detail === -1) {
        this.selectMaterialIndex = 0;
        this.matIndexMap[this.$parent.selectPos] = this.selectMaterialIndex;
        return;
      }
      if (!this.curMatPos.material) return;
      const index = this.curMatPos.userData.defaultMaterialInfo.mat.findIndex(
        (id) => id === obj.detail
      );
      this.selectMaterialIndex = index !== -1 ? index : 0;

      this.selectColor = "#" + this.curMatPos.material[0].color.getHexString();
      console.log(this.selectMaterialIndex, "this.selectMaterialIndex");

      this.matIndexMap[this.$parent.selectPos] = this.selectMaterialIndex;
    });

    Bus.$on("resetModel", () => {
      this.reductionModel();
    });
  },
  methods: {
    getTextInfo(id, index, flag) {
      const {matText} = this.craftInfo;
      let textMid, text;
      for (let key of Object.keys(matText)) {
        if (matText[key].mid === id) {
          textMid = key;
          text = matText[key].text
          break;
        }
      }

      const craft = this.craftInfoList.find(f => f.id === textMid);
      if (craft) {
        // if (text && text.length > 1 && (index !== undefined)) {
        //   if (flag === 1) {

        //     text = text.filter(f => f === index);
        //   } else {
            
        //     text = [text[index]];
        //   }
        // }
        this.getMaterialJsonInfo(craft.json, craft.id, 2, text);
        console.log(craft, '匹配到了对应的贴图材质')
      }
     
      
    },
    changeColorIndex() {
      // lc有色卡时，选中对应的颜色
      if (this.colorList.length > 0 && this.selectColor) {
        for (let i = 0; i < this.colorList.length; i++) {
          const color = this.colorList[i].color;
          if (color.toLocaleLowerCase() === this.selectColor.toLocaleLowerCase()) {
            this.selectColorIndex = i;
            return;
          }
        }
        this.selectColorIndex = -1;
      }
    },
    // 最多小数点后2位
    changeNumber(type) {
      const reg = /^([1-9]\d*|0)(\.\d{1,2})?$/;
      if (type === 1) {
        while (
          !reg.test(this.imgEditInfo.rotateNum) &&
          this.imgEditInfo.rotateNum != ""
        ) {
          this.imgEditInfo.rotateNum = checkStr(this.imgEditInfo.rotateNum);
        }
      } else {
        while (
          !reg.test(this.imgEditInfo.scaleNum) &&
          this.imgEditInfo.scaleNum != ""
        ) {
          this.imgEditInfo.scaleNum = checkStr(this.imgEditInfo.scaleNum);
        }
      }
      function checkStr(str) {
        str = str.substring(0, str.length - 1);
        return str;
      }
    },
    handleDefaultMat() {
      if (!this.curMatPos.material) return;

      console.log(this.$store.state.user, 'this.$store.state.user.fixedComponents')
      const fixedComponents = JSON.parse(
        this.$store.state.user.fixedComponents
      );

      const curMatIndex = this.$parent.posNameArr.findIndex(f => f === this.$parent.selectPos);
      let matPosArr_ = [];

      if (curMatIndex === 0) {
        matPosArr_ = this.matPosArr;
      } else {
        matPosArr_ = [this.matPosArr[curMatIndex]];
      }
      // 模型初始化时
      let i = -1;
      // 加载客户页面带过来的材质和颜色
      matPosArr_.forEach((obj, index) => {
        const mat = obj.userData.defaultMaterialInfo.mat;

        curMatIndex === 0 && this.getMatInfo(mat[0], obj.userData.cName, "", 2); //lc 获取材质列表的第一个材质信息

        for (let item of fixedComponents) {
          for (let childItem of item.componentSpecs) {
            const matId = mat.find(
              (id) => id === childItem.productSpecValueCode
            );
            if (matId) {
              const index =
                this.curMatPos.userData.defaultMaterialInfo.mat.findIndex(
                  (id) => id === matId
                );
              if (index !== -1) {
                if (this.$parent.selectPos === obj.userData.cName) i = index;
              }

              const colorIndex = item.componentSpecs.findIndex(
                (f) =>
                  f.productSpecValueCode !== childItem.productSpecValueCode
              );
              let color;
              if (colorIndex !== -1) {
                color = item.componentSpecs[colorIndex].productSpecValueCode;

                if (this.$parent.selectPos === obj.userData.cName) this.selectColor = color;
              }

              this.getMatInfo(matId, obj, color, 1);

              return;
            }
          }
        }
        if (index > 0) {
          this.getMatInfo(mat[0], obj, undefined, 1);
          this.getTextInfo(mat[0]);
        }
      });

      this.selectMaterialIndex = i !== -1 ? i : 0;
      this.matIndexMap[this.$parent.selectPos] = this.selectMaterialIndex;
    },
    getMatInfo(id, obj, color, type) {
      this.$api.material.getmaterialInfo(id).then((res) => {
        this.getMatJsonInfo(res.fileModelPath, id, type, obj, color);
      });
    },
    //请求到材质的json文件
    getMatJsonInfo(url, id, type, obj, color) {
      if (!url) return;
      axios.get(url).then((res) => {
        let jsonData = res.data;
        if (type == 1) {
          // console.log('%c报错信息：','background:red;color:white;padding:5px;border-radius:4px;', '00000000000')
          app.loadNewMaterial(jsonData, id, obj, color);
        } else {
          // app.craftToModel(jsonData, id);
          // 当没有选择材质区域时，先把第一个材质信息存起来
          const cObj = {};
          cObj["materialInfo"] = jsonData;
          cObj["materialId"] = id;
          app.oneMatInfo[obj] = cObj;
        }
      });
    },

    //取消选中
    uncheck() {
      edit.uncheckImg();
      this.editType = null;
    },
    //一键铺满
    oneKeyOver() {
      let info = edit.oneKeyOver();
      this.imgEditInfo.scaleNum = (info.scale * 100).toFixed(2);

      if (info.type == 2) {
        this.$message({
          type: "warning",
          message: "当前图片无法完整铺满画布",
        });
        return;    
      }
    },
    //改变当前部件 ind 获取部件下标  ind2 获取到哪个部件组
    changenowPart(partInfo, ind1, ind2) {
      if (partInfo.selectIndex == ind1) return;

      this.partList[ind2].selectIndex = ind1;
      this.$forceUpdate();
      app.changePartArr(partInfo, ind1);
    },
    //请求到材质的json文件 type = 1材质 2 是工艺, text 贴图区域
    getMaterialJsonInfo(url, id, type = 1, text) {
      if (!url) return;
      axios.get(url).then((res) => {
        let jsonData = res.data;
        if (type == 1) {
          // console.log('%c报错信息：','background:red;color:white;padding:5px;border-radius:4px;', '111111111')
          app.loadNewMaterial(jsonData, id, undefined, this.selectColor);
        } else {
          app.craftToModel(jsonData, id, text);
        }
      });
    },
    //旋转对象
    rotateObj(angle) {
      let obj = this.editCanvas._activeObject;
      if (obj) {
       
        obj.rotate(angle);
        this.updateThrottle();

        if(obj.editType === 2){
          window.edit.exportModelImg().then(res=>{
            app.modelMap.currentMesh.refreshText(res);
          });
        }
      }
    },
    //触发更新canvas对象
    updateEditView() {
      if (this.editCanvas) {
        this.editCanvas.renderAll();

        
      }
    },
    //触发更新
    updateThrottle: throttle(function () {
      this.updateEditView();
    }, 50),
    //切换文字转曲信息
    changeTextPath(path, index) {
      this.editInfo.curFontTempIndex = index;
      this.editInfo.textPathData = path;
    },
    //q切换字体样式
    changeFont(val) {
      const item = this.fontList.find((value) => value.id === val);
      if (item) {
        this.editInfo.familyName = item.name;
      }
    },
    //获取文字样式列表
    getFontList() {
      this.$api.system.getFontList(1).then((data) => {
        this.fontList = data;
      });
    },
    //当修改下标时给颜色重新赋值
    changeColorList() {
      if (this.selectMaterialIndex == -1) {
        this.colorList = [];
        return;
      }
      let data = this.modelMaterialList[this.selectMaterialIndex];
      if (!data || !data.color) return;
      let arr = data.color.split(",");
      let res = [];
      const remark = JSON.parse(data.remark);
      arr.map((item, index) => {
        res.push({
          color: item,
          remark: remark ? remark[index].remark : null
        });
        if (item == "" || item.indexOf("#") != 0) {
          res.push({
            color: "#FFFFFF",
            remark: remark[index].remark
          });
        }
      });
      // res.push({color: "#FFFFFF", remark: '#FFFFFF'});
      this.colorList = res;
    },

    //获取材质信息
    getmaterialInfo(id, type, index) {
      let data = id;
      this.$api.material.getmaterialInfo(data).then((res) => {
        let obj = {
          color: res?.hex,
          preView: res?.preview,
          name: res?.name,
          id: res?.id,
          json: res?.fileModelPath,
          colorType: res?.colorType,
          remark: res?.remark
        };

        // this.modelMaterialList[index + 1] = obj;
        this.$set(this.modelMaterialList, index, obj);
        if (index + 1 == this.modelMaterialList.length) {
          if (matSwiper) matSwiper.destroy();
          this.initMatSwiper();

          this.matListMap[this.$parent.selectPos] = this.modelMaterialList;
        }
        this.changeColorList();
      });
    },

    //获取工艺信息
    getCraftInfo(id, index) {
      let data = id;
      this.$api.material.getmaterialInfo(data).then((res) => {
        if (!res) return;
        let obj = {
          preView: res.preview,
          name: res.name,
          id: res.id,
          json: res.fileModelPath,
        };

        // this.craftInfoList[index] = obj;
        this.$set(this.craftInfoList, index, obj);
        if (index + 1 == this.craftInfoList.length) {
          // if (craftSwiper) craftSwiper.destroy();
          // this.initCraftSwiper();
        }
      });
    },

    //切换转曲路径
    prevPage() {
      if (this.index > 0) {
        this.index--;
      }
    },

    nextPage() {
      if (this.index < this.maxPage - 1) {
        this.index++;
      }
    },

    //改变当前选中的图片信息 type == 2 缩放 type == 1 旋转
    changeImgInfo(type, mathType) {
      let size = mathType == 1 ? -1 : 1;
      if (type == 1) {
        this.imgEditInfo.rotateNum = (
          Number(this.imgEditInfo.rotateNum) + size
        ).toFixed(2);
      } else {
        this.imgEditInfo.scaleNum = (
          Number(this.imgEditInfo.scaleNum) + size
        ).toFixed(2);
      }
      
    },
    //进入编辑区域
    enterToEdit(e) {
      setTimeout(() => {
        let app = window.app;
        if (e.type == "mouseout") {
          app.openEidt = false;
        } else {
          app.openEidt = true;
        }
      }, 500);
    },
    //切换选中
    changeShowSelect(ind) {
      // if (ind === 3 && this.$parent.selectTexturePos == "无") {
      //   return this.$message({
      //     type: "error",
      //     message: "请先选择贴图区域",
      //   });
      // }

      this.selectIndex = ind;

      this.swiper.slideTo(ind, 1000, false); //切换到第一个slide，速度为1秒
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
    },
    initCraftSwiper() {
      craftSwiper = new Swiper("#craftSwiper", {
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 5,
        loop: false,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    initMatSwiper() {
      matSwiper = new Swiper("#matSwiper", {
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 5,
        loop: false,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    //初始化编辑面板
    initCanvas() {
      let dom = document.getElementsByClassName("canvasInfo")[0];
      let info = {
        maxWidth: dom.offsetWidth,
        maxHeight: dom.offsetHeight,
        dom: "canvas",
      };
      edit = new Edit(info);
      window.edit = edit;
      edit.initEdit(info.maxHeight, info.maxHeight, 2);
    },

    //绑定事件
    bindFun() {
      let _this = this;
      //选中
      this.editCanvas.on("selection:created", function (event) {
        _this.updateSelectEdit(event);
      });

      //取消选中
      this.editCanvas.on("selection:cleared", function (event) {
        if (!_this.editCanvas._activeObject) {
          _this.editType = -1;
        }
      });

      //更新事件
      this.editCanvas.on("selection:updated", function (event) {
        _this.updateSelectEdit(event);
      });

      this.editCanvas.on("mouse:move", function (event) {
         _this.updateSelectEdit(event,true);
      });

      /**对象旋转事件 */
      this.editCanvas.on("object:rotating", function (event) {
        _this.updateSelectEdit(event);
      });

      /**监听对象缩放事件 */
      this.editCanvas.on("object:scaling", function (event) {
        _this.updateSelectEdit(event);
      });


      document.addEventListener("changeSelect", () => {
        if (!_this.editCanvas._activeObject) {
          _this.editType = -1;
        }
      });

      document.addEventListener("changeSize", () => {
        let data = _this.editCanvas;
        _this.canvasSize.width = Math.floor(data.width);
        _this.canvasSize.height = Math.floor(data.height);
      });
    },

    //执行了切换编辑对象方法
    updateSelectEdit(info,isMove = false) {
      let target;
      if (info.selected && info.selected.length != 0) {
        target = info.selected[0];
      } else if (info.target) {
        target = info.target;
      }
      //  edit.updateImg();
      if (target) {
        if(this.editCanvas._activeObject != target){
          return;
        }
        //target.uid
        this.editType = target.editType;
        if (this.editType == 1) {
          this.imgEditInfo.rotateNum = target.angle
            ? target.angle.toFixed(2)
            : 0;
          this.imgEditInfo.scaleNum = (target.scaleX * 100).toFixed(2);
        } else {
          this.changeSelectInfo();
        }

        if(isMove === true){
          if(target.editType != 2){
            app.modelMap.refresh(target.uid,"",{x:target.scaleX,y:target.scaleY},{x:target.left,y:target.top},target.angle)
            window.edit.matMap.syncJson(target,target.uid);
          }else{
            window.edit.exportModelImg().then(res=>{
              app.modelMap.currentMesh.refreshText(res);
            });
          }
        }
        
        
      }
    },

    //修改材质颜色
    changeEditColor(color, index) {
      if (index !== undefined) {
        this.selectColorIndex = index;
      }
      this.$emit("changeColor", {
        color,
        id:
          this.modelMaterialList[this.selectMaterialIndex] &&
          this.modelMaterialList[this.selectMaterialIndex].id,
      });
    },
    
    //添加编辑的图片
    addEditImg(url) {
      if (!this.canAddImg) return;

      this.canAddImg = false;
      setTimeout(() => {
        this.canAddImg = true;
      }, 2000);
      url = `${url}?time=${Date.now()}`;
      //edit.addEditImg(url);
      edit.addTexture(url);

      this.changeShowSelect(3);

    },

    //添加编辑的文字
    addEditText() {
      this.changeShowSelect(3);

      this.styleList.map((item) => {
        item.isSelect = false;
      });

      this.editInfo = {
        textType: "", //选中结果
        textSize: "", //选中结果
        textStyle: "", //选中结果
        textDesc: "", //文本域
        textColor: "#000000",
        familyName: "",
        isBold: false,
        isItalic: false,
        align: 0,
        textPathData: "", //转曲路径数据
      };
      this.editInfo.textPathData = "";
      edit.addEditText(this.addText, this);
      this.addText = "";
    },

    //文字的信息
    changeSelectInfo() {
      let info = this.editCanvas._activeObject;
      if (info.textInfo) {
        this.editInfo = info.textInfo;
        this.editInfo.textPathData = info.loadPath;
        if (info.textInfo.align == 3 || info.textInfo.align == 4) {
          let align = info.textInfo.align;
          let isAlign = align == 3 || align == 0 ? true : false;

          this.styleList[2].isSelect = isAlign;
          this.styleList[3].isSelect = !isAlign;
        }
        this.styleList[0].isSelect = info.textInfo.isBold;
        this.styleList[1].isSelect = info.textInfo.isItalic;
      } else {
        this.editInfo.textDesc = info.text;
        this.editInfo.textSize = info.fontSize;
        this.editInfo.textPathData = info.loadPath;
        this.editInfo.textColor = info.color;
        this.planeRotateNum = info.angle ? Math.floor(info.angle) : 0;
      }
    },

    //切换样式
    changeStyleSelect(ind) {
      this.styleList[ind].isSelect = !this.styleList[ind].isSelect;
      const key = this.styleList[ind].key;
      if (key != "row" && key != "col") {
        this.editInfo[key] = this.styleList[ind].isSelect;
        return;
      }
      this.editInfo.align = key == "col" ? 4 : 0;
      if (key == "col") {
        this.styleList[2].isSelect = false;
      } else {
        this.styleList[3].isSelect = false;
      }
    },

    //下载刀版图
    uploadImg() {
      let a = document.createElement("a"); // 创建一个a节点插入的document
      let event = new MouseEvent("click"); // 模拟鼠标click点击事件
      a.download = "刀版图";
      a.href = this.knifeInfo.downUrl;
      // a.dispatchEvent(event);

      window.open(this.knifeInfo.downUrl, "_blank");
      // let imgSrc = document.getElementById("dbImg").src;
      // let image = new Image();
      // image.src = imgSrc + "?v=" + Math.random(); // 处理缓存
      // image.crossOrigin = "*"; // 支持跨域图片
      // image.onload = function() {
      //   let base64 = getBase64Image(image);
      //   a.href = base64;
      //   // 触发点击
      //   a.dispatchEvent(event);
      // };
      // // 图片转换成base64
      // function getBase64Image(img) {
      //   let canvas = document.createElement("canvas");
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   let ctx = canvas.getContext("2d");
      //   ctx.drawImage(img, 0, 0, img.width, img.height);
      //   let dataURL = canvas.toDataURL("image/jpg", 0); // 可选其他值 image/jpeg
      //   return dataURL;
      // }
    },

    //获取素材列表
    getSystemList() {
      this.$api.system.getSystemList(this.userId).then((res) => {
        this.imgList = res;
        this.canLoadImg = true;
      });
    },

    //上传图片资源
    uploadImgInfo(url) {
      if (this.imgList.length >= 50) {
        this.$message({
          type: "error",
          message: "上传图片达到上限",
        });
        return;
      }
      let data = {
        createUserId: this.userId,
        previewImage: url,
      };
      this.$api.system.uploadSystemInfo(data).then((res) => {
        this.getSystemList();
      });
    },

    //删除图片
    deleteImgInfo(id) {
      let data = id;
      this.$api.system.deleteImgInfo(data).then((res) => {
        this.getSystemList();
      });
    },

    //还原模型
    reductionModel() {
      // this.craftSelectIndex = 0;
      // app.initializeNowModel(2);

      // if (this.$store.state.cases.isSaveCase || this.$route.query.cartId) {
      //   setTimeout(() => {
      //     this.selectMaterialIndex = 0;
      //   }, 1000)
      // }
      if (!this.$store.state.cases.isSaveCase) {
        this.craftSelectIndex = 0;
      } else {
        this.craftSelectIndex = -1;
      }
      this.selectMaterialIndex = -1;

      let event = document.createEvent("HTMLEvents");
      event.initEvent("editOk", false, false);
      document.body.dispatchEvent(event);

      // Bus.$emit('changeLoadingName', '初始化模型中...')
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/.el-upload {
  width: 100%;
  height: 100%;
  background: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overButton {
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: white;
  background: #0099ff;
  text-align: center;
  cursor: pointer;
  margin-right: 30px;
  border-radius: 2px;
}
.cancelButton {
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: white;
  background: #999;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}
.info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  .list {
    width: 48px;
    height: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.39);
    .box {
      width: 100%;
      height: 148px;
      border-bottom: 1px solid white;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      > div {
        line-height: 24px;
        width: 16px;
      }
      &:nth-of-type(1){
        border-top: 1px solid white;
      }
    }
  }
  .swiper-container {
    width: 564px;
    height: 100%;
    // max-height: 78vh;
    // overflow-y: auto;
    // overflow-y:overlay;
    // transform:translate3d(0,0,0)!important;
    position: absolute;
    right: 48px;
    top: 0;
    background: white;
    .swiper-slide {
      padding: 21px;
      box-sizing: border-box;
      // transform: translate3d(0, 0, 0)!important;
      > div {
        width: 100%;
      }
      .craft{
        margin-top: 20px;
      }
      .craft,
      .mat {
        overflow: hidden;
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;
          position: relative;
        }
        #craftSwiper,
        #matSwiper {
          position: relative;
          .swiper-slide {
            padding: 0 !important;
          }
          .swiper-button-prev,
          .swiper-button-next {
            // width: 15px;
            // height: 32px;
            // top: 29%;
            background-size: 0 0;
          }
          .left-btn,
          .right-btn {
            position: absolute;
            top: 48%;
            width: 20px;
            height: 32px;
            transform: translateY(-50%);
            background-color: #ccc;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .left-btn {
            left: 0;
            border-radius: 0 5px 5px 0;
          }
          .right-btn {
            right: 0;
            border-radius: 5px 0 0 5px;
          }
        }
        .materialList {
          margin-top: 21px;
          padding-bottom: 21px;
          border-bottom: 1px solid #e8e8e8;
          margin-bottom: 16px;
          .infoBox {
            cursor: pointer;
            > div {
              width: 84px;
            }
            .img {
              height: 86px;
              margin-bottom: 11px;
              border: 1px solid #f0f0f0;
            }
            .name {
              width: 100%;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #1a1a1a;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .slider-box{
          width: 70%;
          margin-top: 10px;
          display: flex;
          align-items: center;
          > label{
            flex-shrink: 0;
            margin-right: 20px;
            color: #333;
            font-size: 16px;
          }
          .el-slider{
            width: 400px;
            /deep/ input {
              height: 32px!important;
            }
          }
        }
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        position: relative;
        .deleteBox {
          display: flex;
          align-items: center;
          cursor: pointer;
          > span {
            width: 14px;
            height: 16px;
            display: inline-block;
            margin-right: 12px;
          }
          position: absolute;
          right: 0;
        }
        .confimBut {
          width: 48px;
          height: 28px;
          background: #0099ff;
          border-radius: 2px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 28px;
          position: absolute;
          cursor: pointer;
          right: 0;
        }
        .search {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-left: 20px;
        }
        > a {
          font-size: 14px;
          font-weight: 400;
          color: #0099ff;
          text-decoration: underline;
          margin-left: 5px;
        }
      }
      .dbtBox {
        width: 100%;
        height: 100%;
        > div {
          width: 100%;
        }
        .imgBox {
          height: 554px;
          border: 1px solid #e5e5e5;
          > img {
            width: 470px;
            height: 514px;
          }
        }
        .button {
          height: 36px;
          background: #0099ff;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }
        .textInfo {
          text-align: center;
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #fa5151;
          margin-top: 6px;
        }
      }
      .materialBox {
        .title {
          width: 100%;
          position: relative;
          .returnOriginal {
            position: absolute;
            right: 0;
            border-radius: 5px;
            height: 30px;
            text-align: center;
            padding: 0px 10px;
            color: white;
            line-height: 28px;
            background: #0099ff;
            cursor: pointer;
          }
        }
        .selectPos {
          height: 34px;
          display: flex;
          align-items: center;
          padding: 30px 0;
          border-bottom: 1px solid #e8e8e8;
          color: #333;
          // margin-bottom: 20px;
          .moreBox {
            width: 130px;
            height: 34px;
            margin-left: 10px;
          }
        }
        .materialList {
          margin-top: 21px;
          padding-bottom: 21px;
          border-bottom: 1px solid #e8e8e8;
          // margin-bottom: 16px;
          display: flex;
          flex-wrap: nowrap;
          .infoBox {
            cursor: pointer;
            flex-shrink: 0;
            margin-right: 26px;
            .img {
              height: 86px;
              width: 84px;
              margin-bottom: 11px;
              border: 1px solid #f0f0f0;
            }
            .name {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              color: #1a1a1a;
              overflow: hidden;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .colorPick {
          margin-top: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > div {
            text-align: center;
            line-height: 34px;
            cursor: pointer;
          }
          .colorBox {
            width: 84px;
            height: 34px;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            margin-right: 10px;
          }
          .colorBox2 {
            width: 88px;
            height: 88px;
            box-sizing: border-box;
            margin-right: 16px;
            margin-bottom: 20px;
            position: relative;
            border: 2px solid transparent;
            // &:last-child{
            //   border: 2px solid #cccccc;
            // }
            > span{
              width: 88px;
              position: absolute;
              bottom: -38px;
              left: 50%;
              transform: translateX(-50%);
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              color: #1a1a1a;
            }
          }

          > span {
            text-decoration: underline;
            margin-left: 10px;
            color: #0099ff;
            cursor: pointer;
          }
        }
      }
      .default-mat{
        .title{
          
        }
        .mat-box{
          width: 84px;
          height: 84px;
          margin-top: 16px;
          margin-bottom: 11px;
          background: #fff;
          border: 2px solid #09f!important;
          cursor: pointer;
        }
        .name{
          font-size: 14px;
          font-weight: 400;
          color: #1a1a1a;
        }
      }
      .customMaterial {
        > div {
          box-sizing: border-box;
        }
        .selectPos {
          height: 34px;
          display: flex;
          align-items: center;
          padding: 30px 0;
          border-bottom: 1px solid #e8e8e8;
          margin-bottom: 20px;
          color: #333;
          .moreBox {
            width: 130px;
            height: 34px;
            margin-left: 10px;
          }
        }
        .deleteList {
          padding: 10px 5px 10px 12px;
          box-sizing: border-box;
          margin-top: 22px;
          width: 520px;
          height: 406px;
          background: #f3f3f3;
          border: 1px solid #e8e8e8;
          .infolist {
            display: flex;
            flex-wrap: wrap;
            overflow-x: hidden;
          }
          .addBox {
          }
          .deleteBox {
            width: 88px;
            height: 88px;
            background: #ffffff;
            border: 1px solid #e8e8e8;
            margin-bottom: 11px;
            position: relative;
            margin-right: 10px;
            cursor: pointer;
            .delete {
              width: 88px;
              height: 20px;
              background: rgba($color: #5a5e60, $alpha: 0.8);
              position: absolute;
              bottom: 0;
              .icon {
                width: 13px;
                height: 14px;
              }
            }
          }
        }
        .txt {
          font-size: 14px;
          font-weight: 400;
          color: #fa5151;
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: 1px solid #e8e8e8;
          margin-bottom: 20px;
        }
        .texBox {
          margin-top: 20px;
          display: flex;
          .inp {
            display: flex;
            padding: 8px 10px;
            box-sizing: border-box;
            justify-content: space-between;
            width: 412px;
            height: 34px;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            > input {
              height: 100%;
              width: 76%;
              color: #999999;
            }
            .maxTex {
              font-size: 14px;
              color: #999999;
            }
          }
          .addBox {
            width: 88px;
            height: 34px;
            background: #0099ff;
            border-radius: 2px;
            margin-left: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .planeMaterial {
        position: relative;
        .canvasInfo {
          width: 100%;
          height: 310px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          // margin-bottom: 21px;
          position: relative;
          overflow: hidden;
          > div {
            position: absolute;
          }
          .xLine {
            border-top: 1px dashed rgba($color: #0099ff, $alpha: 0.2);
            pointer-events: none;
          }
          .yLine {
            border-left: 1px dashed rgba($color: #0099ff, $alpha: 0.2);
            pointer-events: none;
          }
          .canvasSize {
            z-index: 20;
            right: 10px;
            bottom: 10px;
            > span {
              display: block;
              text-align: right;
              font-size: 12px;
              font-weight: 400;
              color: #000;
            }
          }
          #uv {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;
            pointer-events: none;
          }
        }
        .imgEdit {
          width: 100%;
          margin-top: 20px;
          border-bottom: 1px solid #e8e8e8;
          .infoBox {
            font-size: 16px;
            color: #333333;
            margin-bottom: 20px;
            .editBox {
              margin-left: 20px;
              display: flex;
              border: 1px solid #e8e8e8;
              box-sizing: border-box;
              .edit {
                width: 34px;
                height: 34px;
                background: rgba(230, 230, 230, 0.34);
                border-radius: 2px 0px 0px 2px;
                box-sizing: border-box;
                text-align: center;
                color: #9e9e9e;
                font-size: 20px;
                font-weight: 600;
                line-height: 34px;
                cursor: pointer;
              }
              .num {
                width: 107px;
                height: 100%;
                text-align: center;
                line-height: 34px;
                > input {
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .textEdit {
          div {
            box-sizing: border-box;
          }
          .textInfo {
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 20px;
            justify-content: space-between;
            padding-bottom: 10px;
            > div {
              width: 245px;
              height: 106px;
            }
            .left {
              border: 1px solid #e8e8e8;
              border-radius: 2px;
              padding: 10px 8px;
              > textarea {
                width: 100%;
                height: 100%;
                resize: none;
              }
            }

            .right {
              > div {
                width: 100%;
                height: 34px;
              }
              .styleList {
                display: flex;
                margin-top: 9px;
                .styleBox {
                  height: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #1a1a1a;
                  margin-right: 10px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                }
                span {
                  display: inline-block;
                  height: 16px;
                  margin-right: 4px;
                }
              }
              .textStyle {
                justify-content: space-between;
                margin-top: 10px;
                > div {
                  width: 117.5px;
                  height: 100%;
                }
              }
            }
          }
        }
        .slideBox {
          position: relative;
          margin: 20px -5px 12px -5px;
          width: 100%;
          height: 58px;
          display: flex;
          .item {
            height: 54px;
            width: 120px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            cursor: pointer;
            flex-shrink: 0;
            text-align: center;
            margin: 0 5px;
            border: 2px solid transparent;
            img {
              height: 100%;
              width: auto;
            }
          }
          .left-btn,
          .right-btn {
            position: absolute;
            top: 50%;
            width: 20px;
            height: 32px;
            transform: translateY(-50%);
            background-color: #ccc;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .left-btn {
            left: 5px;
            border-radius: 0 5px 5px 0;
          }
          .right-btn {
            right: 5px;
            border-radius: 5px 0 0 5px;
          }
        }
        .textButton {
          justify-content: space-between;
          > div {
            width: 243px;
          }
          .left {
            > div {
              display: flex;
            }
            .colorBox {
              .color {
                width: 84px;
                height: 34px;
                border: 1px solid #cccccc;
                border-radius: 2px;
                margin-right: 10px;
                margin: 20px 0px 10px 0px;
                padding: 8px 0px 10px 0px;
                text-align: center;
              }
            }
            .colorList {
              justify-content: space-between;
              > div {
                box-sizing: border-box;
                width: 34px;
                height: 34px;
                border: 2px solid transparent;
                border-radius: 2px;
                cursor: pointer;
              }
            }
          }
          .right {
            .rotateBox {
              margin-top: 20px;
              height: 78px;
              justify-content: space-between;
              .rotate {
                width: 98px;
                height: 83px;
                position: relative;
                .line {
                  width: 1px;
                  height: 19px;
                  background: #b2b2b2;
                  position: absolute;
                  left: 51.5px;
                  top: 22px;
                  transform-origin: bottom;
                  // transform: rotate(30deg);
                }
              }
              .rotateNum {
                width: 109px;
                height: 34px;
                border: 1px solid #e8e8e8;
                border-radius: 2px;
                padding: 8px 8px 10px 8px;
                > input {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/deep/.canvas-container{
  .border-wrap{
    border: 1px dotted red;
    position: absolute;
    pointer-events: none;
  }
}
</style>